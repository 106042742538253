import {ReactNode} from 'react';
import styles from './Feedback.module.css';
import Text from '../typography/Text';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../enums';

interface FeedbackProps {
    icon: ReactNode;
    text: string;
}

const Feedback = ({
    icon,
    text,
}: FeedbackProps) => {
    return (
        <div className={styles.feedbackContainer}>
            <div className={styles.iconContainer}>
                {icon}
            </div>
            <Text
                size={FontSizeEnum.Sm}
                color={ColorEnum.Gray400}
                weight={FontWeightEnum.Regular}
            >{text}</Text>
        </div>
    );
};

export default Feedback;
