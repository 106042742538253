import styles from './Button.module.css';
import { ButtonProps } from './ButtonProps';
import classNames from '../../utils/classNames';
import LoadingSpinner from '../../atoms/loading-spinner';

const ButtonNew = ({
    className,
    value,
    children,
    disabled = false,
    fullWidth = false,
    spaceBetween = false,
    iconBefore,
    iconAfter,
    type = 'button',
    size = 'lg',
    variant = 'primary',
    loading = false,
    inline = false,
    onClick,
}: ButtonProps) => {
    const hasIconBeforeClassName = () => {
        return (iconBefore !== undefined) ? styles.hasIconBefore : '';
    }

    const hasIconAfterClassName = () => {
        return (iconAfter !== undefined) ? styles.hasIconAfter : '';
    }

    const mainClassNames = () => {
        return classNames(
            className ?? className,
            styles.button,
            inline && styles.inline,
            fullWidth && styles.fullWidth,
            spaceBetween && styles.spaceBetween,
            styles[size],
            styles[variant],
            loading ? styles.loading : '',
            hasIconBeforeClassName(),
            hasIconAfterClassName(),
            iconBefore !== undefined && children == undefined ? styles.iconOnly : '',
        );
    }

    return (
        <button
            className={mainClassNames()}
            data-testid="button"
            disabled={disabled}
            type={type}
            value={value}
            onClick={onClick}
        >
            {iconBefore &&
                <span className={classNames(styles.iconBefore)}>{iconBefore}</span>
            }
            {children}
            {iconAfter &&
                <span className={classNames(styles.iconAfter)}>{iconAfter}</span>
            }
            {loading &&
                <LoadingSpinner className={styles.spinner}/>
            }
        </button>
    )
}

export default ButtonNew;
