import React from 'react';
import {useParams, useLocation, Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './BreadCrumbNavigation.module.css';
import PropTypes from 'prop-types';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function BreadCrumbNavigation({onNavigation}) {
    const { id, pid } = useParams();
    const { pathname } = useLocation();
    const { selectedCandidate } = useSelector((state) => state.candidates.data);
    const { selectedListing } = useSelector((state) => state.listings);
    const { street, houseNumber, city } = selectedListing;
    const { firstName } = selectedCandidate;
    const cityFromCandidateDetails = selectedCandidate.listing.city;
    const streetFromCandidateDetails = selectedCandidate.listing.details.street;
    const house_numberFromCandidateDetails = selectedCandidate.listing.details.house_number;

    const renderBreadcrumbLabel = (label) => {

        const STREET_LABEL = street || streetFromCandidateDetails;
        const HOUSE_NUMBER_LABEL = houseNumber || house_numberFromCandidateDetails;
        const CITY_LABEL = city || cityFromCandidateDetails;

        switch (label) {
            case id: return `${STREET_LABEL} ${HOUSE_NUMBER_LABEL}, ${CITY_LABEL}`;
            case pid: return `${firstName}`;
            default: return normalizePath(label);
        }
    };

    function normalizePath(path) {
        const words = path.split('-')
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    }

    const getBreadcrumb = (path) => {
        const DASHBOARD_PATH = 'dashboard';
        const CANDIDATES_PATH = 'kandidaten';
        const pathParts = path?.split('/').filter((part) => part?.trim() !== '');
        return pathParts?.map((part, index) => {
            const previousParts = pathParts.slice(0, index);
            if (part === DASHBOARD_PATH || part === CANDIDATES_PATH) { return; }
            return {
                id: index,
                name: renderBreadcrumbLabel(part),
                href: previousParts?.length > 0 ? `/${previousParts?.join('/')}/${part}` : `/${part}`,
                current: pathParts.length === (index + 1)
            };
        }).filter(e => e) || [];
    };

    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className={styles.wrapper}>
                {pathname &&
                    <>
                        {getBreadcrumb(pathname).map((path, index) =>
                            <li key={index}>
                                <div className="flex flex-row items-center justify-center">
                                    <i className={'text-gray-500 ri-arrow-right-s-line ri-lg'}></i>
                                    <Link
                                        to={path.href}
                                        className={classNames(path.current ? 'font-bold text-primary' : 'font-medium text-gray-500', 'text-md hover:text-primary-dark')}
                                        aria-current={path.current ? 'page' : undefined}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onNavigation(path.href);
                                        }}
                                    >
                                        {path.name}
                                    </Link>
                                </div>
                            </li>
                        )}
                    </>

                }
            </ol>
        </nav >
    );
}

BreadCrumbNavigation.propTypes = {
    onNavigation: PropTypes.func
};

export default BreadCrumbNavigation;
