import React from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import styles from './AutomaticEnrichingModal.module.css'
import {useTranslation} from 'react-i18next'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function AutomaticEnrichingModal(props) {
    const { enrichmentMode,
        handleAutoEnriching,
        toggleAutoEnrichingModal,
        autoEnrichingModalOpen } = props

    const {t} = useTranslation()

    return (
        <Transition appear show={autoEnrichingModalOpen === true}>
            <Dialog as="div" className="relative z-50"
                onClose={() => toggleAutoEnrichingModal(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto w-full">
                    <div className="flex min-h-full items-center justify-center p-4 text-center w-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel
                                className={`w-full md:w-[600px] space-y-3
                                transform overflow-hidden
                                rounded-2xl bg-white p-6 text-left align-middle
                                shadow-xl transition-all ${styles.flowyContainer}`}>
                                <div className={styles.mainContainer}>
                                    <div className={styles.iconContainer}>
                                        <div className={styles.icon} >
                                            <i className="ri-survey-line"></i>
                                        </div>
                                    </div>
                                    <div className={styles.contentContainer}>
                                        <Dialog.Title className={classNames('font-bold text-1xl py-3 text-gray-900')}>
                                            {t('enriching.automatic.modal.title')}
                                        </Dialog.Title>
                                        <Dialog.Description className={classNames('text-md py-2 text-gray-800')}>
                                            {` ${t('enriching.automatic.modal.description')}
                                            ${enrichmentMode === 'manual' ? 'aanzetten' : 'stopzetten'}? `}
                                        </Dialog.Description>
                                    </div>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <button
                                        onClick={() => toggleAutoEnrichingModal(false)}
                                        className="inline-flex items-center px-4 py-3 border
                                    border-gray-300 shadow-sm text-md leading-4 font-medium
                                    rounded-md text-gray-700 bg-white hover:bg-gray-50
                                    ">
                                        {t('enriching.automatic.modal.button.cancel')}
                                    </button>
                                    <button
                                        className="inline-flex items-center px-4 py-3
                                    border border-transparent text-md leading-4
                                    font-medium rounded-md text-white bg-primary
                                    hover:bg-primary-dark mx-3"
                                        onClick={() => (handleAutoEnriching(enrichmentMode))}>
                                        {enrichmentMode === 'manual' ? 'Aanzetten' : 'Stopzetten'}
                                    </button>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

AutomaticEnrichingModal.propTypes = {
    enrichmentMode: PropTypes.string.isRequired,
    handleAutoEnriching: PropTypes.func.isRequired,
    autoEnrichingModalOpen: PropTypes.bool.isRequired,
    toggleAutoEnrichingModal: PropTypes.func.isRequired
}

export default AutomaticEnrichingModal
