import { PageContainer } from '../../atoms/page-container';
import { TopHeader } from '../../organisms/top-header';
import { Card } from '../../organisms/card';
import { Main } from '../../organisms/main';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import styles from './ViewingInvitationDeclinedPage.module.css';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { ListingCard } from '../../organisms/listing-card';
import { classNames } from '../../utils';
import 'moment/locale/nl';
import currentLanguage from '../../utils/currentLanguage';
import { setNotification } from '../../../redux/actions/ui/setNotification';
import getDeclinedViewingWithTimeslot, {Agency, Listing, Invitee, Viewing} from '../../../api/viewing/getDeclinedViewingWithTimeslot';
import Link from '../../atoms/link/Link';
import declineViewing from '../../../api/viewing/declineViewing';
import SkeletonComponent from '../../../components/SkeletonComponent';

const ViewingInvitationDeclinedPage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {homeseekerApplicationId, viewingId} = useParams();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [, setAgency] = useState<Agency | null>(null);
    const [listing, setListing] = useState<Listing | null>(null);
    const [invitee, setInvitee] = useState<Invitee | null>(null);
    const [viewing, setViewing] = useState<Viewing | null>(null);

    const locale = currentLanguage();

    useEffect(() => {
        fetchDeclinedViewingWithTimeslot();
    }, []);

    const fetchDeclinedViewingWithTimeslot = async () => {
        try {
            await declineViewing({homeseekerApplicationId, viewingId})
                .then(async () => {
                    const response = await getDeclinedViewingWithTimeslot(homeseekerApplicationId, viewingId);
                    setAgency(response.agency);
                    setListing(response.listing);
                    setViewing(response.viewing);
                    setInvitee(response.invitee);
                })
                .then(() => {
                    setIsLoading(false);
                })
                .catch((e) => {
                    throw e;
                })
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        }
    }

    const viewingDateWithTime = () => {
        if (!viewing || !invitee) {
            return '';
        }

        if (invitee.start_time !== null && invitee.end_time !== null) {
            return `${moment(viewing.start_date_time).locale(locale).format('dddd D MMMM,')} ${invitee.start_time} - ${invitee.end_time}`;
        } else {
            return `${moment(viewing.start_date_time).locale(locale).format('dddd D MMMM,')} ${moment(viewing.start_date_time).locale(locale).format('HH:mm')} - ${moment(viewing.end_date_time).locale(locale).format('HH:mm')}`;
        }
    }

    return (
        <PageContainer backgroundColor={ColorEnum.Gray100}>
            <TopHeader hasTranslationSelector={true}/>
            <Main>
                <div className={classNames(styles.cardsGrid, styles.gridXl)}>
                    {!isLoading ?
                        <>
                            <div className={styles.listingCard}>
                                <ListingCard
                                    imageUrl={listing.image_url}
                                    street={listing.street}
                                    houseNumber={listing.house_number}
                                    postalCode={listing.postal_code}
                                    city={listing.city}
                                    price={listing.price}
                                    surface={listing.surface}
                                    rooms={listing.rooms}
                                />
                            </div>
                            <div>
                                <Card
                                    elevated
                                >
                                    <div className="ta-left">
                                        <div className={styles.gridLg}>
                                            <Text
                                                tag="h1"
                                                color={ColorEnum.Gray800}
                                                size={FontSizeEnum.Lg}
                                            >
                                                {t('viewing.invitation.timeslot.declined.title')}
                                            </Text>
                                            <div className={styles.gridSm}>
                                                <Text
                                                    tag="p"
                                                    color={ColorEnum.Gray800}
                                                    size={FontSizeEnum.Md}
                                                    weight={FontWeightEnum.Regular}
                                                >
                                                    <Trans
                                                        i18nKey="viewing.invitation.timeslot.declined.body"
                                                        values={{date: viewingDateWithTime()}}
                                                        components={{strong: <strong/>}}
                                                    />
                                                </Text>
                                                <Text
                                                    tag="p"
                                                    color={ColorEnum.Gray800}
                                                    size={FontSizeEnum.Md}
                                                    weight={FontWeightEnum.Regular}
                                                >
                                                    {t('viewing.invitation.timeslot.declined.link.pretext')}
                                                    <Link
                                                        url={`/homeseeker/${homeseekerApplicationId}/viewing/${viewingId}/timeslot/schedule`}>
                                                        {t('viewing.invitation.timeslot.declined.link')}
                                                    </Link>
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </>
                        :
                        <>
                            <SkeletonComponent height={300} width={300}/>
                            <SkeletonComponent height={100}/>
                        </>
                    }

                </div>
            </Main>
        </PageContainer>
    );
};

export default ViewingInvitationDeclinedPage;
