/* eslint-disable no-undef */
import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {BrowserRouter} from 'react-router-dom';
import {useEffect} from 'react'
import TestAuth0Provider from './testAuth0Provider/TestAuth0Provider';
import LeadflowWindow from '../LeadflowWindow';

if (process.env.NODE_ENV === 'development') {
    LeadflowWindow.REACT_APP_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
    LeadflowWindow.REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
    LeadflowWindow.REACT_APP_AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;
    LeadflowWindow.REACT_APP_AUTH_SCOPE = process.env.REACT_APP_AUTH_SCOPE;
    LeadflowWindow.REACT_APP_IS_AUTH_IN_TEST_MODE = process.env.REACT_APP_IS_AUTH_IN_TEST_MODE;

    LeadflowWindow.REACT_APP_LEADFLOW_CANDIDATES_API = process.env.REACT_APP_LEADFLOW_CANDIDATES_API;
    LeadflowWindow.REACT_APP_LEADFLOW_LEADS_API = process.env.REACT_APP_LEADFLOW_LEADS_API;
}

const AuthWrapperWithHistory = ({ children }) => {

    useEffect(() => {
        sessionStorage.removeItem('redirectToAfterLogin')
        sessionStorage.setItem('redirectToAfterLogin', `${window.location.pathname}${location.search}`)
    }, [])

    return (
        <BrowserRouter>
            {LeadflowWindow.REACT_APP_IS_AUTH_IN_TEST_MODE === 'true' ?
                <TestAuth0Provider>
                    {children}
                </TestAuth0Provider> :
                <Auth0Provider
                    domain={LeadflowWindow.REACT_APP_AUTH_DOMAIN}
                    clientId={LeadflowWindow.REACT_APP_AUTH_CLIENT_ID}
                    redirectUri={window.location.origin}
                    audience={LeadflowWindow.REACT_APP_AUTH_AUDIENCE}
                    scope={LeadflowWindow.REACT_APP_AUTH_SCOPE}
                    useRefreshTokens
                    cacheLocation={'localstorage'}
                >
                    {children}
                </Auth0Provider>
            }

        </BrowserRouter>
    );
};

AuthWrapperWithHistory.propTypes = {
    children: PropTypes.any.isRequired,
};

export default AuthWrapperWithHistory;
