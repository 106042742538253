import React from 'react';
import styles from './Prompt.module.css';
import Text from '../../atoms/typography/Text';
import {FontSizeEnum} from '../../enums';
import ButtonNew from '../button/Button';

interface PromptProps {
    data: string;
    onConfirm: (data: string) => void;
    onCancel: () => void;
}

const Prompt = ({data, onConfirm, onCancel}: PromptProps) => {
    return (
        <div className={styles.backdrop}>
            <div className={styles.container}>
                <Text size={FontSizeEnum.Sm}>
                    Je hebt wijzigingen die nog niet zijn opgeslagen.
                </Text>
                <Text size={FontSizeEnum.Sm}>
                    Weet je zeker dat je van kantoor wilt wisselen zonder op te slaan?
                </Text>
                <div className={styles.buttonContainer}>
                    <ButtonNew variant='secondary' onClick={onCancel}>Annuleren</ButtonNew>
                    <ButtonNew onClick={() => onConfirm(data)}>Doorgaan zonder op te slaan</ButtonNew>
                </div>
            </div>
        </div>
    );
};

export default Prompt;
