const SUITABLE = 'suitable';
const NOT_SUITABLE = 'not_suitable';
const REJECTED = 'rejected';
const NONE = 'none';
const ASSESSMENT = 'assessment';

const VIEWING_INVITATION_STATUS = Object.freeze({
    NOT_INVITED: 'not_invited',
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
});

const VIEWING_INVITATION_STATUS_TRANSITIONS = Object.freeze({
    INVITE: 'invite',
    ACCEPT: 'accept',
    DECLINE: 'decline',
});

const LISTING_ACTIONS = {
    SET_CANDIDATE_COUNT_PER_SELECTED_LISTING: 'SET_CANDIDATE_COUNT_PER_SELECTED_LISTING'
};

const UI_ACTIONS = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING'
};

const LISTING_STATES = {
    AVAILABLE: 'available',
    ARCHIVED: 'archived'
};

const RENTAL_PERIOD = {
    INDEFINITE: {placeholder: 'INDEFINITE', value: 0},
    ZERO_THREE: {placeholder: 'ZERO_THREE', value: 3},
    THREE_SIX: {placeholder: 'THREE_SIX', value: 6},
    SIX_TWELVE: {placeholder: 'SIX_TWELVE', value: 12},
    YEAR: {placeholder: 'YEAR', value: 24},
};


const HOUSEHOLD_TYPE = {
    SINGLE: 'SINGLE',
    WITH_PARTNER: 'WITH_PARTNER',
    WITH_FAMILY: 'WITH_FAMILY',
    WITH_FRIENDS: 'WITH_FRIENDS',
};

const GUARANTOR = {
    IN_NETHERLANDS: 'IN_NETHERLANDS',
    ABROAD: 'ABROAD',
    NO_GUARANTOR: 'NO_GUARANTOR',
};

const SALUTATION = {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    UNSET: 'UNSET',
};

const API_URIS = {
    AGENTS_URI: 'api/agents',
    AGENCIES_URI: 'api/get-agencies'
};

const WAIT_TIME = 750;
// eslint-disable-next-line no-undef
module.exports = {
    SUITABLE,
    NOT_SUITABLE,
    REJECTED,
    NONE,
    ASSESSMENT,
    VIEWING_INVITATION_STATUS,
    VIEWING_INVITATION_STATUS_TRANSITIONS,
    LISTING_ACTIONS,
    UI_ACTIONS,
    RENTAL_PERIOD,
    HOUSEHOLD_TYPE,
    GUARANTOR,
    LISTING_STATES,
    SALUTATION,
    API_URIS,
    WAIT_TIME
};
