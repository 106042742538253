import React from 'react';
import ButtonNew from '../../molecules/button/Button';
import styles from './TimeslotSelector.module.css';
import { classNames } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Timeslot } from '../../../api/viewing/getViewingWithTimeslots';

interface TimeslotSelectorProps {
    readonly timeslot: Timeslot;
    readonly selected: boolean;
    readonly loading: boolean;
    readonly onTimeslotClick: () => void;
    readonly onTimeslotConfirmClick: () => void;
}

const TimeslotSelector = ({
    timeslot,
    selected,
    loading = false,
    onTimeslotClick,
    onTimeslotConfirmClick,
}: TimeslotSelectorProps) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.TimeslotSelector, selected && styles.selected)}>
            <ButtonNew
                className={styles.timeslotButton}
                size='xxl'
                variant={selected ? 'secondary' : 'secondary-gray'}
                disabled={!timeslot.hasAvailableSeats}
                onClick={onTimeslotClick}
            >{timeslot.startTime} - {timeslot.endTime}</ButtonNew>
            {timeslot.hasAvailableSeats &&
                <ButtonNew
                    className={styles.confirmButton}
                    size='xxl'
                    onClick={onTimeslotConfirmClick}
                    loading={selected && loading}
                >{t('viewing.page.confirm.timeslot')}</ButtonNew>
            }
        </div>
    );
};

export default TimeslotSelector;
