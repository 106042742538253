import React, { ReactNode, useEffect, useRef, useState, ChangeEvent, MouseEvent } from 'react';
import { classNames } from '../../utils';
import styles from './Select.module.css';
import { Dropdown } from '../dropdown';
import CheckLineIcon from '../../atoms/icons/CheckLineIcon';
import ButtonNew from '../../molecules/button/Button';
import ArrowDownSLineIcon from '../../atoms/icons/ArrowDownSLineIcon';
import LoadingSpinner from '../../atoms/loading-spinner';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import { useTranslation } from 'react-i18next';
import dropdownStyles from '../dropdown/Dropdown.module.css';

export interface Item {
    label: string | ReactNode;
    value: string;
    selectable?: boolean;
    disabled?: boolean;
    scrollTo?: boolean;
}

interface BaseSelectProps {
    className?: string;
    items: Item[];
    isLoadingItems?: boolean;
    label?: ReactNode;
    name?: string;
    placeholder?: string;
    iconBefore?: ReactNode;
    subText?: ReactNode;
    isOpen?: boolean;
    hasInputField?: boolean;
    fullWidth?: boolean;
    onChange: (item: Item | null) => void;
    onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    renderCustomSelected?: (item: string | string[]) => ReactNode;
    renderDropdownHeader?: () => ReactNode;
    renderCustomOptionLabel?: (item: Item) => ReactNode;
    showArrowIcon?: boolean;
}

interface DefaultSelectProps extends BaseSelectProps {
    multiple?: false;
    selected?: string | null;
}

interface MultiSelectProps extends BaseSelectProps {
    multiple: true;
    selected?: (string | null)[];
}

type SelectProps = DefaultSelectProps | MultiSelectProps;

export const findItemByValue = (items: Item[], value: Item['value']): undefined | Item => {
    return items.find(item => item.value === value);
}

const Select = ({
    className,
    items,
    isLoadingItems = false,
    label,
    name,
    placeholder,
    multiple = false,
    selected,
    iconBefore,
    subText,
    isOpen = false,
    hasInputField = false,
    fullWidth = false,
    onChange,
    onInputChange,
    renderCustomSelected,
    renderDropdownHeader,
    renderCustomOptionLabel,
    showArrowIcon = true,
}: SelectProps) => {
    const { t } = useTranslation();

    const dropdownRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<HTMLUListElement>(null);
    const selectDivRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [dropdownIsActive, setDropdownIsActive] = useState(isOpen)

    const hasIconBeforeClassName = () => {
        return (iconBefore !== undefined) ? styles.hasIconBefore : '';
    }

    useEffect(() => {
        document.addEventListener('pointerdown', handleOutsideClick);

        return () => {
            document.removeEventListener('pointerdown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (!inputRef.current) {
            return;
        }

        if (dropdownIsActive === true) {
            inputRef.current.focus();

            const scrollToElement: HTMLLIElement | null = itemsRef.current?.querySelector(`[data-scroll-to="true"]`);
            const selectedElement: HTMLLIElement | null = itemsRef.current?.querySelector(`.${styles.selected}`);

            scrollToItem(selectedElement ? selectedElement : scrollToElement);
        } else {
            inputRef.current.blur();
        }
    }, [dropdownIsActive]);

    const scrollToItem = (element: HTMLLIElement | null) => {
        if (!dropdownIsActive || items.length === 0 || element === null) {
            return;
        }

        const selectDropdownElement: HTMLDivElement = dropdownRef.current.querySelector(`.${dropdownStyles.dropdownCard}`);
        const itemsListElement = itemsRef.current;

        if (selectDropdownElement.offsetHeight >= itemsListElement.offsetHeight) {
            return;
        }

        selectDropdownElement.scrollTop = element.offsetTop;
    };

    const handleOutsideClick = (e: PointerEvent) => {
        if (selectDivRef.current && !selectDivRef.current.contains(e.target as Node)) {
            setDropdownIsActive(false);
        }
    };

    const isSelected = (item: Item): boolean => {
        return !!selected?.includes(item.value)
    }

    const renderHiddenSelect = (): ReactNode => {
        return (
            <select
                name={name}
                hidden
                multiple={multiple}
                defaultValue={selected}
            >
                {items.map((item, number) => {
                    return (
                        <option
                            key={number}
                            value={item.value}
                        >{item.value}</option>
                    )
                })}
            </select>
        )
    }

    const renderSelected = (): string | ReactNode => {
        if (!multiple && hasInputField) {
            return;
        }

        if (selected?.length === 0) {
            if (placeholder) {
                return (
                    <div className={styles.Placeholder}>{placeholder}</div>
                );
            }

            return (
                <>&nbsp;</>
            );
        }

        if (Array.isArray(selected)) {
            return items.filter((item, index) => item.value === selected[index])
                .map(item => item.label ? item.label : item.value)
                .join(', ');
        }

        return items.find((item) => item.value === selected)?.label;
    }

    const handleSelectClick = (): void => {
        if (!multiple) {
            if (inputRef.current) {
                inputRef.current.select();
            }
            setDropdownIsActive(true);
        } else {
            setDropdownIsActive(!dropdownIsActive);
        }
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const inputValue = e.target.value;

        const itemsRefChildren = itemsRef.current.children;

        let foundMatch = false;

        for (let i = 0; i < itemsRefChildren.length; i++) {
            const item: HTMLLIElement = (itemsRefChildren[i] as HTMLLIElement);

            if (inputValue !== '' &&
                (
                    item.textContent?.toLowerCase().includes(inputValue.toLowerCase())
                    || item.getAttribute('data-value')?.toLowerCase().includes(inputValue.toLowerCase())
                )
                && !foundMatch
            ) {
                item.classList.add(styles.highlighted)
                scrollToItem(item);
                foundMatch = true;
            } else {
                item.classList.remove(styles.highlighted);
            }
        }

        onChange(null);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
        const currentItemRef = itemsRef.current;

        if (!currentItemRef) {
            return;
        }

        const highlightedElement: HTMLLIElement | null = currentItemRef.querySelector(`.${styles.highlighted}`);

        if (e.key === 'Enter' || e.key === 'Escape') {
            if (highlightedElement === null) {
                setDropdownIsActive(false);
            } else {
                onChange(findItemByValue(items, highlightedElement.getAttribute('data-value')));
                setDropdownIsActive(false);
            }
        }

        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            handleArrowsKeyDown(e, highlightedElement);
        }
    }

    function handleArrowsKeyDown(e: React.KeyboardEvent<HTMLInputElement>, highlightedElement: HTMLLIElement | null): void {
        if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
            return;
        }

        if (selected && highlightedElement === null) {
            highlightedElement = itemsRef.current.querySelector(`.${styles.selected}`);
        }

        let item = null;

        if (highlightedElement === null) {
            item = Array.from(itemsRef.current.children).find(
                (li) => li.tagName === 'LI' && !li.classList.contains(styles.disabled)
            ) as HTMLLIElement | null;

            if (item === null) {
                return;
            }

            inputRef.current.value = item.textContent as string;

            item.classList.add(styles.highlighted)
            scrollToItem(item);

            return;
        }

        const nextItem: HTMLLIElement | null = highlightedElement.nextElementSibling as HTMLLIElement | null;
        const previousItem: HTMLLIElement | null = highlightedElement.previousElementSibling as HTMLLIElement | null;
        item = nextItem;

        if (e.key === 'ArrowDown') {
            item = nextItem;
        }

        if (e.key === 'ArrowUp') {
            item = previousItem;
        }

        if (item === null) {
            return;
        }

        if (item.classList.contains(styles.disabled)) {
            return;
        }

        highlightedElement.classList.remove(styles.highlighted);
        inputRef.current.value = item.textContent as string;

        item.classList.add(styles.highlighted)
        scrollToItem(item);
    }

    function handleFocus(): void {
        if (hasInputField) {
            setDropdownIsActive(true);
        }
    }

    function handleBlur(): void {
        const currentItemRef = itemsRef.current;

        if (!currentItemRef || multiple) {
            return;
        }

        const highlightedElement: HTMLLIElement | null = currentItemRef.querySelector(`.${styles.highlighted}`);

        if (highlightedElement) {
            highlightedElement.classList.remove(styles.highlighted);
            onChange(findItemByValue(items, highlightedElement.getAttribute('data-value')));
        }

        setDropdownIsActive(false);
    }

    const renderInput = (): ReactNode => {
        if (!hasInputField) {
            return;
        }

        if (multiple) {
            return (
                <div className={styles.SelectInput}>
                    <input
                        ref={inputRef}
                        className={styles.input}
                        placeholder={placeholder}
                        onChange={onInputChange}
                    />
                </div>
            );
        }

        return (
            <div className={styles.SelectInput}>
                <input
                    ref={inputRef}
                    value={selected}
                    className={styles.input}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onClick={handleSelectClick}
                />
            </div>
        );
    }

    const renderSelect = (): ReactNode => {
        if (renderCustomSelected || hasInputField) {
            return (
                <div
                    className={styles.CustomButton}
                    onClick={handleSelectClick}
                >
                    <div className={classNames(styles.SelectWrapper, hasIconBeforeClassName())}>
                        {iconBefore &&
                            <div className={styles.iconBeforeWrapper}>
                                {iconBefore}
                            </div>
                        }
                        {renderCustomSelected ? renderCustomSelected?.(selected) : renderSelected()}
                        {renderInput()}
                    </div>
                    {showArrowIcon &&
                        <div className={styles.iconAfter}>
                            <ArrowDownSLineIcon className={styles.arrowDownIcon}/>
                        </div>
                    }
                </div>
            );
        }

        return (
            <ButtonNew
                className={classNames(styles.Button)}
                iconBefore={iconBefore}
                iconAfter={
                    showArrowIcon && (
                        <ArrowDownSLineIcon className={styles.arrowDownIcon} />
                    )
                }
                variant="secondary-gray"
                onClick={handleSelectClick}
            >
                <div className={styles.SelectWrapper}>
                    {renderSelected()}
                </div>
            </ButtonNew>
        )
    }

    const handleItemClick = (e: MouseEvent, item?: Item | null) =>  {
        e.preventDefault();

        if (item === null || item === undefined) {
            onChange(null);
        }

        if (item.selectable === false || item.disabled) {
            return;
        }

        if (multiple) {
            e.stopPropagation();
        } else {
            setDropdownIsActive(false);
            if (hasInputField) {
                inputRef.current.value = item.value;
            }
        }

        onChange(item);
    }

    const renderOptionLabel = (item: Item): ReactNode => {
        if (renderCustomOptionLabel) {
            return renderCustomOptionLabel(item);
        }

        return item.label;
    }

    const renderOption = (item: Item, index: number): ReactNode => {
        const optionClassNames = classNames(
            styles.item,
            isSelected(item) && styles.selected,
            item.selectable === false ? styles.unselectable : '',
            item.disabled === true ? styles.disabled : '',
        );

        return (
            <li
                key={index}
                className={optionClassNames}
                onPointerDown={(e) => handleItemClick(e, item)}
                data-scroll-to={item.scrollTo}
                data-value={item.value}
            >
                {renderOptionLabel(item)}
                <CheckLineIcon className={styles.checkLineIcon}/>
            </li>
        )
    }

    const renderOptions = (): ReactNode => {
        return (
            <div>
                {(!isLoadingItems && (renderDropdownHeader !== undefined)) &&
                    <div className={styles.DropdownHeader}>{renderDropdownHeader()}</div>
                }
                <ul
                    ref={itemsRef}
                    className={styles.items}
                >
                    {isLoadingItems ? (
                        <li className={classNames(styles.item, styles.loadingItem)}>
                            <LoadingSpinner/>
                        </li>
                    ) : (
                        <>
                            {items.map(renderOption)}
                        </>
                    )}
                </ul>
            </div>
        )
    }

    const renderLabel = (): ReactNode => {
        if (label === undefined) {
            return <></>;
        }

        return label;
    }

    return (
        <>
            <div className={classNames(className && className, styles.Select, dropdownIsActive && styles.isOpen, fullWidth && styles.fullWidth)}>
                {renderLabel()}
                <div
                    className={styles.SelectContainer}
                    ref={selectDivRef}
                >
                    {renderHiddenSelect()}
                    {renderSelect()}
                    <Dropdown
                        ref={dropdownRef}
                        fullWidth={fullWidth}
                        isActive={dropdownIsActive}
                    >
                        {renderOptions()}
                    </Dropdown>
                </div>
                {subText &&
                    <Text
                        tag="p"
                        color={ColorEnum.Gray500}
                        size={FontSizeEnum.Sm}
                        weight={FontWeightEnum.Light}
                    >
                        {t('candidate.action.invite.viewing.homeseekerSelector.subText')}
                    </Text>
                }
            </div>
        </>
    );
};

export default Select;
