import React from 'react';
import styles from './Logo.module.css';
import {useNavigate} from 'react-router-dom';
import {classNames} from '../../utils';
import LogoWideSVG from './assets/LogoWideSVG';
import useAuth from '../../../hooks/useAuth';

interface Props {
    readonly size?: 'md';
    readonly isNavigationDisabled?: boolean;
}

const Logo = ({ size = 'md', isNavigationDisabled = false }: Props) => {
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();

    const handleClick = () => {
        if (!isNavigationDisabled) {
            if (isAuthenticated) {
                navigate('/dashboard/advertenties');
            } else {
                navigate('/login');
            }
        }
    }

    return (
        <div className={classNames(styles.logoContainer, styles[size], !isNavigationDisabled && styles.clickable)} onClick={handleClick}>
            <LogoWideSVG />
        </div>
    );
};

export default Logo;
