import api from '../../../utils/api';
import {setNotification} from '../ui/setNotification';

export const setHomeseekerApplicationNote = (homeseekerApplicationId, value) => (dispatch) => {
    dispatch({type: 'START_LOADING'});
    api.post('api/homeseeker/application/note/create', {
        note: value,
        homeseekerApplicationId: homeseekerApplicationId,
    })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                dispatch(setNotification({
                    id: Date.now(),
                    message: 'Notitie opgeslagen.',
                    type: 'success'
                }));
            }
        })
        .catch((e) => {
            dispatch({type: 'STOP_LOADING'});
            console.log(e);
        })
        .finally(() => dispatch({type: 'STOP_LOADING'}));
};
