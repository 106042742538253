import React, {ReactNode, useEffect, useState} from 'react';
import styles from './PageWithSidePanel.module.css';
import {classNames} from '../../utils';
import { SidePanel, SidePanelCustomEventType } from '../../organisms/side-panel';

type PageWithSidePanelProps = {
    sidePanelOpen?: boolean;
    children: ReactNode;
};

const PageWithSidePanel = ({
    sidePanelOpen = false,
    children
}: PageWithSidePanelProps) => {
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(sidePanelOpen);

    const setSidePanelOpen = (e: SidePanelCustomEventType) => setIsSidePanelOpen(e.detail.open);

    useEffect(() => {
        SidePanel.addListener(setSidePanelOpen);

        return () => {
            SidePanel.removeListener(setSidePanelOpen);
        }
    }, []);

    return (
        <div className={classNames(styles.PageWithSidePanel, isSidePanelOpen && styles.open)}>
            {children}
        </div>
    );
};

export default PageWithSidePanel;
