import React from 'react';
import PropTypes from 'prop-types';
import SkeletonComponent from '../SkeletonComponent';
import { ListingProperties } from './listingProperties/ListingProperties';
import ListingPropertiesModel from './listingProperties/logics/model/listingPropertiesModel';

function normalizeListingImageUrl(imageUrl) {
    return imageUrl.replace('fit=crop&width=100&height=100', 'fit=cover&width=500&height=500');
}

function ListingCard({ listing, agent, className }) {

    const [isListingImageValid, setIsListingImageValid] = React.useState(true);
    const [isLogoValid, setIsLogoValid] = React.useState(true);

    function getListingAddress() {
        return `${listing.street} ${listing.show_house_number ?
            listing.house_number :
            ''}`;
    }

    function isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    return (
        !isObjectEmpty(listing) && <div className={`mt-4 overflow-hidden bg-white p-4 rounded-lg ${className}`}>
            {isListingImageValid &&
                <div className="col-span-6 my-5">
                    <img
                        className="inline-block max-h-[220px] w-full rounded-lg"
                        src={listing.image_url ?
                            normalizeListingImageUrl(listing.image_url) :
                            ''}
                        onError={() => setIsListingImageValid(false)}
                        alt=""
                    />
                </div>
            }
            {isLogoValid && !isObjectEmpty(listing) &&
                <div className="col-span-3 mt-4 sm:block hidden">
                    <img
                        className="inline-block  max-h-[4rem] w-auto rounded-lg"
                        src={agent.logo ? agent.logo : ''}
                        onError={() => setIsLogoValid(false)}
                        alt="Logo van de makelaar"
                    />
                </div>
            }
            <div className='col-span-6 p-1'>
                <div>
                    <div className="flex-1 min-w-0">
                        <h1 className="mt-4 text-2xl font-semibold leading-7 text-primary
                                sm:text-4xl sm:truncate font-heading text-uppercase"
                        >
                            {getListingAddress() || <SkeletonComponent />}
                        </h1>
                        <p className="text-gray-400 text-base font-medium py-2">
                            {`${listing.postal_code}, ${listing.city}`}
                        </p>
                        <ListingProperties
                            model={new ListingPropertiesModel(listing.price, listing.surface, listing.rooms)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

ListingCard.propTypes = {
    agent: PropTypes.object.isRequired,
    listing: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default ListingCard;
