import {Fragment, useEffect} from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import gbFlag from '../../assets/img/flags/gb-flag.png';
import nlFlag from '../../assets/img/flags/nl-flag.png';
import style from './LanguageSwitchButtons.module.css';
import PropTypes from 'prop-types';

function LanguageDropdown(props) {
    const { i18n } = useTranslation();
    const languages = [
        { code: 'nl', label: 'Nederlands', flag: nlFlag },
        { code: 'en', label: 'English', flag: gbFlag }
    ];

    const currentLanguage =  i18n.language || 'nl';
    const { languageCode } = props;

    useEffect(() => {
        if (languageCode && languageCode !== i18n.language) {
            i18n.changeLanguage(languageCode);
        }
    }, [languageCode]);

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className={`${style.buttonContainer} inline-flex items-center justify-start rounded-md border border-gray-300 text-sm font-medium px-4 py-2 bg-white text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-100 focus:border-primary-300`}>
                    <img
                        src={languages.find((lang) => lang.code === currentLanguage)?.flag}
                        alt={currentLanguage === 'nl' ? 'English' : 'Nederlands'}
                        className="w-5 h-5 mr-2"
                    />
                    <span>{languages.find((lang) => lang.code === currentLanguage)?.label}</span>
                    <ChevronDownIcon className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className= "absolute right-0 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className={style.menuContainer}>
                        {languages.map((lang) => (
                            <Menu.Item key={lang.code}>
                                {({ active }) => (
                                    <button
                                        onClick={() => i18n.changeLanguage(lang.code)}
                                        className={classNames(
                                            (active || currentLanguage === lang.code) ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                            'w-full flex items-center justify-between text-sm cursor-pointer', style.menuDropdownConatainer
                                        )}
                                    >
                                        <div className={style.menuItemContainer}>
                                            <img src={lang.flag} alt={lang.label} className="w-5 h-5 mr-2" />
                                            {lang.label}
                                        </div>
                                        {currentLanguage === lang.code && <i className="ri-check-line"></i>}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

LanguageDropdown.propTypes = {
    languageCode: PropTypes.string,
};

LanguageDropdown.defaultProps = {
    languageCode: null,
};

export default LanguageDropdown;
