import React from 'react';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../enums';
import styles from './TemplateVariableExplanation.module.css';
import {Trans, useTranslation} from 'react-i18next'
import Text from '../../atoms/typography/Text';
import CopyButtonNew from '../../molecules/button/CopyButtonNew';
import FileCopyLineIcon from '../../atoms/icons/FileCopyLineIcon'
import {KBD} from '../../atoms/kbd';
import ButtonNew from '../../molecules/button/Button';
import Divider from '../../atoms/divider';

const TemplateVariableExplanation = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.sidepanelContentWrapper}>
            <Text
                tag='p'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                {t('sidepanel.variable.description')}
            </Text>

            <Text
                tag='h2'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
                weight={FontWeightEnum.Bold}
            >
                {t('sidepanel.explanation.variable.title')}
            </Text>

            <Text
                tag='ul'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                <li>
                    <Trans
                        i18nKey="sidepanel.explanation.variable.description.name"
                        components={{KBD: <KBD/>}}
                    />
                </li>
                <li>
                    {t('sidepanel.explanation.variable.description')}
                </li>
            </Text>
            <Text
                tag='h2'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
                weight={FontWeightEnum.Bold}
            >
                {t('sidepanel.example.variable.title')}
            </Text>

            <Text
                tag='p'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                {t('sidepanel.example.template')}
            </Text>

            <Text
                tag='blockquote'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                <Trans
                    i18nKey="sidepanel.example.email"
                    components={{KBD: <KBD/>}}
                />
            </Text>

            <Text
                tag='p'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                {t('sidepanel.example.result')}
            </Text>

            <Text
                tag='blockquote'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                {t('sidepanel.example.received')}
            </Text>

            <Text
                tag='h2'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
                weight={FontWeightEnum.Bold}
            >
                {t('sidepanel.variable.list.title')}
            </Text>
            <div className={styles.variableListContainer}>
                <div className={styles.variableList}>
                    <Text
                        tag="p"
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray800}
                    >
                        {t('sidepanel.variable.list.name')}
                        <div className={styles.variableTransList}>
                            <Trans i18nKey="sidepanel.variable.list.name.transText"/>
                        </div>
                    </Text>
                    <CopyButtonNew
                        variant='secondary-gray'
                        size="lg"
                        iconBefore={<FileCopyLineIcon/>}
                        tooltipText='Gekopieerd!'
                        textToCopy='{{kandidaat_naam}}'
                    />
                </div>
                <Divider/>
                <div className={styles.variableList}>
                    <Text
                        tag="p"
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray800}
                    >
                        {t('sidepanel.variable.list.address')}
                        <div className={styles.variableTransList}>
                            <Trans i18nKey="sidepanel.variable.list.address.transText"/>
                        </div>
                        <div className={styles.variableTransList}>
                            <Trans>
                                {t('sidepanel.variable.list.address.transText.support')}{' '}
                                <ButtonNew className={styles.linkButton} variant="link" size='sm' onClick={() => window.location.href = 'mailto:info@leadflow.rent'}>
                                    {t('sidepanel.variable.list.address.transText.link')}
                                </ButtonNew>
                            </Trans>
                        </div>
                    </Text>
                    <CopyButtonNew
                        variant='secondary-gray'
                        size="lg"
                        iconBefore={<FileCopyLineIcon/>}
                        tooltipText='Gekopieerd!'
                        textToCopy='{{woning_adres}}'
                    />
                </div>
                <Divider/>
                <div className={styles.variableList}>
                    <Text
                        tag="p"
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray800}
                    >
                        {t('sidepanel.variable.list.city')}
                        <div className={styles.variableTransList}>
                            <Trans i18nKey="sidepanel.variable.list.city.transText"/>
                        </div>
                    </Text>
                    <CopyButtonNew
                        variant='secondary-gray'
                        size="lg"
                        iconBefore={<FileCopyLineIcon/>}
                        tooltipText='Gekopieerd!'
                        textToCopy='{{woning_stad}}'
                    />
                </div>
            </div>

            <Text
                tag='h2'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
                weight={FontWeightEnum.Bold}
            >
                {t('sidepanel.variable.list.reasons.title')}
            </Text>

            <Text
                tag='ul'
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                <li>
                    <Trans
                        i18nKey="sidepanel.variable.list.reasons.description.fast"
                    />
                </li>
                <li>
                    <Trans
                        i18nKey="sidepanel.variable.list.reasons.description.personal"
                    />
                </li>
                <li>
                    <Trans
                        i18nKey="sidepanel.variable.list.reasons.description.accurately"
                    />
                </li>
            </Text>
        </div>
    );};

export default TemplateVariableExplanation;
