import React from 'react'
import styles from './HTMLRenderer.module.css'

export interface HTMLRendererProps {
    readonly html: string;
}

export default function HTMLRenderer(props: HTMLRendererProps) {
    return (
        <div
            className={styles.htmlRenderer}
            dangerouslySetInnerHTML={{__html: props.html}}
        />
    )
}
