import api from '../../../utils/api';
import {setNotification} from '../ui/setNotification';

export const updateAgentWithLogins = async (token, agencyId, agentData, loginsToCreate, loginsToDelete, dispatch) => {
    try {
        await api.post(`api/agency/${agencyId}/update`, agentData);

        const agentIdsToDelete = loginsToDelete.map(login => login.id);
        await api.post(`api/agents/remove`, {agentIds: agentIdsToDelete});
        await api.post(`api/agents/add`, {agentEmails: loginsToCreate, agencyId: agencyId});
    } catch (error) {
        dispatch(
            setNotification({
                id: Date.now(),
                message: error,
                type: 'failed'
            })
        );
        throw error;
    }
};
