import { forwardRef, useRef, useImperativeHandle, useEffect, useLayoutEffect, useState } from 'react';
import styles from './TextArea.module.css';
import Quill from 'quill';
import QuillResizeImage from 'quill-resize-image';
import ImageCompress from 'quill-image-compress';
import classNames from '../../utils/classNames';
import VariablesDropdownBlot from './utils/VariableDropdownBlot';
import { Delta } from 'quill/core';
import { replaceVariables } from './utils/Variables';

Quill.register('formats/variables-dropdown', VariablesDropdownBlot);
Quill.register('modules/resize', QuillResizeImage);
Quill.register('modules/imageCompress', ImageCompress);

window.QuillResizeImage = QuillResizeImage;

const H1 = 1;
const H2 = 2;
const H3 = 3;
const H4 = 4;
const p = false; // this value is false to enable the <p> tag. (Basically saying headers = false)

interface TextAreaProps {
    readonly className?: string;
    readonly name: string;
    readonly value: string;
    readonly onChange: (name: string, value: string) => void;
    readonly toolbarExtensions?: Array<{label: string, options: string[]}>;
    readonly allowImages?: boolean;
    readonly disabled?: boolean;
    readonly hidden?: boolean;
}

const TextArea = forwardRef<Quill, TextAreaProps>(
    (
        {
            className = 'defaultQuillEditor',
            name,
            value,
            onChange,
            toolbarExtensions = [],
            allowImages = false,
            disabled = false,
            hidden = false
        },
        forwardedRef?
    ) => {
        const ref = useRef<Quill>(null);
        useImperativeHandle(forwardedRef, () => ref.current as Quill);

        const onChangeRef = useRef(onChange);
        const containerRef = useRef<HTMLDivElement | null>(null);
        const valueRef = useRef(null);

        const [variables, ] = useState(toolbarExtensions?.[0]?.options);

        useEffect(() => {
            if (variables) {
                value = replaceVariables(value, variables);
            }
        }, []);

        useEffect(() => {
            const formats = [
                'header', 'bold', 'italic', 'underline', 'strike',
                'blockquote', 'color', 'list', 'indent', 'link'
            ];
            const toolbarImportModules = ['link'];
            let image = '';
            const toolbarExtensionModules = [];

            if (allowImages == true) {
                image = 'image';
                toolbarImportModules.push(image);
            }

            if (toolbarExtensions.length > 0) {
                for (let i = 0; i < toolbarExtensions.length; i++) {
                    const extension = toolbarExtensions[i];
                    toolbarExtensionModules[extension.label] = extension.options
                    formats.push(extension.label);
                }
            }

            const modules: Record<string, Array<object | string> | object> = {
                toolbar: {
                    container: [
                        [{'header': [H1, H2, H3, H4, p]}],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{color: []}],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        toolbarImportModules,
                        toolbarExtensionModules,
                    ],
                },
                imageCompress: {
                    maxWidth: 600
                },
                resize: {
                    locale: {},
                }
            };

            if (allowImages) {
                formats.push('image');
            }

            const container = containerRef.current;
            const quillContainer = container.appendChild(
                container.ownerDocument.createElement('article'),
            );

            quillContainer.id = name;
            ref.current = new Quill(quillContainer, {
                formats,
                modules,
                theme: 'snow',
            });

            return () => {
                container.innerHTML = '';
                ref.current = null;
            };
        }, [containerRef]);

        useEffect(() => {
            if (!ref?.current) return;

            const quillRef = ref.current;

            if (toolbarExtensions.length > 0) {
                // TODO: move to seperate file that's about variables
                const dropdownContainer = document.createElement('span');

                dropdownContainer.className = 'ql-formats custom-dropdown';
                dropdownContainer.innerHTML = `
                    <span class="ql-variables-dropdown">
                        <span class="ql-variables-dropdown-label">
                            Variabelen
                            <svg class="ql-variables-dropdown-icon" viewBox="0 0 18 18">
                                <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon><polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon>
                            </svg>
                        </span>
                        <span class="ql-variables-dropdown-options ql-picker-options">
                            ${renderVariableOption()}
                        </span>
                    </span>
                `;

                const toolbarElement = document.querySelector(`#${name}Container .ql-toolbar`);
                toolbarElement.appendChild(dropdownContainer);

                const variablesDropdown = dropdownContainer.querySelector(`#${name}Container .ql-variables-dropdown`);
                const variablesSelect = dropdownContainer.querySelector(`#${name}Container .ql-variables-dropdown .ql-variables-dropdown-label`);

                document.addEventListener('pointerdown', (event) => {
                    if (!variablesDropdown.contains(event.target as Node)) {
                        variablesDropdown.classList.remove('expanded');
                    }
                });

                variablesSelect.addEventListener('mousedown', (event) => {
                    event.preventDefault();
                    variablesDropdown.classList.toggle('expanded');
                    const range = quillRef.getSelection();
                    if (value && range) {
                        setTimeout(() => {
                            quillRef.setSelection(range.index);
                        }, 10);
                    }
                });

                dropdownContainer.querySelectorAll(`#${name}Container .ql-variables-dropdown .ql-variables-dropdown-options`).forEach((item) => {
                    item.addEventListener('mousedown', (event: MouseEvent) => {
                        event.preventDefault();

                        const variableValue = (event.target as Element).getAttribute('value');
                        const range = quillRef.getSelection();

                        if (variableValue && range) {
                            const delta: Delta = quillRef.insertEmbed(
                                range.index,
                                'variables-dropdown',
                                variableValue,
                                Quill.sources.USER
                            );
                            setTimeout(() => {
                                variablesDropdown.classList.remove('expanded');
                                quillRef.setSelection(delta.length());
                            }, 1)
                        }
                    });
                });
                // END "move to seperate file that's about variables"
            }

            ref.current?.on(Quill.events.TEXT_CHANGE, handleChange);

            return () => {
                ref.current?.off(Quill.events.TEXT_CHANGE, handleChange);
            };
        }, [ref]);

        useEffect(() => {
            ref.current?.enable(!disabled);
        }, [disabled]);

        useEffect(() => {
            if (variables) {
                value = replaceVariables(value, variables);
            }

            valueRef.current = value;

            const quillRef = ref.current;

            if (quillRef && quillRef.root.innerHTML !== valueRef.current) {
                const range = quillRef.getSelection();
                let length = 0;
                if (range) {
                    length = quillRef.getContents()?.length();
                }

                quillRef.setContents(quillRef.clipboard.convert({html: valueRef.current}));

                length -= quillRef.getContents()?.length();

                if (range && length > 0) {
                    quillRef.setSelection(range.index - length);
                }
            }
        }, [value]);

        useLayoutEffect(() => {
            onChangeRef.current = onChange;
        });

        const renderVariableOption = (): string => {
            return variables.map(variable => {
                return `<div class="ql-variables-dropdown-option" value="${variable}">{{${variable}}}</div>`;
            }).join('\n');
        }

        const handleChange = () => {
            const quillContent = ref.current?.root.innerHTML;

            if (variables) {
                replaceVariables(ref.current.root.innerHTML, variables);
            }

            if (ref.current?.root.innerHTML !== valueRef.current) {
                onChange(name, quillContent);
            }
        }

        return (
            <div
                ref={containerRef}
                id={`${name}Container`}
                className={classNames(styles.root, className && className)}
                hidden={hidden}
            ></div>
        );
    },
);

TextArea.displayName = 'TextArea';

export default TextArea;
