import ImmutableModel from '../../../../models/immutableModel/immutableModel'
import moment, {Moment} from 'moment'
import CandidateModel from '../../../../models/candidate/candidateModel'
import TimeInputModel from '../../../atoms/timeInput/model/timeInputModel'
import { HomeseekerApplicationForSelector } from '../../../../api/homeseeker-application/findHomeseekerApplicationsForSelector'

interface InviteViewingModalModelProps {
    readonly messageSubject: string
    readonly messageBody: string
    readonly messageRecipients: ReadonlyArray<CandidateModel>
    readonly invitationDate: Moment | null
    readonly invitationStartDateTime: Moment | null
    readonly invitationEndDateTime: Moment | null
    readonly includeDateAndTimeInEmail: boolean,
    readonly timeslotDurationInMinutes: string | null,
    readonly hasMaxNumberOfHomeseekersPerTimeslot: boolean,
    readonly maxNumberOfHomeseekersPerTimeslot: string | null,
}

export default class InviteViewingModalModel extends ImmutableModel<InviteViewingModalModel, InviteViewingModalModelProps> {

    public static create(initialMessageSubject: string, messageRecipients: CandidateModel[]): InviteViewingModalModel {

        return new InviteViewingModalModel({
            messageSubject: initialMessageSubject,
            messageBody: '',
            messageRecipients: messageRecipients,
            invitationDate: null,
            invitationStartDateTime: null,
            invitationEndDateTime: null,
            includeDateAndTimeInEmail: true,
            timeslotDurationInMinutes: null,
            hasMaxNumberOfHomeseekersPerTimeslot: false,
            maxNumberOfHomeseekersPerTimeslot: '',
        })
    }

    public getMinimumSelectableInvitationDate(): Moment {
        return moment().seconds(0)
    }

    public updateInvitationDate(year: number, month: number, dateDay: number): InviteViewingModalModel {
        if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(dateDay)) {
            return this;
        }

        const date = moment().year(year).month(month).date(dateDay).hours(0).minutes(0).seconds(0)

        let invitationStartTime = null
        if (this.value.invitationStartDateTime !== null) {
            invitationStartTime = date.clone()
                .hour(this.value.invitationStartDateTime.hour())
                .minute(this.value.invitationStartDateTime.minute())
        }
        let invitationEndTime = null
        if (this.value.invitationEndDateTime !== null) {
            invitationEndTime = date.clone()
                .hour(this.value.invitationEndDateTime.hour())
                .minute(this.value.invitationEndDateTime.minute())
        }

        return this.with({
            invitationDate: date,
            invitationStartDateTime: invitationStartTime,
            invitationEndDateTime: invitationEndTime
        })
    }

    public updateInvitationStartDateTimeMaintainingTimeDiff(val: TimeInputModel): InviteViewingModalModel {
        if (val.value.time === null) {
            return this.with({
                invitationStartDateTime: null,
                invitationEndDateTime: this.value.invitationEndDateTime
            });
        }

        const baseDate = this.value.invitationDate ? this.value.invitationDate.clone() : moment().seconds(0)
        const newStartDateTime = baseDate.clone().hour(val.getHour()).minute(val.getMinute())
        let difference = moment.duration(1, 'hour')
        let newEndDateTime: Moment | null = null;

        if (this.value.invitationStartDateTime !== null && this.value.invitationEndDateTime !== null) {
            difference = moment.duration(newStartDateTime.diff(this.value.invitationStartDateTime.clone()))
        } else if (this.value.invitationStartDateTime === null && this.value.invitationEndDateTime !== null) {
            difference = moment.duration(0, 'hour')
        }

        if (this.value.invitationEndDateTime !== null) {
            newEndDateTime = this.value.invitationEndDateTime.clone().add(difference)
            newEndDateTime.day(baseDate.day());

            let minutes = newEndDateTime.minute();
            minutes = Math.ceil(minutes / 5) * 5;
            newEndDateTime = newEndDateTime.minute(minutes);
        }

        return this.with({
            invitationStartDateTime: newStartDateTime,
            invitationEndDateTime: newEndDateTime
        });
    }

    public updateInvitationEndDateTime(val: TimeInputModel): InviteViewingModalModel {
        if (val.value.time === null) {
            return this.with({
                invitationEndDateTime: null
            });
        }

        const baseDate = this.value.invitationDate ? this.value.invitationDate.clone() : moment().seconds(0)

        const newEndDateTime = baseDate
            .clone()
            .hour(val.getHour())
            .minute(val.getMinute())

        return this.with({
            invitationEndDateTime: newEndDateTime
        })
    }

    public updateTimeslotDuration(timeslotDurationInMinutes: string): InviteViewingModalModel {
        return this.with({
            timeslotDurationInMinutes
        })
    }

    public getRecipientNames(): string[] {
        return this.value.messageRecipients.map(c => c.firstName)
    }

    public getHomeseekerApplicationsForSelector(): HomeseekerApplicationForSelector[] {
        return this.value.messageRecipients.map(c => {
            return {
                id: c.id,
                fullName: c.fullName
            }
        });
    }

    public getInvitationStartTimeInputModel(): TimeInputModel {
        return new TimeInputModel({time: this.value.invitationStartDateTime?.format('HH:mm')})
    }

    public getInvitationEndTimeInputModel(): TimeInputModel {
        return new TimeInputModel({time: this.value.invitationEndDateTime?.format('HH:mm')})
    }

    public getViewingDuration(): number | null {
        if (!this.value.invitationEndDateTime) {
            return null;
        }

        if (!this.value.invitationStartDateTime) {
            return null;
        }

        return this.value.invitationEndDateTime.diff(this.value.invitationStartDateTime, 'minutes');
    }

    public isMessageSubjectInvalid(): boolean {
        return this.value.messageSubject.length === 0
    }

    public isMessageBodyInvalid(): boolean {
        return this.value.messageBody.length === 0 || this.value.messageBody === '<p><br></p>'
    }

    public isInvitationStartDateTimeInThePast(): boolean {
        if (this.value.invitationStartDateTime === null) {
            return false
        }

        return this.value.invitationStartDateTime.isBefore(moment())
    }

    public isInvitationStartDateTimeTheSameAsEndDateTime(): boolean {
        if (this.value.invitationStartDateTime === null || this.value.invitationEndDateTime === null) {
            return false
        }

        return this.value.invitationEndDateTime.isSame(this.value.invitationStartDateTime)
    }

    public isInvitationStartDateTimeTooCloseToCurrentTime(): boolean {
        if (this.value.invitationStartDateTime === null) {
            return false
        }

        return (
            this.value.invitationStartDateTime.isSameOrBefore(moment().add(14, 'minute').add(59, 'second')) &&
            this.value.invitationStartDateTime.isSameOrAfter(moment())
        )
    }

    public isInvitationEndDateTimeBeforeStartDateTime(): boolean {
        if (this.value.invitationEndDateTime === null) {
            return false
        }

        return this.value.invitationEndDateTime.isBefore(this.value.invitationStartDateTime)
    }

    public isInvitationDateInvalid(): boolean {
        const m = this.value.invitationDate

        if (m === null || isNaN(m.date()) || isNaN(m.month()) || isNaN(m.year())) {
            return false
        }

        return true
    }

    public isInvitationStartDateTimeInvalid(): boolean {
        const m = this.value.invitationStartDateTime

        if (m === null || isNaN(m.hour()) || isNaN(m.minute())) {
            return false
        }

        return true
    }

    public isInvitationEndDateTimeInvalid(): boolean {
        const m = this.value.invitationEndDateTime

        if (m === null || isNaN(m.hour()) || isNaN(m.minute())) {
            return false
        }

        return true
    }
    public isMaxNumberOfHomeseekersValid(): boolean {
        if (!this.value.hasMaxNumberOfHomeseekersPerTimeslot) {
            return true;
        }

        return (
            this.value.maxNumberOfHomeseekersPerTimeslot !== '' &&
            Number(this.value.maxNumberOfHomeseekersPerTimeslot) > 0
        );
    }

    public isSubmitButtonEnabled(): boolean {
        if (!this.value.invitationDate || !this.value.invitationStartDateTime || !this.value.invitationEndDateTime || !this.isMaxNumberOfHomeseekersValid() ) {
            return false
        }

        return this.value.messageRecipients.length > 0 &&
            this.value.invitationDate.isSameOrAfter(moment(), 'day') &&
            !this.isMessageSubjectInvalid() &&
            !this.isMessageBodyInvalid() &&
            !this.isInvitationStartDateTimeInThePast() &&
            !this.isInvitationStartDateTimeTheSameAsEndDateTime() &&
            !this.isInvitationStartDateTimeTooCloseToCurrentTime() &&
            !this.isInvitationEndDateTimeBeforeStartDateTime()
    }
}
