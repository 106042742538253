import AgentsOverview from './pages/admin/AgentsOverview';
import AgentDetails from './pages/admin/agents/AgentDetails';
import MergeDuplicateTransactionalListingsPage from './pages/admin/mergeDuplicateTransactionalListings/MergeDuplicateTransactionalListingsPage';
import DiscardedLeadsPage from './pages/admin/discardedLeads/DiscardedLeadsPage';
import ListingsOverview from './pages/dashboard/listings/ListingsOverview';
import ListingDetails from './pages/dashboard/listings/ListingDetails';
import CandidateProfileContainer from './pages/dashboard/candidates/CandidateProfileContainer';
import SettingsPage from './pages/settings/SettingsPage';
import ViewingAttendancePage from './componentsNew/pages/viewingAttendancePage/ViewingAttendancePage';
import EnrichingFormPage from './pages/enriching-form/EnrichingFormPage';
import ContactRequestPage from './pages/contactRequestPage/ContactRequestPage';
import CookieDeclarationPage from './pages/cookies/CookieDeclarationPage';
import EmailAddressConfirmationPage from './pages/emailAddressConfirmation/EmailAddressConfirmationPage';
import ViewingInvitiationAcceptedPage from './componentsNew/pages/viewingInvitationAccepted/ViewingInvitiationAcceptedPage';
import ViewingInvitationDeclinedPage from './componentsNew/pages/viewingInvitationDeclined/ViewingInvitationDeclinedPage';
import ScheduleViewingPage from './componentsNew/pages/scheduleViewing/ScheduleViewingPage';
import LoginPage from './pages/login/LoginPage';
import LogoutPage from './pages/LogoutPage';
import SSOLoginPage from './pages/login/SSOLoginPage';
import MarketingMessagesSubscribePage from './pages/marketingMessagesSubscribe/MarketingMessagesSubscribePage';
import MarketingMessagesUnsubscribePage from './pages/marketingMessagesUnsubscribe/MarketingMessagesUnsubscribePage';

const routes = {
    authenticatedRoutes: [
        {path: '/admin', component: AgentsOverview},
        {path: '/admin/agents/:id', component: AgentDetails},
        {path: '/admin/dubbele-advertenties', component: MergeDuplicateTransactionalListingsPage},
        {path: '/admin/onverwerkbare-leads', component: DiscardedLeadsPage},
        {path: '/admin/onverwerkbare-leads/:agentId', component: DiscardedLeadsPage},
        {path: '/dashboard/advertenties', component: ListingsOverview},
        {path: '/dashboard/advertenties/:id', component: ListingDetails},
        {path: '/dashboard/advertenties/:id/:status', component: ListingDetails},
        {path: '/dashboard/advertenties/:id/kandidaten/:pid', component: CandidateProfileContainer},
        {path: '/dashboard/listings', component: ListingsOverview},
        {path: '/dashboard/listings/:id', component: ListingDetails},
        {path: '/instellingen', component: SettingsPage},
        {path: '/listing/:listingId/viewings/:viewingId/overview', component: ViewingAttendancePage},
        {path: '/listing/:listingId/viewings/:viewingId/overview/:candidateId', component: ViewingAttendancePage},
        {path: '/settings', component: SettingsPage},
        {path: '/logout', component: LogoutPage},
    ],
    guestRoutes: [
        {path: '/login', component: LoginPage},
        {path: '/sso-login', component: SSOLoginPage},
    ],
    unauthenticatedRoutes: [
        {path: '/applicant-form/:id', component: EnrichingFormPage},
        {path: '/applicant-form/:id/:languageCode', component: EnrichingFormPage},
        {path: '/contact-request/:id', component: ContactRequestPage},
        {path: '/contact-verzoek/:id', component: ContactRequestPage},
        {path: '/cookies', component: CookieDeclarationPage},
        {path: '/email-address-confirmation/:id', component: EmailAddressConfirmationPage},
        {path: '/email-address-confirmation/:id/:listingCity', component: EmailAddressConfirmationPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/accepted', component: ViewingInvitiationAcceptedPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/declined', component: ViewingInvitationDeclinedPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/schedule', component: ScheduleViewingPage},
        {path: '/subscribe-to-marketing-messages/:id', component: MarketingMessagesSubscribePage},
        {path: '/subscribe-to-marketing-messages/:id/:listingCity', component: MarketingMessagesSubscribePage},
        {path: '/unsubscribe-from-marketing-messages/:id', component: MarketingMessagesUnsubscribePage},
    ]
};

export function catchAllRedirectPath(isAuthenticated: boolean): string
{
    return isAuthenticated ? '/dashboard/advertenties' : '/login';
}

export default routes;
