import api from '../../../utils/api';

export interface Agency {
    readonly id: string;
    readonly name: string;
}

export interface FetchAgencyResponse {
    readonly result: Agency[];
}

const fetchAgencies = async (): Promise<FetchAgencyResponse> => {
    return await api.get('api/get-agencies');
};

export default fetchAgencies;
