import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
    ChevronDownIcon,
} from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import CandidateStatus from '../../enums/candidateStatus';
import ListingStatus from '../../enums/listingStatus';
import styles from './BulkActionDropdown.module.css';
import Text from '../typography/text/Text';
import fontSizeEnum from '../typography/enums/fontSizeEnum';
import colorEnum from '../../enums/colorEnum';
import fontWeightEnum from '../typography/enums/fontWeightEnum';



export interface BulkActionDropdownProps {
    readonly selectedListings?: ReadonlyArray<string>
    readonly type?: string
    readonly hidden?: boolean
    readonly handleBulkStatusChange?: (status: string) => void,
    readonly toggleMessageModal?: (isModalOpen: boolean) => void
    readonly selectedPeople?: ReadonlyArray<string>,
    readonly isMessageModalOpen?: boolean,
    readonly onArchiveClick?: () => void
    readonly onUnarchiveClick?: () => void
    readonly toggleExtraInfoModal?: (isModalOpen: boolean) => void
    readonly candidateStatus?: CandidateStatus
    readonly listingStatus?: ListingStatus
    readonly toggleRejectionModal?: (isModalOpen: boolean, shouldRejectAll: boolean) => void
    readonly toggleInviteToViewingModal?: (isModalOpen: boolean) => void
    readonly allItems?: number
}

export default function BulkActionDropdown(props: BulkActionDropdownProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const amountOfItems = (props.selectedPeople?.length || props.selectedListings?.length) ?? 0

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function bulkActionText() {
        if (props.hidden) {
            return t('listings.bulkActions.caption')
        } else {
            return t('listings.bulkActions.caption.amount', {amountOfItems})
        }
    }

    return (
        <Menu as="span" className="relative inline-block text-left ">
            <div>
                <Menu.Button
                    disabled={props.hidden}
                    className={classNames('inline-flex items-center rounded-md border  text-md font-medium whitespace-nowrap px-5 py-2 z-0',
                        props.hidden ? 'border-gray-300 text-gray-400' : 'bg-white  text-gray-900 shadow-sm',
                        'focus:outline-none focus:ring-2 focus:ring-primary',
                        'focus:ring-offset-2')}>
                    {bulkActionText()}
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={`
                ${styles.mainContainer}
                ${classNames(' origin-top-left absolute left-0 mt-2 w-[256px]', 'rounded-md shadow-lg bg-white ring-1 ring-black', 'ring-opacity-5 divide-y divide-gray-100 focus:outline-none', props.hidden ? 'hidden' : null)}`}>
                    <div className={styles.bulkActionHeader}>
                        <Text size={fontSizeEnum.Sm} color={colorEnum.Gray800} weight={fontWeightEnum.Medium}>
                            <Trans
                                i18nKey="listings.bulkActions.amount"
                                values={{amountOfItems}}
                                components={{strong: <strong/>}}
                            />
                        </Text>
                    </div>
                    <div className="py-1">
                        {props.type === 'candidates' ?
                            <div className={styles.bulkActionContainer}>
                                <Menu.Item>
                                    {({active}) => (

                                        <a
                                            onClick={() => dispatch(props.handleBulkStatusChange('promote'))}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm cursor-pointer'
                                            )}
                                        >
                                            <i className="ri-thumb-up-line"></i>
                                            <Text size={fontSizeEnum.Sm} color={colorEnum.Gray800}
                                                weight={fontWeightEnum.Medium}>
                                                {t('bulk.dropdown.mark.as.approved')}
                                            </Text>
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({active}) => (
                                        <a
                                            onClick={() => dispatch(props.handleBulkStatusChange('demote'))}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm cursor-pointer'
                                            )}
                                        >
                                            <i className="ri-pause-circle-line"></i>
                                            <Text size={fontSizeEnum.Sm} color={colorEnum.Gray800}
                                                weight={fontWeightEnum.Medium}>
                                                {t('bulk.dropdown.queue')}
                                            </Text>
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({active}) => (
                                        <a
                                            onClick={() => props.toggleExtraInfoModal(true)}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm cursor-pointer'
                                            )}
                                        >
                                            <i className="ri-survey-line"></i>
                                            <Text size={fontSizeEnum.Sm} color={colorEnum.Gray800}
                                                weight={fontWeightEnum.Medium}>
                                                {t('bulk.dropdown.extra.info')}
                                            </Text>
                                        </a>
                                    )}
                                </Menu.Item>
                                {props.candidateStatus === CandidateStatus.Suitable &&
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    onClick={() => props.toggleInviteToViewingModal(true)}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm cursor-pointer'
                                                    )}
                                                >
                                                    <i className="ri-calendar-line"></i>
                                                    <Text size={fontSizeEnum.Sm} color={colorEnum.Gray800}
                                                        weight={fontWeightEnum.Medium}>
                                                        {t('bulk.dropdown.invite.for.viewing')}
                                                    </Text>
                                                </a>
                                            )}
                                        </Menu.Item>
                                }
                                <>
                                    {props.candidateStatus === null || props.candidateStatus === 'rejected' ? '' : <>
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    onClick={() => props.toggleRejectionModal(true, false)}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm cursor-pointer'
                                                    )}
                                                >
                                                    <i className="ri-thumb-down-line"></i>
                                                    <Text size={fontSizeEnum.Sm} color={colorEnum.Gray800}
                                                        weight={fontWeightEnum.Medium}>
                                                        {t('bulk.dropdown.reject')}
                                                    </Text>
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </>
                                    }
                                </>
                                <Menu.Item>
                                    {({active}) => (
                                        <a
                                            onClick={() => props.toggleMessageModal(true)}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm cursor-pointer'
                                            )}
                                        >
                                            <i className="ri-mail-line"></i>
                                            <Text size={fontSizeEnum.Sm} color={colorEnum.Gray800}
                                                weight={fontWeightEnum.Medium}>
                                                {t('bulk.dropdown.message')}
                                            </Text>
                                        </a>
                                    )}
                                </Menu.Item>
                                <>
                                    {props.candidateStatus === null || props.candidateStatus === 'rejected' ? '' : <>
                                        <hr/>
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    onClick={() => props.toggleRejectionModal(true, true)}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-red-500' : 'text-red-500',
                                                        'block px-4 py-2 text-sm cursor-pointer'
                                                    )}
                                                >
                                                    <div className="ri-thumb-down-line"></div>
                                                    <Text size={fontSizeEnum.Sm} color={colorEnum.Error600}
                                                        weight={fontWeightEnum.Medium}> {t('bulk.dropdown.all.candidates')} {t(`bulk.dropdown.candidate.status.${props.candidateStatus}`)} {t('bulk.dropdown.done', {allItems: props.allItems})}
                                                    </Text>
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </>
                                    }
                                </>
                            </div> : <>
                                <Menu.Item>
                                    {({ active }) => (
                                        props.listingStatus === ListingStatus.Open ?
                                            <a
                                                onClick={() => props.onArchiveClick?.()}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                {t('actions.archive')}
                                            </a> :
                                            <a
                                                onClick={() => props.onUnarchiveClick?.()}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                {t('actions.unarchive')}
                                            </a>
                                    )}
                                </Menu.Item>
                            </>
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

