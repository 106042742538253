enum ColorEnum {
    Inherit = 'inherit', // TODO: allow places where ColorEnum is allowed to pass string

    White = 'white',

    Gray25 = 'gray-25',
    Gray100 = 'gray-100',
    Gray200 = 'gray-200',
    Gray300 = 'gray-300',
    Gray400 = 'gray-400',
    Gray500 = 'gray-500',
    Gray600 = 'gray-600',
    Gray700 = 'gray-700',
    Gray800 = 'gray-800',
    Gray900 = 'gray-900',

    Primary700 = 'primary-700'
}

export function getColorCssVar(colorEnum: ColorEnum): string {
    if (colorEnum === ColorEnum.Inherit) { // TODO: remove this when TODO above is fixed
        return 'inherit';
    }

    return `var(--${colorEnum})`;
}

export default ColorEnum;
