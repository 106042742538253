import api, {request} from '../../../utils/api';
import {getListingDetails} from '../listings/getListingDetails';
import {setNotification} from '../ui/setNotification';
import {getCandidateDetails} from './getCandidateDetails';

export const changeCandidateStatus = (token, ids, transition, listingId, params, isNotified = false, featureSettingsPage) => dispatch => {
    if (featureSettingsPage && transition === 'reject') {
        dispatch({ type: 'START_LOADING' });
        api.post(`api/agency/homeseeker-applications/reject`, {
            homeseekerApplicationIds: ids,
            transactionalListingId: listingId,
            notifyHomeseeker: isNotified
        })
            .then(() => {
                const message = ids.length > 1 ? 'Kandidaten status aangepast' : 'Kandidaat status aangepast' ;
                dispatch(setNotification({
                    type: 'success',
                    message: message,
                    id: Date.now()
                }));
                if (listingId && params) {
                    dispatch(getListingDetails(token, listingId, params));
                } else {
                    dispatch(getCandidateDetails(token, ids[0]))
                        .catch((error) => {
                            console.error(error);
                            dispatch(setNotification({
                                id: Date.now(),
                                message: 'Er is iets fout gegaan',
                                type: 'failed'
                            }));
                        });
                }
            })
            .catch((e) => {
                dispatch({ type: 'STOP_LOADING' });
                console.error(e);
            })
            .finally(() => {
                dispatch({ type: 'STOP_LOADING' });
            });
    } else {
        dispatch({ type: 'START_LOADING' });
        request(token).post(`/api/bulk/candidates/status/${transition}`, {
            candidatesIds: [...ids],
            isNotified: isNotified
        })
            .then(() => {
                dispatch(setNotification({
                    type: 'success',
                    message: 'Kandidaat status aangepast',
                    id: Date.now()
                }));

                if (listingId && params) {
                    dispatch(getListingDetails(token, listingId, params));
                } else {
                    dispatch(getCandidateDetails(token, ids[0]));
                }
            })
            .catch((e) => {
                dispatch({ type: 'STOP_LOADING' });
                console.error(e);
            })
            .finally(() => {
                dispatch({ type: 'STOP_LOADING' });
            });
    }
};
