import ImmutableModel from '../../../../models/immutableModel/immutableModel';
import { Moment } from 'moment';

interface TimeInputModelProps {
    readonly time: string | null;
}

export default class TimeInputModel extends ImmutableModel<TimeInputModel, TimeInputModelProps> {

    public static createFromMomentDateTime(dateTime: Moment): TimeInputModel {
        return new TimeInputModel({ time: dateTime.format('HH:mm') });
    }

    public getHour(): number {
        const split = this.value.time.split(':')
        return parseInt(split.length === 2 ? split[0] : '0');
    }

    public getMinute(): number {
        const split = this.value.time.split(':')
        return parseInt(split.length === 2 ? split[1] : '0');
    }
}
