import api from '../../utils/api';

export interface Agency {
    readonly default_email: string;
    readonly logo: string;
    readonly name: string;
}

export interface Listing {
    readonly city: string;
    readonly house_number: string;
    readonly image_url: string;
    readonly postal_code: string;
    readonly price: number;
    readonly rooms: number;
    readonly show_house_number: boolean;
    readonly street: string;
    readonly surface: number;
}

export interface Timeslot {
    readonly duration: number;
    readonly startTime: string;
    readonly endTime: string;
    readonly hasAvailableSeats: boolean;
}

export interface Viewing {
    readonly end_date_time: string;
    readonly id: string;
    readonly maximum_invitees_per_timeslot: number;
    readonly minutes_per_timeslot: number;
    readonly start_date_time: string;
    readonly viewing_has_passed: boolean;
}

export interface GetViewingWithTimeslotsResponse {
    readonly agency: Agency;
    readonly listing: Listing;
    readonly timeslots: Timeslot[] | [];
    readonly viewing: Viewing;
}

const getViewingWithTimeslots = async (viewingId: string): Promise<GetViewingWithTimeslotsResponse> => {
    return await api.getWithoutAuth(`api/viewing/${viewingId}/timeslots`);
};

export default getViewingWithTimeslots;
