import {ReactNode, useState} from 'react';
import styles from './Collapse.module.css';

interface CollapseProps {
    toggler: ReactNode;
    children: ReactNode;
    defaultIsCollapsed?: boolean;
    onChange?: (collapse: boolean) => void;
}

const Collapse = ({
    toggler,
    children,
    defaultIsCollapsed = false,
    onChange
}: CollapseProps) => {
    const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed)

    const handleToggle = () => {
        const newCollapsedValue = !isCollapsed;

        setIsCollapsed(newCollapsedValue);
        onChange(newCollapsedValue);
    }

    return (
        <>
            <div
                className={styles.toggler}
                onClick={handleToggle}
            >
                {toggler}
            </div>
            {!isCollapsed &&
                <div>
                    {children}
                </div>
            }
        </>
    );
};

export default Collapse;
