import React, {useEffect, useRef, useState} from 'react';
import ButtonNew from './Button';
import Tooltip from '../../../components/tooltip/Tooltip';
import { ButtonWithIconBeforeProps } from './ButtonProps';

interface CopyButtonNewProps extends ButtonWithIconBeforeProps {
    readonly tooltipText: string;
    readonly textToCopy: string;
}

export default function CopyButton(props: CopyButtonNewProps) {
    const CopyButtonRef = useRef<HTMLDivElement>(null);

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isButtonUnFocused, setIsButtonUnFocused] = useState(false);
    const [, setCustomTimeout] = useState<undefined | NodeJS.Timeout>(undefined);

    function onClick(e) {
        if (!isTooltipVisible) {
            e.stopPropagation();
            navigator.clipboard.writeText(props.textToCopy).then(() => {
                setIsTooltipVisible(true);
                setIsButtonUnFocused(false);
                setCustomTimeout(setTimeout(() => {
                    setIsTooltipVisible(false);
                    setIsButtonUnFocused(true);
                }, 2000));
            });
        }
    }

    const handleOutsideClick = (event: PointerEvent) => {
        if (CopyButtonRef.current && !CopyButtonRef.current.contains(event.target as Node)) {
            setIsTooltipVisible(false);
            setIsButtonUnFocused(true);
            setCustomTimeout(undefined);
        }
    };

    useEffect(() => {
        document.addEventListener('pointerdown', handleOutsideClick);

        return () => {
            document.removeEventListener('pointerdown', handleOutsideClick);
        };
    }, []);

    return (
        <div ref={CopyButtonRef}>
            <Tooltip
                text={props.tooltipText}
                isVisible={isTooltipVisible}
                noHover={true}
            >
                <ButtonNew
                    variant={props.variant}
                    iconBefore={props.iconBefore}
                    onClick={onClick}
                    isUnFocused={isButtonUnFocused}
                />
            </Tooltip>
        </div>
    )
}
