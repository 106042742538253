import api from '../../../utils/api';
import {API_URIS} from '../../../utils/constants';

export const createAgentWithLogins = async (token, agentData, loginsToCreate) => {
    const response = await api.post(API_URIS.AGENTS_URI, agentData);

    const data = await response.json();
    const agentId = data.id;
    await api.post(`api/agents/add`, {agentEmails: loginsToCreate, agencyId: agentId});
};
