import api from '../../utils/api';

export interface Listing {
    readonly city: string;
    readonly houseNumber: string;
    readonly postalCode: string;
    readonly price: number;
    readonly rooms: number;
    readonly street: string;
    readonly surface: number;
}

export interface Viewing {
    readonly viewingId: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly actualNumberOfAttendees: number;
    readonly maximumNumberOfAttendees: number;
}

export interface FindListingViewingsOverviewResponse {
    readonly listing: Listing;
    readonly viewings: { [key: string]: Viewing[] }
}

const findListingViewingsOverview = async (listingId: string, viewingId: string): Promise<FindListingViewingsOverviewResponse> => {
    return await api.get(`api/listing/${listingId}/viewings/${viewingId}/overview`);
};

export default findListingViewingsOverview;
