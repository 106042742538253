import api from '../../utils/api';

export interface CoTenant {
    readonly id: string;
    readonly fullName: string;
    readonly email: string;
    readonly phoneNumber: string;
    readonly status: string;
    readonly outcome: string | null;
}

export interface ScreeningRequest {
    id: string;
    rating: number | null;
    status: string;
    outcome: string | null;
    coTenants: CoTenant[];
}

interface FindScreeningRequestResponse {
    result: ScreeningRequest;
}

const findScreeningRequest = async (screeningRequestId: string): Promise<FindScreeningRequestResponse> => {
    return await api.get(`api/get-screening-request/${screeningRequestId}`);
};

export default findScreeningRequest;
