import api, { BodyData } from '../../utils/api';

export interface DeclineViewingPayload extends BodyData {
    viewingId: string;
    homeseekerApplicationId: string;
}

const declineViewing = async (data: DeclineViewingPayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/homeseeker/viewing/decline`, data);
};

export default declineViewing;
