import FontSizeEnum from '../../components/typography/enums/fontSizeEnum'
import FontWeightEnum from '../../components/typography/enums/fontWeightEnum'
import ColorEnum from '../../enums/colorEnum'
import Title from '../../components/typography/title/Title'
import style from './ErrorPage.module.css'
import Button from '../../components/buttons/button/Button'
import ButtonType from '../../components/buttons/button/logic/models/buttonType'
import Text from '../../components/typography/text/Text'
import TopNavigation from '../../components/navigation/TopNavigation'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import IconWithFlowyBackground from '../../components/molecules/iconWithFlowyBackground/IconWithFlowyBackground'

export default function ErrorPage() {

    const {t} = useTranslation()

    return (
        <>
            <TopNavigation/>
            <div className={style.root}>
                <IconWithFlowyBackground icon={'ri-emotion-sad-line'}/>
                <Title
                    size={FontSizeEnum.Sm}
                    weight={FontWeightEnum.Bold}
                    color={ColorEnum.Gray700}
                >
                    {t('errorPage.general.title')}
                </Title>
                <Button
                    title={t('errorPage.general.toHome')}
                    buttonType={ButtonType.Primary}
                    onClick={() => location.replace('/')}
                />
                <Text
                    size={FontSizeEnum.Md}
                    color={ColorEnum.Gray500}
                >
                    <Trans
                        i18nKey="errorPage.general.support"
                        components={{ Link: <a /> }}
                    />
                </Text>
            </div>
        </>
    )
}
