import moment from 'moment';
import Text from '../../atoms/typography/Text';
import { FontSizeEnum, FontWeightEnum } from '../../enums';
import { CalendarWidget } from '../calendar-widget';
import styles from './ViewingDetails.module.css';
import currentLanguage from '../../utils/currentLanguage';
import 'moment/locale/nl';
import { Viewing } from '../../../api/viewing/findViewingAttendance';
import AdditionalViewingDetails from '../additional-viewing-details/AdditionalViewingDetails';

interface ViewingDetailsProps {
    viewing: Viewing;
}

const ViewingDetails = ({
    viewing
}: ViewingDetailsProps) => {
    const locale = currentLanguage();
    const startDateMoment = moment(viewing.startDateTime).locale(locale);
    const startDate = startDateMoment.format('dddd, DD MMMM YYYY');
    const startTime = startDateMoment.format('HH:mm');
    const endTime = moment(viewing.endDateTime).locale(locale).format('HH:mm');

    return (
        <div className={styles.ViewingDetails}>
            <div>
                <CalendarWidget date={startDateMoment} />
            </div>
            <div className={styles.grid}>
                <div className={styles.date}>
                    <Text
                        size={FontSizeEnum.Md}
                        weight={FontWeightEnum.Regular}
                    >{startDate}</Text>
                </div>
                <AdditionalViewingDetails
                    startTime={startTime}
                    endTime={endTime}
                    actualNumberOfAttendees={viewing.actualNumberOfAttendees}
                    maximumNumberOfAttendees={viewing.maximumNumberOfAttendees}
                />
            </div>
        </div>
    );
}

export default ViewingDetails;
