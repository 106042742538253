import AgentDetailsFormServiceInterface from '../components/forms/agentDetails/logic/service/agentDetailsFormServiceInterface';
import AgentJsonToModelConverter, {AgentJSON} from '../converters/agentJsonToModelConverter';
import ApiService from '../enums/apiService';
import AgentModel from '../models/agent/agentModel';
import api from '../utils/api';

export default class AgentDetailsFormService implements AgentDetailsFormServiceInterface {
    public async getAgent(id: string): Promise<AgentModel> {
        const res: AgentJSON = await api.get(`api/agents/${id}`, {}, ApiService.Leads);

        return AgentJsonToModelConverter.convert(res);
    }
}
