import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams, useSearchParams} from 'react-router-dom';
import {getListingDetails} from '../../../redux/actions/listings/getListingDetails';
import {useSelector, useDispatch} from 'react-redux';
import TopNavigation from '../../../components/navigation/TopNavigation';
import ListingsBucket from '../../../components/tabs/ListingsBucket';
import CandidatesTable from '../../../components/tables/candidatesTable/CandidatesTable';
import PaginationBar from '../../../components/tables/pagination/PaginationBar';
import {changeCandidateStatus} from '../../../redux/actions/candidates/setCandidateStatus';
import ExtraInformationModal from '../../../components/modals/ExtraInformationModal';
import {requestExtraInformation} from '../../../redux/actions/candidates/getExtraInformation';
import {createMessage} from '../../../redux/actions/messages/createMessages';
import {toggleAutomaticEnriching} from '../../../redux/actions/listings/setAutoEnriching';
import AutomaticEnrichingModal from '../../../components/modals/AutomaticEnrichingModal';
import RejectionConfirmationModal from '../../../components/modals/RejectionConfirmationModal';
import {setAllCandidatesStatusById} from '../../../redux/actions/candidates/setAllCandidatesStatusById';
import CandidateDetailsSidePanel
    from '../../../components/organisms/candidateDetailsSidePanel/CandidateDetailsSidePanel';
import InviteToViewingModal from '../../../components/modals/viewing/InviteToViewingModal';
import PaginationBarModel from '../../../components/tables/pagination/logic/model/paginationBarModel';
import ListingDetailsHeader from './listingDetailsHeader/ListingDetailsHeader';
import AddressModel from '../../../models/listing/addressModel';
import ListingPropertiesModel from '../../../components/listings/listingProperties/logics/model/listingPropertiesModel';
import ListingStatusTimerModel from '../../../models/listing/listingStatusTimerModel';
import {getAllCandidates} from '../../../redux/actions/candidates/getCandidates';
import ScreeningModalModel from '../../../components/modals/screeningModal/logic/models/screeningModalModel';
import ScreeningModal from '../../../components/modals/screeningModal/ScreeningModal';
import ScreeningModalService from '../../../services/screeningModalService';
import AssignTenantModalModel from '../../../components/modals/AssignTenantModal/logic/models/AssignTenantModalModel';
import AssignTenantModal from '../../../components/modals/AssignTenantModal/AssignTenantModal';
import AssignTenantModalService from '../../../services/assignTenantModalService';
import styles from './ListingDetails.module.css';
import api from '../../../utils/api';
import useAuth from '../../../hooks/useAuth';
import CreateNoteModal from '../../../components/modals/CreateNoteModal';
import CandidateFilterStatus from '../../../enums/candidateFilterStatus';
import {setNotification} from '../../../redux/actions/ui/setNotification';
import CandidateDetailsSidePanelModel
    from '../../../components/organisms/candidateDetailsSidePanel/models/candidateDetailsSidePanelModel';
import ScreeningRequestJsonToModelConverter from '../../../converters/screeningRequestJsonToModelConverter';
import CandidateModel from '../../../models/candidate/candidateModel';
import ViewingInviteErrorModal from '../../../components/modals/viewing/ViewingInviteErrorModal';
import ListingDetailsService from '../../../services/listingDetailsService';
import SendMessageModal from '../../../components/modals/SendMessageModal';
import GenerateContactRequestLinkModal
    from '../../../components/modals/generateContactRequestLinkModal/GenerateContactRequestLinkModal';
import {useTranslation} from 'react-i18next';
import {useFeature} from '@growthbook/growthbook-react';
import {PageWithSidePanel} from '../../../componentsNew/atoms/page-with-side-panel';
import { PageContainer } from '../../../componentsNew/atoms/page-container';
import { SidePanel } from '../../../componentsNew/organisms/side-panel';


function ListingDetails() {
    const listingId = useParams()?.id
    const dispatch = useDispatch()
    const [queryParams, setQueryParams] = useSearchParams()
    const status = queryParams.get('status');
    const selectedCandidateId = queryParams.get('selectedCandidateId');
    const {user} = useAuth()
    const FEATURE_SETTINGS_PAGE = useFeature('settings-page').value

    const {
        candidates,
        city,
        houseNumber,
        postalCode,
        price,
        street,
        surface,
        rooms,
        enrichment,
        address,
        numberOfLeads,
        numberOfLeadsToAssess,
        numberOfSuitableLeads,
        numberOfRejectedLeads,
        numberOfNotSuitableLeads,
        numberOfUnassessedLeads,
        closedAt,
    } = useSelector(state => state.listings.selectedListing)

    const {token} = useSelector((state) => state.users)
    const {isLoading} = useSelector((state) => state.ui)

    const [screeningModalModel, setScreeningModalModel] = useState(ScreeningModalModel.createEmpty())
    const [assignTenantModalModel, setAssignTenantModalModel] = useState(AssignTenantModalModel.createEmpty())
    const [selectedStatus, setSelectedStatus] = isValidStatusSelected() ? useState(status) : useState(CandidateFilterStatus.None)
    const [paginationBarModel, setPaginationBarModel] = useState(new PaginationBarModel({
        totalItems: 0,
        currentPage: 1,
        itemsPerPage: 25
    }))
    const [query, setQuery] = useState('')
    const [income, setIncome] = useState(null)
    const [pets, setPets] = useState(null)
    const [sortType, setSortType] = useState('income.desc')
    const [householdType, setHouseholdType] = useState([])
    const [extraInfoModalOpen, setExtraInfoModalOpen] = useState(false)
    const [isInviteToViewingModalOpen, setInviteToViewingModalOpen] = useState(false)
    const [autoEnrichingModalOpen, setAutoEnrichingModalOpen] = useState(false)
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
    const [isFiltersDropdownOpen, setIsFiltersDropdownOpen] = useState(false)
    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false)
    const [isRejectingAll, setIsRejectingAll] = useState(false)
    const [selectedPeople, setSelectedPeople] = useState([])
    const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false)
    const [isGenerateContactRequestLinkModalOpen, setIsGenerateContactRequestLinkModalOpen] = useState(false)
    const [sidePanelModel, setSidePanelModel] = useState(CandidateDetailsSidePanelModel.createEmpty())
    const [viewingInviteError, setViewingInviteError] = useState(false)
    const [clearInvitetoViewingModal, setClearInvitetoViewingModal] = useState(false);

    const service = useRef(new ListingDetailsService())
    const screeningModalService = useRef(new ScreeningModalService())
    const assignTenantModalService = useRef(new AssignTenantModalService())

    const {t} = useTranslation()

    useEffect(() => {
        if (token && listingId) {
            fetchListingsDetails()
        }
    }, [token, listingId])

    useEffect(() => {
        setPaginationBarModel(prev => prev.with({totalItems: candidates.totalItems}))
    }, [candidates.totalItems])

    useEffect(() => {
        if (sortType && householdType && enrichment && paginationBarModel) {
            fetchCandidates()
        }
    }, [sortType, pets, householdType, income, selectedStatus, enrichment, query, paginationBarModel])

    useEffect(() => {
        if (!status) {
            changeStatusTab(CandidateFilterStatus.None)
        }
        setSelectedPeople([])
        setSelectedStatus(status)

    }, [queryParams.get('status')])

    useEffect(() => {
        SidePanel.setOpen(selectedCandidateId !== null);
        fetchSidePanelData()
    }, [selectedCandidateId])

    const fetchListingsDetails = () => {
        dispatch(getListingDetails(token, listingId))
    }

    async function fetchSidePanelData() {
        if (selectedCandidateId === null) {
            return
        }

        try {
            setSidePanelModel(CandidateDetailsSidePanelModel.createEmpty())

            const homeseekerApplicationResponse = await api.get(`api/get-homeseeker-application/${selectedCandidateId}`)
            const candidateModel = CandidateModel.fromJson({...homeseekerApplicationResponse.result})
            let withCandidate = sidePanelModel.with({candidateModel: candidateModel})

            if (candidateModel.screeningRequestId) {
                const screeningRequestResponse = await api.get(`api/get-screening-request/${candidateModel.screeningRequestId}`)
                withCandidate = withCandidate.with({
                    screeningRequestModel: ScreeningRequestJsonToModelConverter.convert({...screeningRequestResponse.result})
                })
            }

            setSidePanelModel(withCandidate.with({isCandidateLoading: false}));
        } catch (error) {
            setSidePanelModel(sidePanelModel.with({isCandidateLoading: false}));
        }
    }

    const fetchCandidates = (ignoreCache = false) => {
        const params = generateParams(sortType, query, income, pets, paginationBarModel.value.currentPage, householdType, paginationBarModel.value.itemsPerPage, selectedStatus)
        dispatch(getAllCandidates(token, listingId, params, ignoreCache))
    }

    const selectHousehold = (h) => {
        if (Array.isArray(householdType)) {
            if (householdType.includes(h)) {
                setHouseholdType(householdType.filter(household => household !== h))
            } else {
                setHouseholdType([...householdType, h])
            }
        } else {
            setHouseholdType([h])
        }
    }

    const generateParams = (sortType, query, income, pets, page, householdType, itemsPerPage, selectedStatus) => {
        let urlQueryParams = {}
        const [sortBy, direction] = sortType.split('.')

        urlQueryParams = {
            fullName: query,
            pets: pets,
            minimumIncome: income,
            order: sortBy,
            sortDirection: direction,
            page: page,
            itemsPerPage: itemsPerPage,
            pagination: true
        }

        if (selectedStatus) {
            urlQueryParams['status'] = selectedStatus
        }

        if (householdType.length > 0) {
            urlQueryParams['householdType'] = structureHouseholdTypeQueryParamString(householdType)
        }

        return urlQueryParams
    }

    const structureHouseholdTypeQueryParamString = (householdType) => {
        return householdType.join(',')
    }

    const confirmBulkRejection = (isModalOpen, shouldRejectAll) => {
        isModalOpen ? setIsRejectionModalOpen(true) : setIsRejectionModalOpen(false)
        shouldRejectAll ? setIsRejectingAll(true) : setIsRejectingAll(false)
    }

    const approveHomeseekerApplication = async (id) => {
        try {
            await api.post(`api/homeseeker/application/${id}/approve`);
        } catch(error) {
            dispatch(setNotification({
                type: 'failed',
                message: error.response.data.error || t('candidate.action.approve.failed'),
                id: Date.now()
            }));
        }

        dispatch(setNotification({
            type: 'success',
            message: t('candidate.action.approved'),
            id: Date.now()
        }));
        setSelectedPeople([]);
        fetchCandidates(true);
    }

    const handleCandidateStatus = (status, idx, isNotified = false) => {
        if (status === 'promote') {
            return approveHomeseekerApplication(idx);
        }

        const params = generateParams(sortType, query, income, pets, paginationBarModel.value.currentPage, householdType, paginationBarModel.value.itemsPerPage, selectedStatus)
        const ids = selectedPeople.map((person) => person.id)


        if (FEATURE_SETTINGS_PAGE && status === 'reject') {
            const homeseekerApplicationIds = ids.length > 0 ? ids : [idx];
            dispatch(changeCandidateStatus(token, homeseekerApplicationIds, status, listingId, params, isNotified, FEATURE_SETTINGS_PAGE))
            setSelectedPeople([])
        } else {
            if (listingId) {
                dispatch(changeCandidateStatus(token, [idx], status, listingId, params, isNotified))
                setSelectedPeople([])
            } else {
                dispatch(changeCandidateStatus(token, ids, status, listingId, params, isNotified))
                setSelectedPeople([])
            }
        }

        fetchCandidates()
    }

    const handleBulkStatusChange = (transition, isNotified = false) => {

        const ids = selectedPeople.map((person) => person.id)

        if (FEATURE_SETTINGS_PAGE && transition === 'reject') {
            try {
                dispatch(changeCandidateStatus(token, ids, transition, listingId, 'params', isNotified, FEATURE_SETTINGS_PAGE))
                setSelectedPeople([])
                setIsRejectingAll(false)
            } catch (err) {
                console.log(err)
            } finally {
                fetchCandidates()
            }
        } else {
            if (isRejectingAll) {
                try {
                    dispatch(setAllCandidatesStatusById(token, listingId, selectedStatus, transition, isNotified))
                    setIsRejectingAll(false)
                } catch (error) {
                    console.log(error)
                }
            } else {
                try {
                    dispatch(changeCandidateStatus(token, ids, transition, undefined, undefined, isNotified))
                    setSelectedPeople([])
                } catch (err) {
                    console.log(err)
                } finally {
                    fetchCandidates()
                }
            }
        }
    }

    const handleViewingModalSubmitButtonClick = async (inviteToViewingModel) => {
        const homeSeekerApplicationIds = selectedPeople.map((person) => person.id)

        try {
            await service.current.sendInvitation(homeSeekerApplicationIds, listingId, inviteToViewingModel)

            dispatch(setNotification({
                type: 'success',
                message: t('viewing.invitation.success.notification.text'),
                id: Date.now()
            }))
            setSelectedPeople([])
            setClearInvitetoViewingModal(true);
        } catch (err) {
            setClearInvitetoViewingModal(false);
            setViewingInviteError(true)
        } finally {
            fetchCandidates()
        }
    }

    const resetFilter = () => {
        setIncome(null)
        setHouseholdType([])
        setPets(null)
        setIsFiltersDropdownOpen(false)
        fetchCandidates()
    }

    const handlePopover = (candidate) => {
        setQueryParams({status: selectedStatus, selectedCandidateId: candidate.id})
    }

    const handleRequestExtraInfo = () => {
        const ids = selectedPeople.map((person) => person.id)
        dispatch(requestExtraInformation(token, ids, user.email, listingId, FEATURE_SETTINGS_PAGE))
        setSelectedPeople([])
    }

    const sendMessageToSelectedCandidates = (subject, message, candidates) => {
        const homeseekerApplicationIds = candidates.map((person) => person.id)

        if (FEATURE_SETTINGS_PAGE) {
            api.post(`api/agency/mailing/send-manual-email`, {
                homeseekerApplicationIds,
                subject,
                message,
            })
                .then(() => {
                    dispatch(setNotification({
                        id: Date.now(),
                        type: 'success',
                        message: homeseekerApplicationIds.length > 1 ? 'Berichten verzonden.' : 'Bericht verzonden.',
                    }));
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(setNotification({
                        id: Date.now(),
                        message: 'Er is iets fout gegaan',
                        type: 'failed',
                    }))
                });
        } else {
            try {
                dispatch(createMessage(token, homeseekerApplicationIds.length === 0 ? [selectedCandidateId] : homeseekerApplicationIds, subject, message))
            } catch (err) {
                console.log(err)
            }
        }
    }

    const onSendMessageButtonClick = () => {
        setIsMessageModalOpen(true)
    }

    const handleAutoEnriching = (mode) => {
        const enrichingMode = mode === 'instant' ? 'manual' : 'instant'
        try {
            dispatch(toggleAutomaticEnriching(listingId, enrichingMode))
            setAutoEnrichingModalOpen(false)
        } catch (err) {
            console.error(err)
        }
    }

    const changeStatusTab = (status) => {
        queryParams.set('status', status)
        setQueryParams(queryParams)
    }

    const handleScreenButtonClick = async (candidate) => {
        const isScreeningActivated = (await api.get('api/is-screening-service-activated-for-my-agency')).isActivated
        const isExtraExplanationShowing = await screeningModalService.current.getIsExtraExplanationShowing()

        if (isScreeningActivated) {
            setScreeningModalModel(() => ScreeningModalModel.createWithActivateCandidateContent(candidate).withIsExtraExplanationShowing(isExtraExplanationShowing))
        } else {
            setScreeningModalModel(() => ScreeningModalModel.createWithScreeningActivationContent(candidate))
        }
    }

    const handleAssignTenantButtonClick = (candidate) => {
        setAssignTenantModalModel(() => AssignTenantModalModel.createWithAssignTenantContent(candidate))
    }

    const handleProfilePopoverCloseButtonClick = () => {
        queryParams.delete('selectedCandidateId')
        setQueryParams(queryParams)
    }

    const isProfilePopoverOpen = () => {
        return !!selectedCandidateId
    }

    async function sendHomeseekerApplicationNote(note) {
        setIsCreateNoteModalOpen(false)
        await api.post('api/homeseeker/application/note/create', {
            note: note,
            homeseekerApplicationId: selectedCandidateId,
        }).then(() => {
            dispatch(setNotification({
                id: Date.now(),
                message: t('candidate.notes.createNote.success.title'),
                type: 'success'
            }));

        }).catch(() => {
            dispatch(setNotification({
                id: Date.now(),
                message: t('candidate.notes.createNote.error.title'),
                type: 'failed'
            }));
        })
    }

    function isValidStatusSelected() {
        return status !== null && Object.values(CandidateFilterStatus).includes(status);
    }

    function getSelectedCandidate() {
        const model = sidePanelModel.value.candidateModel
        return model === undefined || model === null ? selectedPeople : [model]
    }

    function retryViewingInvitation() {
        setViewingInviteError(false)
        setInviteToViewingModalOpen(true)
    }

    const changeProfilePopoverOnSidePanelChange = (e) => {
        if (!e.detail.open) {
            handleProfilePopoverCloseButtonClick();
        }
    }

    useEffect(() => {
        SidePanel.addListener(changeProfilePopoverOnSidePanelChange);

        return () => {
            SidePanel.removeListener(changeProfilePopoverOnSidePanelChange);
        }
    }, []);

    return (
        <div className='relative h-full w-full'>
            <PageWithSidePanel>
                <PageContainer>
                    <TopNavigation/>
                    <ListingDetailsHeader
                        addressModel={new AddressModel(city, houseNumber, street, postalCode)}
                        listingProperties={isLoading ? null : new ListingPropertiesModel(price, surface, rooms, listingId)}
                        listingStatusTimer={new ListingStatusTimerModel(status, closedAt ? new Date(closedAt) : null)}
                        isAutoEnrichmentEnabled={enrichment === 'instant'}
                        onAutoEnrichmentSwitchChange={() => setAutoEnrichingModalOpen(true)}
                        setIsGenerateContactRequestLinkModalOpen={setIsGenerateContactRequestLinkModalOpen}
                    />
                    <main className={styles.mainTableContainer}>
                        <div className={styles.listingsBucket}>
                            <ListingsBucket
                                status={selectedStatus}
                                selectStatus={changeStatusTab}
                                numberOfLeads={numberOfLeads}
                                numberOfLeadsToAssess={numberOfLeadsToAssess}
                                numberOfSuitableLeads={numberOfSuitableLeads}
                                numberOfUnassessedLeads={numberOfUnassessedLeads}
                                numberOfRejectedLeads={numberOfRejectedLeads}
                                numberOfNotSuitableLeads={numberOfNotSuitableLeads}
                            />
                        </div>

                        <CandidatesTable
                            query={query}
                            onScreenButtonClick={(candidate) => handleScreenButtonClick(candidate)}
                            onAssignTenantButtonClick={(candidate) => handleAssignTenantButtonClick(candidate)}
                            setQuery={setQuery}
                            isLoading={isLoading}
                            data={candidates.items}
                            totalItems={candidates.totalItems}
                            selectedPeople={selectedPeople}
                            setSelectedPeople={setSelectedPeople}
                            selectedCandidateId={selectedCandidateId}
                            handleCandidateStatus={handleCandidateStatus}
                            sortType={sortType}
                            setSorting={setSortType}
                            pets={pets}
                            setPets={setPets}
                            income={income}
                            setIncome={setIncome}
                            householdType={householdType}
                            setHouseholdType={setHouseholdType}
                            selectHousehold={selectHousehold}
                            toggleExtraInfoModal={setExtraInfoModalOpen}
                            handleBulkStatusChange={handleBulkStatusChange}
                            extraInfoModalOpen={extraInfoModalOpen}
                            sendMessageToCandidate={(subject, message, candidates) => sendMessageToSelectedCandidates(subject, message, candidates)}
                            resetFilter={resetFilter}
                            isFiltersDropdownOpen={isFiltersDropdownOpen}
                            toggleFiltersDropdown={setIsFiltersDropdownOpen}
                            onSearch={() => setPaginationBarModel(prev => prev.with({currentPage: 1}))}
                            status={selectedStatus}
                            toggleRejectionModal={confirmBulkRejection}
                            handlePopover={handlePopover}
                            isShrunk={isProfilePopoverOpen()}
                            toggleInviteToViewingModal={setInviteToViewingModalOpen}
                        />
                        <PaginationBar
                            model={paginationBarModel}
                            onPageChange={(m) => setPaginationBarModel(m)}
                            onItemsPerPageChange={(m) => setPaginationBarModel(m)}
                        />
                    </main>
                </PageContainer>
                <CandidateDetailsSidePanel
                    model={sidePanelModel}
                    onStatusButtonClick={handleCandidateStatus}
                    onMessageSendButtonClick={() => onSendMessageButtonClick()}
                    onNoteCreateButtonClick={() => setIsCreateNoteModalOpen(true)}
                />
            </PageWithSidePanel>

            <ExtraInformationModal
                handleRequestExtraInfo={handleRequestExtraInfo}
                setSelectedPeople={setSelectedPeople}
                extraInfoModalOpen={extraInfoModalOpen}
                onCloseButtonClick={setExtraInfoModalOpen}
                selectedPeople={selectedPeople}
            />
            <AutomaticEnrichingModal
                autoEnrichingModalOpen={autoEnrichingModalOpen}
                handleAutoEnriching={handleAutoEnriching}
                toggleAutoEnrichingModal={setAutoEnrichingModalOpen}
                enrichmentMode={enrichment}
            />
            <RejectionConfirmationModal
                selectedPeopleAmount={selectedPeople.length}
                isRejectionModalOpen={isRejectionModalOpen}
                toggleRejectionModal={setIsRejectionModalOpen}
                handleBulkStatusChange={handleBulkStatusChange}
                status={selectedStatus}
                isRejectingAll={isRejectingAll}
            />
            <SendMessageModal
                receiversEmail=""
                sendMessageToCandidate={(subject, message, candidates) => sendMessageToSelectedCandidates(subject, message, candidates)}
                isOpen={isMessageModalOpen}
                selectedPeople={getSelectedCandidate()}
                setIsSendMessageModelOpen={setIsMessageModalOpen}
            />
            <CreateNoteModal
                isOpen={isCreateNoteModalOpen}
                onCloseButtonClick={() => setIsCreateNoteModalOpen(false)}
                onSubmitButtonClick={(note) => sendHomeseekerApplicationNote(note)}
            />
            <ScreeningModal
                model={screeningModalModel}
                listingPrice={price}
                service={screeningModalService.current}
                onCloseButtonClick={() => setScreeningModalModel(prev => prev.withIsOpen(false))}
            />

            <AssignTenantModal
                model={assignTenantModalModel}
                service={assignTenantModalService.current}
                onCloseButtonClick={() => setAssignTenantModalModel(prev => prev.withIsOpen(false))}
            />

            <GenerateContactRequestLinkModal
                isOpen={isGenerateContactRequestLinkModalOpen}
                setIsOpen={setIsGenerateContactRequestLinkModalOpen}
                setIsGenerateContactRequestLinkModalOpen={setIsGenerateContactRequestLinkModalOpen}
            />

            <ViewingInviteErrorModal
                isOpen={viewingInviteError}
                onConfirm={retryViewingInvitation}
                onCancel={() => setViewingInviteError(false)}
                onCloseButtonClick={() => setViewingInviteError(false)}
            />
            <InviteToViewingModal
                listingId={listingId}
                street={street}
                houseNumber={houseNumber}
                address={address}
                city={city}
                isOpen={isInviteToViewingModalOpen}
                selectedPeople={selectedPeople}
                setSelectedPeople={setSelectedPeople}
                onCloseButtonClick={() => setInviteToViewingModalOpen(false)}
                onSubmitButtonClick={(val) => handleViewingModalSubmitButtonClick(val)}
                clear={clearInvitetoViewingModal}
                setClear={setClearInvitetoViewingModal}
            />
        </div>
    )
}

ListingDetails.propTypes = {
    listingId: PropTypes.string
}

export default ListingDetails
