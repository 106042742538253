import {Timeslot} from '../../../api/viewing/findViewingAttendance';
import {AdditionalViewingDetails} from '../additional-viewing-details';
import {Card} from '../card';
import {ColorEnum} from '../../enums';
import {Collapse} from '../../atoms/collapse';
import styles from './ViewingTimeslotPanel.module.css';
import {classNames} from '../../utils';
import ArrowDownSLineIcon from '../../atoms/icons/ArrowDownSLineIcon';
import React, {ReactNode, useState} from 'react';
import {ViewingAttendee} from '../viewing-attendee';
import {Feedback} from '../../atoms/feedback';
import UserLineIcon from '../../atoms/icons/UserLineIcon';
import {t} from 'i18next';
import Divider from '../../atoms/divider';

interface ViewingTimeslotPanelProps {
    maximumNumberOfInviteesPerTimeslot: number;
    timeslot: Timeslot;
}

const ViewingTimeslotPanel = ({
    maximumNumberOfInviteesPerTimeslot,
    timeslot,
}: ViewingTimeslotPanelProps) => {
    const [isCollapsed, setIsCollapsed] = useState(timeslot.timeslotHasPassed);

    const handlePanelCollapseChange = (collapse: boolean) => {
        setIsCollapsed(collapse);
    }

    const renderViewingAttendees = (): ReactNode[] => {
        const panels = [];

        for (let i = 0; i < timeslot.attendees.length; i++) {
            panels.push(
                <ViewingAttendee attendee={timeslot.attendees[i]} />
            );
            if (i < timeslot.attendees.length - 1) {
                panels.push(<Divider />)
            }
        }

        return panels;
    }

    return (
        <Card
            hasPadding={false}
            backgroundColor={ColorEnum.Gray25}
            borderColor={ColorEnum.Gray300}
        >
            <Collapse
                defaultIsCollapsed={timeslot.timeslotHasPassed}
                toggler={
                    <div
                        className={classNames(styles.panelHeader, isCollapsed && styles.collapsed)}
                    >
                        <AdditionalViewingDetails
                            startTime={timeslot.startTime}
                            endTime={timeslot.endTime}
                            actualNumberOfAttendees={timeslot.attendees.length}
                            maximumNumberOfAttendees={maximumNumberOfInviteesPerTimeslot}
                        />
                        <ArrowDownSLineIcon className={classNames(styles.arrowIcon, isCollapsed && styles.collapsed)}/>
                    </div>
                }
                onChange={handlePanelCollapseChange}
            >
                {timeslot.attendees.length > 0 ? (
                    <div className={styles.attendeesContainer}>
                        {renderViewingAttendees().map((a, index) => {
                            return (
                                <div key={index}>{a}</div>
                            )
                        })}
                    </div>
                ) : (
                    <Feedback
                        icon={<UserLineIcon />}
                        text={t('feedback.text.no.homeseeker.applications.found')}
                    />
                )}
            </Collapse>
        </Card>
    );
}

export default ViewingTimeslotPanel;
