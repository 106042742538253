import AssignTenantModalServiceInterface from '../components/modals/AssignTenantModal/service/assignTenantModalServiceInterface'
import api from '../utils/api'
import { setNotification } from '../redux/actions/ui/setNotification'
import store from '../redux/store'
import { t } from 'i18next'

export default class AssignTenantModalService implements AssignTenantModalServiceInterface {
    public async assignCandidateAsTenant(candidateId: string, shouldArchiveListing = false): Promise<void> {
        try {
            await api.post('api/transactional-listing/tenant/assign', {
                homeseekerApplicationId: candidateId,
                shouldArchiveListing: shouldArchiveListing
            })
            store.dispatch(setNotification({
                id: Date.now(),
                message: t('candidate.assignTenant.success'),
                type: 'success'
            }))
        } catch (error) {
            console.error(error)
        }
    }
}
