import {useTranslation} from 'react-i18next';
import Logo from './Logo';
import React from 'react';

export function LogoLeadflowPoweredBy() {
    const {t} = useTranslation();

    return <div className="flex flex-col items-center">
        <p className="block text-xs font-medium text-gray-500 mb-2">{t('enriching.form.poweredBy')}</p>
        <Logo/>
    </div>;
}
