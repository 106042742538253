import React, { CSSProperties } from 'react';
import { Card } from '../card';
import styles from './ListingCard.module.css';
import { ListingDetails } from '../listing-details';

interface ListingCardProps {
    readonly imageUrl: string;
    readonly street: string;
    readonly houseNumber: string;
    readonly postalCode: string;
    readonly city: string;
    readonly price: number;
    readonly surface: number;
    readonly rooms: number;
}

const ListingCard = ({
    imageUrl,
    street,
    houseNumber,
    postalCode,
    city,
    price,
    surface,
    rooms,
}: ListingCardProps) => {

    const listingImageStyleAttr: CSSProperties = { };

    if (imageUrl !== '') {
        listingImageStyleAttr.backgroundImage = `url('${imageUrl}')`;
    }

    return (
        <Card
            elevated
            hasPadding={false}
        >
            <div className={styles.ListingDetails}>
                <div
                    className={styles.image}
                    style={listingImageStyleAttr}
                ></div>
                <div style={{ padding: '16px 16px 24px 16px' }}>
                    <ListingDetails
                        street={street}
                        houseNumber={houseNumber}
                        postalCode={postalCode}
                        city={city}
                        price={price}
                        surface={surface}
                        rooms={rooms}
                    />
                </div>
            </div>
        </Card>
    );
};

export default ListingCard;
