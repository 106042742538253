import {useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useFeature} from '@growthbook/growthbook-react';
import useAuth from './hooks/useAuth';
import {updateMomentLocale} from './components/formattedDateContent/dateUtils';
import styles from './App.module.css';
import BasicNotification from './components/notifications/BasicNotification';
import routes, {catchAllRedirectPath} from './routes';
import LeadflowWindow from './LeadflowWindow';

const App = () => {
    const {isAuthenticated} = useAuth()
    const navigate = useNavigate()
    const {notifications} = useSelector((state: { ui: { notifications: Array<{ id: number, message: string, type: string }> } }) => state.ui)
    const REDIRECT_ON_LOGIN = useFeature('redirect_on_login').value

    function renderRoutes() {
        let availableRoutes;
        if (isAuthenticated) {
            availableRoutes = [...routes.authenticatedRoutes, ...routes.unauthenticatedRoutes];
        } else {
            availableRoutes = [...routes.guestRoutes, ...routes.unauthenticatedRoutes];
        }

        const navigationElements = availableRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={<route.component />} />
        ));

        const path = catchAllRedirectPath(isAuthenticated);
        navigationElements.push(<Route key={'catch-all'} path="*" element={<Navigate to={`${path}`}/>}/>);

        return navigationElements;
    }

    useEffect(() => {
        updateMomentLocale()
    }, [])

    useEffect(() => {
        if (isAuthenticated && LeadflowWindow.ps) {
            LeadflowWindow.ps.addMeta({isAuthenticated: true})
        }
    }, []);

    useEffect(() => {
        if (REDIRECT_ON_LOGIN) {
            const redirectToAfterLogin = sessionStorage.getItem('redirectToAfterLogin') || '/dashboard/advertenties/'
            if (isAuthenticated && redirectToAfterLogin) {
                navigate(redirectToAfterLogin, {replace: true})
            }
        }
    }, [isAuthenticated])

    return (
        <div className={styles.App}>
            <Routes>
                {renderRoutes()}
            </Routes>
            {notifications?.length > 0 && (
                <div className="fixed inset-0 flex flex-col px-4 py-6 pointer-events-none z-50 justify-end items-end">
                    <>
                        {notifications.map((notif, index) => (
                            <BasicNotification
                                key={index}
                                message={notif.message}
                                id={notif.id}
                                type={notif.type}
                            />
                        ))}
                    </>
                </div>
            )}
        </div>
    )
}

export default App
