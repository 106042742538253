import DomEventManager from '../../utils/DomEventManager';

export interface SidePanelEventData {
    open: boolean;
}

export type SidePanelCustomEventType = CustomEvent<SidePanelEventData>;

class SidePanelEventManager<SidePanelEventData> extends DomEventManager<SidePanelEventData> {
    protected readonly isOpen: boolean;

    private constructor(event_name: string) {
        super(event_name);

        this.isOpen = false;
    }

    public static create = <SidePanelEventData>(event_name = 'side-panel'): SidePanelEventManager<SidePanelEventData> => {
        return new SidePanelEventManager(event_name);
    }

    public setOpen = (open: boolean): void => {
        this.dispatch({ open } as SidePanelEventData);
    }

    public open = (): void => {
        this.setOpen(true);
    }

    public close = (): void => {
        this.setOpen(false);
    }

    public toggle = (): void => {
        this.setOpen(!this.isOpen);
    }
}

export default SidePanelEventManager;
