import React, {useEffect, useState} from 'react';
import styles from './TopHeader.module.css';
import {Logo} from '../../atoms/logo';
import { LanguageSelect } from '../language-select';
import {ProfileDropdownMenu} from '../profile-dropdown-menu';
import useAuth from '../../../hooks/useAuth';

interface Props {
    readonly hasTranslationSelector?: boolean;
}

const TopHeader = ({ hasTranslationSelector = false }: Props) => {
    const {user} = useAuth();
    const [isNavigationDisabled, setIsNavigationDisabled] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        setIsNavigationDisabled(mediaQuery.matches);

        const handleMediaChange = (e: MediaQueryListEvent) => {
            setIsNavigationDisabled(e.matches);
        };

        mediaQuery.addEventListener('change', handleMediaChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaChange);
        };
    }, []);

    return (
        <header className={styles.container}>
            <Logo isNavigationDisabled={isNavigationDisabled} />
            <div className={styles.containerRight}>
                {hasTranslationSelector &&
                    <LanguageSelect />
                }
                {user &&
                    <ProfileDropdownMenu isNavigationDisabled={isNavigationDisabled} />
                }
            </div>
        </header>
    );
};

export default TopHeader;
