import api from '../../../utils/api';

export const toggleAutomaticEnriching = (transactionalListingId, enrichingMode) => async (dispatch) => {
    dispatch({type: 'START_LOADING'});

    const url = enrichingMode === 'instant' ?
        'api/transactional-listing/enrichment/instant' :
        'api/transactional-listing/enrichment/manual';

    api.post(url, {
        transactionalListingId: transactionalListingId
    })
        .then((response) => {
            if (response.status === 202) {
                dispatch({type: 'SET_AUTO_ENRICHING_MODE', payload: enrichingMode});
            }
        })
        .catch((e) => {
            dispatch({type: 'STOP_LOADING'});
            console.error(e);
        })
        .finally(() => dispatch({type: 'STOP_LOADING'}));
};
