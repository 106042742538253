import {stringToEnum} from '../utils/enumType'
import CandidateScreeningRequestOutcomeEnum from '../enums/candidateScreeningRequestOutcomeEnum'
import CandidateScreeningRequestStatusEnum from '../enums/candidateScreeningRequestStatusEnum'
import ScreeningRequestModel from '../models/screeningRequest/screeningRequestModel'
import CoTenantJsonToModelConverter, {CoTenantJSON} from './coTenantModelJsonToModelConverter'
import { ScreeningRequest } from '../api/screening-request/findScreeningRequest'

export interface ScreeningRequestJSON {
    readonly id: string,
    readonly rating: number | null,
    readonly status: string,
    readonly outcome: string | null,
    readonly coTenants: ReadonlyArray<CoTenantJSON>
}

export default class ScreeningRequestJsonToModelConverter {
    public static convert(obj: ScreeningRequestJSON | ScreeningRequest): ScreeningRequestModel {
        return new ScreeningRequestModel({
            id: obj.id,
            rating: obj.rating,
            status: stringToEnum(obj.status, CandidateScreeningRequestStatusEnum),
            outcome: obj.outcome !== null ? stringToEnum(obj.outcome, CandidateScreeningRequestOutcomeEnum) : null,
            coTenants: obj.coTenants.map((c: CoTenantJSON) => CoTenantJsonToModelConverter.convert(c))
        })
    }
}
