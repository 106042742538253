import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'

export default function SSOLoginPage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/login');
    }, [])

    return 'Redirecting...'
}
