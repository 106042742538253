import React from 'react';
import styles from './PromptBeforeNavigation.module.css';
import Text from '../../atoms/typography/Text';
import {FontSizeEnum, FontWeightEnum} from '../../enums';
import ButtonNew from '../button/Button';

interface PromptBeforeNavigationProps {
    onConfirm: () => void;
    onCancel: () => void;
}

const PromptBeforeNavigation = ({onConfirm, onCancel}: PromptBeforeNavigationProps) => {
    return (
        <div className={styles.backdrop}>
            <div className={styles.container}>
                <Text size={FontSizeEnum.Sm} weight={FontWeightEnum.Bold}>
                    app.leadflow.rent
                </Text>
                <Text size={FontSizeEnum.Sm}>
                    Je hebt wijzigingen die nog niet zijn opgeslagen.
                </Text>
                <Text size={FontSizeEnum.Sm}>
                    Weet je zeker dat je de pagina wilt verlaten zonder op te slaan?
                </Text>
                <div className={styles.buttonContainer}>
                    <ButtonNew variant='secondary' onClick={onCancel}>Annuleren</ButtonNew>
                    <ButtonNew onClick={onConfirm}>Doorgaan zonder op te slaan</ButtonNew>
                </div>
            </div>
        </div>
    );
};

export default PromptBeforeNavigation;
