import Modal from '../../modal/Modal';
import ScreeningModalModel from './logic/models/screeningModalModel';
import { useEffect, useState } from 'react';
import ScreeningModalServiceInterface from './service/screeningModalServiceInterface';
import ScreeningModalActionType from './logic/models/screeningModalActionType';
import SpinnerOverlay from '../../spinnerOverlay/SpinnerOverlay';
import ScreeningActivationFragment from './fragments/screeningActivation/ScreeningActivationFragment';
import ScreeningActivationSuccessFragment from './fragments/screeningActivationSuccess/ScreeningActivationSuccessFragment';
import ScreeningCandidateFragment from './fragments/screeningCandidate/ScreeningCandidateFragment';
import ScreeningModalFragmentEnum from './logic/models/screeningModalFragmentEnum';
import ScreeningErrorFragment from './fragments/ScreeningError/ScreeningErrorFragment';
import { useFeature } from '@growthbook/growthbook-react';

export interface ScreeningModalProps {
    readonly model: ScreeningModalModel
    readonly listingPrice: string
    readonly service: ScreeningModalServiceInterface
    readonly onCloseButtonClick: () => void
}

export default function ScreeningModal(props: ScreeningModalProps) {
    const [model, setModel] = useState(props.model ? props.model : ScreeningModalModel.createWithScreeningActivationContent(props.model.candidate));
    const LEADFLOW_SCREENING = useFeature('leadflow-screening').value;

    useEffect(() => {
        setModel(props.model)
    }, [props.model])

    async function handleModalButtonClick(actionType: ScreeningModalActionType) {
        if (actionType === ScreeningModalActionType.Cancel) {
            setModel(prev => prev.withIsOpen(false))
        }
        if (actionType === ScreeningModalActionType.Activate) {
            setModel(prev => prev.withIsLoading(true).disableActivateButton())
            await props.service.activateScreening()
            setModel(() => ScreeningModalModel.createWithActivateScreeningSuccessContent(model.candidate))
        }
        if (actionType === ScreeningModalActionType.Continue) {
            setModel(prev => prev.withIsLoading(true))
            setModel(() => ScreeningModalModel.createWithActivateCandidateContent(model.candidate))
        }
        if (actionType === ScreeningModalActionType.ScreenCandidate) {
            setModel(prev => prev.withIsLoading(true))

            if (!model.isExtraExplanationShowing) {
                await props.service.rememberNotToShowExplanationAnymore()
            }
            try {
                if (LEADFLOW_SCREENING) {
                    await props.service.sendVerificationRequest(model.candidate.listing, model.candidate.id, model.areCoTenantsAllowed)
                    setModel(prev => prev.withIsOpen(false))
                } else {
                    await props.service.sendCandidateScreeningRequestInvite(model.candidate.id, model.areCoTenantsAllowed)
                    setModel(prev => prev.withIsOpen(false))
                }
            } catch(e) {
                setModel(() => ScreeningModalModel.createWithScreeningError(model.candidate))
            }
        }
    }

    function handleApprovePolicyCheckboxChange(checked: boolean) {
        setModel(prev => checked ? prev.enableActivateButton() : prev.disableActivateButton())
    }

    function handleRentalPriceEdit() {
        setModel(prev => prev.disableScreenButton())
    }

    function handleRentalPriceSave() {
        setModel(prev => prev.enableScreenButton())
    }

    function handleAreCoTenantsAllowedChange(val: boolean) {
        setModel(prev => prev.withAreCoTenantsAllowed(val))
    }

    return (
        <Modal
            model={model}
            onButtonClick={(actionType: ScreeningModalActionType) => handleModalButtonClick(actionType)}
            onCloseButtonClick={() => props.onCloseButtonClick()}
        >
            {model.currentFragment === ScreeningModalFragmentEnum.ScreeningActivation &&
                <ScreeningActivationFragment
                    onApprovePolicyCheckboxChange={handleApprovePolicyCheckboxChange}
                />
            }
            {model.currentFragment === ScreeningModalFragmentEnum.ScreeningActivationSuccess &&
                <ScreeningActivationSuccessFragment/>
            }

            {model.currentFragment === ScreeningModalFragmentEnum.ScreeningCandidate &&
                <ScreeningCandidateFragment
                    service={props.service}
                    candidateName={model.candidate.fullName}
                    isExtraExplanationShowing={model.isExtraExplanationShowing}
                    listingPrice={props.listingPrice}
                    onRentalPriceEdit={() => handleRentalPriceEdit()}
                    onRentalPriceSave={() => handleRentalPriceSave()}
                    onAreCoTenantsAllowedChange={(val) => handleAreCoTenantsAllowedChange(val)}
                    onRememberToNotShowExtraExplanationCheckboxChange={val => setModel(prev => prev.withIsExtraExplanationShowing(!val))}
                />
            }

            {model.currentFragment === ScreeningModalFragmentEnum.ScreeningError &&
                <ScreeningErrorFragment/>
            }

            {model.isLoading ? <SpinnerOverlay/> : null}
        </Modal>
    )
}
