import api from '../../../utils/api';
import {LISTING_STATES} from '../../../utils/constants';
import {getAllListings} from './getAllListings';

export const setListingStatus = (ids, status) => async (dispatch) => {
    dispatch({type: 'START_LOADING'});

    api.post(`api/transactional-listing/status/set`,
        {
            transactionalListingIds: ids,
            status: status
        }
    ).then(() => {
        dispatch(
            getAllListings({
                itemsPerPage: 25,
                page: 1,
                status: status === LISTING_STATES.AVAILABLE ? LISTING_STATES.ARCHIVED : LISTING_STATES.AVAILABLE,
                pagination: true,
            })
        );
    })
        .catch((e) => {
            dispatch({type: 'STOP_LOADING'});
            console.error(e);
        })
        .finally(() => dispatch({type: 'STOP_LOADING'}));
};
