import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LayoutLandingPage from '../../components/generics/LayoutLandingPage';
import styles from './ContactRequestPage.module.css';
import Logo from '../../components/logo/Logo';
import LanguageSwitchButtons from '../../components/locales/LanguageSwitchButtons';
import { useTranslation } from 'react-i18next';
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum';
import ColorEnum from '../../enums/colorEnum';
import Title from '../../components/typography/title/Title';
import FontWeightEnum from '../../components/typography/enums/fontWeightEnum';
import Text from '../../components/typography/text/Text';
import ContactRequestFormData from './ContactRequestFormData';
import ContactRequestForm from './ContactRequestForm';
import {HOUSEHOLD_TYPE} from '../../utils/constants';
import ListingCard from '../../components/listings/ListingCard';
import api from '../../utils/api';
import {useParams} from 'react-router-dom';
import FormSuccess from '../../components/empty-states/FormSuccess';
import {setNotification} from '../../redux/actions/ui/setNotification';
import {useDispatch} from 'react-redux';

interface ContactRequestListingResponse {
    listing: {
        street: string,
        showHouseNumber: boolean,
        houseNumber: string,
        postalCode: string,
        city: string,
        imageUrl: string,
        price: number,
        surface: number,
        rooms: number,
    },
    agent: {
        name: string,
        logo: string,
        privacyPolicy: string,
    }
}

export default function ContactRequestPage() {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const listingId = useParams().id;

    const [contactRequestFormData, setContactRequestFormData] = useState(ContactRequestFormData.create());
    const [householdClass, setHouseholdClass] = useState('');
    const [agent, setAgent] = useState({
        name: '',
        logo: '',
        privacyPolicy: ''
    });
    const [listing, setListing] = useState({});
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const [isFromSubmitSuccess, setIsFormSubmitSuccess] = useState(false);

    useEffect(() => {
        api.getWithoutAuth(`api/contact-request/listing/${listingId}`)
            .then((res: ContactRequestListingResponse) => {
                if (res) {

                    const { listing, agent } = res;

                    setListing({
                        street: listing.street,
                        show_house_number: listing.showHouseNumber,
                        house_number: listing.houseNumber,
                        postal_code: listing.postalCode,
                        city: listing.city,
                        image_url: listing.imageUrl,
                        price: listing.price / 100,
                        surface: listing.surface,
                        rooms: listing.rooms,
                    });

                    setAgent({
                        name: agent.name,
                        logo: agent.logo,
                        privacyPolicy: agent.privacyPolicy,
                    });
                }
            })
    }, []);

    useEffect(() => {
        if (contactRequestFormData.value.birthDate) {
            let age = null

            if (contactRequestFormData.value.birthDate && moment(contactRequestFormData.value.birthDate, 'YYYY-MM-DD', true).isValid()) {
                age = moment().diff(contactRequestFormData.value.birthDate, 'years')
            }

            setContactRequestFormData(prev => prev.with({age}))
        }
    }, [contactRequestFormData.value.birthDate]);

    useEffect(() => {
        if (contactRequestFormData.value.householdType === HOUSEHOLD_TYPE.SINGLE) {
            setContactRequestFormData(prev => prev.with({household: 1}))
            setHouseholdClass('');
        } else if (contactRequestFormData.value.householdType === HOUSEHOLD_TYPE.WITH_PARTNER) {
            setContactRequestFormData(prev => prev.with({household: 2}))
            setHouseholdClass('');
        } else if (contactRequestFormData.value.householdType !== null) {
            setContactRequestFormData(prev => prev.with({household: 1}))
            setHouseholdClass(styles.showFormField);
        } else {
            setHouseholdClass('')
        }
    }, [contactRequestFormData.value.householdType]);

    const handleSubmit = async () => {
        const updatedFormData = contactRequestFormData.with({});

        if (!updatedFormData.validate()) {
            setContactRequestFormData(updatedFormData);
            return;
        }
        setIsSubmitButtonDisabled(true);
        try {
            await api.postWithoutAuth('api/create-enriched-homeseeker-application', {
                transactionalListingId: listingId,
                salutation: updatedFormData.value.salutation,
                fullName: updatedFormData.value.fullName,
                locale: i18n.language,
                birthDate: updatedFormData.value.birthDate,
                age: updatedFormData.value.age,
                email: updatedFormData.value.email,
                phoneNumber: updatedFormData.value.phoneNumber,
                employment: updatedFormData.value.employment,
                income: updatedFormData.value.income,
                guarantor: updatedFormData.value.guarantor,
                householdType: updatedFormData.value.householdType,
                household: updatedFormData.value.household,
                hasPets: updatedFormData.value.hasPets,
                rentalStartDate: updatedFormData.value.rentalStartDate,
                rentalPeriod: updatedFormData.value.rentalPeriod,
                motivation: updatedFormData.value.motivation,
                dataSharingAccepted: updatedFormData.value.dataSharingAccepted,
                privacyPolicyAccepted: updatedFormData.value.privacyPolicyAccepted
            })
        }
        catch (error) {
            setIsSubmitButtonDisabled(false);
            console.error('Error submitting contact request:', error)
            dispatch(setNotification(
                {
                    id: Date.now(),
                    message: t('contactRequestPage.form.validation.toast.failed'),
                    type: 'failed'
                }
            ));
            return;
        }
        setIsFormSubmitSuccess(true);
        setIsSubmitButtonDisabled(false);

    };

    return (
        <>
            {isFromSubmitSuccess ?
                <FormSuccess/>
                :
                <LayoutLandingPage>
                    <div className={styles.header}>
                        <Logo />
                        <LanguageSwitchButtons />
                    </div>
                    <hr className={styles.divider} />
                    <div className={styles.gridContainer}>
                        <div className={styles.sidebar}>
                            <div className={styles.content}>
                                <Title
                                    size={FontSizeEnum.Md}
                                    weight={FontWeightEnum.Semibold}
                                    color={ColorEnum.Primary700}
                                >
                                    {t('contactRequestPage.title')}
                                </Title>
                                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                                    {t('contactRequestPage.description')}
                                </Text>
                                <ListingCard agent={agent} listing={listing} className='col-span-6'/>
                            </div>
                        </div>
                        <div className={styles.mainContent}>
                            <ContactRequestForm
                                isSubmitButtonDisabled={isSubmitButtonDisabled}
                                householdClass={householdClass}
                                contactRequestFormData={contactRequestFormData}
                                setContactRequestFormData={setContactRequestFormData}
                                handleSubmit={handleSubmit}
                                agent={agent}
                            />
                        </div>
                    </div>
                </LayoutLandingPage>
            }
        </>
    );

}
