import DomEventManager from '../../utils/DomEventManager';

export interface ModalEventData {
    open: boolean;
}

export type ModalCustomEventType = CustomEvent<ModalEventData>;

class ModalEventManager<ModalEventData> extends DomEventManager<ModalEventData> {
    protected readonly isOpen: boolean;

    private constructor(event_name: string) {
        super(event_name);

        this.isOpen = false;
    }

    public static create = <ModalEventData>(event_name: string): ModalEventManager<ModalEventData> => {
        return new ModalEventManager(event_name);
    }

    public setOpen = (open: boolean): void => {
        this.dispatch({ open } as ModalEventData);
    }

    public open = (): void => {
        this.setOpen(true);
    }

    public close = (): void => {
        this.setOpen(false);
    }

    public toggle = (): void => {
        this.setOpen(!this.isOpen);
    }
}

export default ModalEventManager;
