import api, { request, wait } from '../../../utils/api';
import { WAIT_TIME } from '../../../utils/constants';

/**
 * @param token Authorization token
 * @param id Candidate id
 *
 * @deprecated use {@see getCandidateListing()} instead
 */
export const getEnrichingCandidateDetails = (token, id) => dispatch => {
    dispatch({ type: 'START_LOADING' });
    api.getWithoutAuth(`api/enrichment-information/${id}`)
        .then(response => {
            dispatch({
                type: 'SET_ENRICHING_CANDIDATE_TRANSACTIONAL_LISTING',
                payload: response
            });
        })
        .then(() => wait(WAIT_TIME))
        .catch((e) => {
            dispatch({ type: 'STOP_LOADING' });
            console.log(e);
        })
        .finally(() => dispatch({ type: 'STOP_LOADING' }));
};

export const getCandidateDetails = (token, id) => dispatch => {
    request(token).get(`/api/candidates/${id}`)
        .then(response => {
            dispatch({
                type: 'SET_CANDIDATE_DETAILS',
                payload: response.data
            });
        })
        .then(() => wait(WAIT_TIME))
        .catch((e) => {
            console.log(e);
        })
        .finally(() => dispatch({ type: 'STOP_LOADING' }));

};
