import api from '../../../utils/api';

export interface EmailSignature {
    readonly value: string;
}

export interface EmailTemplate {
    title: string;
    dutchEmailContent: string;
    englishEmailContent: string;
    variables: string[];
}

export interface MailingSettings {
    readonly agencyId: string;
    readonly emailSignature: EmailSignature;
    readonly rejectionEmail: EmailTemplate;
    readonly enrichmentEmail: EmailTemplate;
}

export interface MailingSettingsResponse {
    readonly mailingSettings: MailingSettings
}

export interface MailingSettingsFormData {
    agencyId: string,
    rejectionEmailTitle: string,
    dutchRejectionEmailContent: string,
    englishRejectionEmailContent: string,
    enrichmentEmailTitle: string,
    dutchEnrichmentEmailContent: string,
    englishEnrichmentEmailContent: string,
    emailSignature: string,
    readonly rejectionEmailVariables: string[];
    readonly enrichmentEmailVariables: string[];
}

export const defaultMailingSettingsFormData = {
    agencyId: '',
    rejectionEmailTitle: '',
    dutchRejectionEmailContent: '',
    englishRejectionEmailContent: '',
    enrichmentEmailTitle: '',
    dutchEnrichmentEmailContent: '',
    englishEnrichmentEmailContent: '',
    emailSignature: '',
    rejectionEmailVariables: [],
    enrichmentEmailVariables: [],
};

const mapResponseToFormData = (response: MailingSettings) => {
    return {
        agencyId: response.agencyId,
        rejectionEmailTitle: response.rejectionEmail.title,
        dutchRejectionEmailContent: response.rejectionEmail.dutchEmailContent,
        englishRejectionEmailContent: response.rejectionEmail.englishEmailContent,
        enrichmentEmailTitle: response.enrichmentEmail.title,
        dutchEnrichmentEmailContent: response.enrichmentEmail.dutchEmailContent,
        englishEnrichmentEmailContent: response.enrichmentEmail.englishEmailContent,
        emailSignature: response.emailSignature.value,
        rejectionEmailVariables: response.rejectionEmail.variables,
        enrichmentEmailVariables: response.enrichmentEmail.variables,
    };
}

const fetchMailingSettings = async (selectedAgencyId: string): Promise<MailingSettingsFormData> => {
    const response: MailingSettingsResponse = await api.get(`api/agency/${selectedAgencyId}/settings/mailing`);

    return mapResponseToFormData(response.mailingSettings);
};

export default fetchMailingSettings;
