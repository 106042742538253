import AddressModel from '../model/addressModel';
import ListingPropertiesModel from '../../../../components/listings/listingProperties/logics/model/listingPropertiesModel';

export interface Listing {
    id: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    price: string;
    surface: number;
    rooms: number;
}

export default class TransactionalListingToAddressConverter {
    public static convert(listing: Listing): AddressModel {
        return new AddressModel({
            listingId: listing.id,
            value: `${listing.street} ${listing.houseNumber}, ${listing.postalCode} ${listing.city}`,
            listingProperties: new ListingPropertiesModel(parseFloat(listing.price), listing.surface, listing.rooms)
        });
    }
}
