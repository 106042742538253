import PropTypes from 'prop-types';
import React from 'react';
import { Fragment, useState } from 'react';
import { Listbox, Menu, Transition } from '@headlessui/react';
import {
    CalendarIcon,
    CheckIcon,
    ChevronDownIcon,
    HomeIcon
} from '@heroicons/react/solid';

import styles from './OverviewHeader.module.css';

import {
    CurrencyEuroIcon as CurrencyEuroOutline,
} from '@heroicons/react/outline';
import moment from 'moment';
import 'moment/locale/nl';
import { renderGender } from '../../utils/renders';
import CandidateViewingStatus from '../../enums/candidateViewingStatus';

const publishingOptions = [
    {
        name: 'Open',
        description: 'This job posting can be viewed by anyone who has the link.',
        current: true,
    },
    {
        name: 'Archief',
        description: 'This job posting will no longer be publicly accessible.',
        current: false,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function OverviewHeader(props) {
    const { data, type,
        handleCandidateStatus } = props;
    const [selected, setSelected] = useState(false);

    /* Listings Overview */
    if (type === 'listings') {
        return (
            <header>
                <div className={styles.headerContainer}>
                    <h1 className={styles.headerTitle}>
                        Advertenties
                    </h1>
                </div>
            </header>
        );
    }

    /* Home */
    if (type === 'home') {
        return (
            <header className="bg-gray-50 py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                    <div className="flex-1 min-w-0">
                        <h1 className="mt-4 text-3xl font-bold leading-7 text-gray-800  sm:text-2xl font-heading">
                            Burgemeester v. Walsumweg 200
                        </h1>
                        <h4 className="mt-5 text-md font-medium leading-9 text-gray-400 sm:text-md">
                            3011 GB Rotterdam{' '}
                        </h4>
                        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                            <div className="mt-2 flex items-center text-base text-gray-500">
                                <CurrencyEuroOutline
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                € 1200,-
                            </div>
                            <div className="mt-2 flex items-center text-base text-gray-500">
                                <HomeIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                70 m2 - 3 kamers
                            </div>

                            <div className="mt-2 flex items-center text-base text-gray-500">
                                <CalendarIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                Beschikbaar per 22 april, 2022
                            </div>
                            <span className="inline-flex mt-2 items-center px-2 py-0.5
              rounded-lg text-sm font-medium bg-green-100 text-green-800">
                                <svg
                                    className="ml-1 mr-1.5 h-2 w-2 text-green-400 "
                                    fill="currentColor"
                                    viewBox="0 0 8 8"
                                >
                                    <circle cx={4} cy={4} r={3} />
                                </svg>
                                Beschikbaar
                            </span>
                        </div>
                    </div>
                    <div className="mt-5 flex xl:mt-0 xl:ml-4">
                        <span className="sm:ml-3 relative z-0">
                            <Listbox value={selected} onChange={setSelected}>
                                {({ open }) => (
                                    <>
                                        <Listbox.Label className="sr-only">
                                            Change published status
                                        </Listbox.Label>
                                        <div className="relative">
                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="origin-top-right absolute left-0
                        mt-2 -mr-1 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y
                        divide-gray-200 ring-1 ring-black ring-opacity-5
                        focus:outline-none sm:left-auto sm:right-0">
                                                    {publishingOptions.map(option => (
                                                        <Listbox.Option
                                                            key={option.name}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'text-white bg-purple-500'
                                                                        : 'text-gray-900',
                                                                    'cursor-default select-none relative p-4 text-base'
                                                                )
                                                            }
                                                            value={option}
                                                        >
                                                            {({ selected, active }) => (
                                                                <div className="flex flex-col">
                                                                    <div className="flex justify-between">
                                                                        <p
                                                                            className={
                                                                                selected
                                                                                    ? 'font-semibold'
                                                                                    : 'font-normal'
                                                                            }
                                                                        >
                                                                            {option.name}
                                                                        </p>
                                                                        {selected ? (
                                                                            <span
                                                                                className={
                                                                                    active
                                                                                        ? 'text-white'
                                                                                        : 'text-purple-500'
                                                                                }
                                                                            >
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                    <p
                                                                        className={classNames(
                                                                            active
                                                                                ? 'text-purple-200'
                                                                                : 'text-gray-500',
                                                                            'mt-2'
                                                                        )}
                                                                    >
                                                                        {option.description}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                        </span>

                        {/* Dropdown */}
                        <Menu as="span" className="ml-3 relative sm:hidden">
                            <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300
              rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                More
                                <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                />
                            </Menu.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48
                rounded-md shadow-lg py-1 bg-white ring-1 ring-black
                ring-opacity-5 focus:outline-none">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-base text-gray-700'
                                                )}
                                            >
                                                Edit
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-base text-gray-700'
                                                )}
                                            >
                                                View
                                            </a>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </header>
        );
    }

    /* Candidate Profile */
    if (type == 'candidate_profile' && data) {
        return <div className=" max-w-3xl mx-auto px-4 sm:px-6 md:flex
            md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
                <div>
                    <h1 className="text-4xl font-bold text-gray-900">
                        {renderGender(data.gender)}{' '}{data.firstName}{' '}{data.lastName}
                    </h1>
                    <p className="text-base font-medium text-gray-500">
                        Gereageerd{' '}
                        <a
                            href={`/dashboard/advertenties/${data.listing.id}`}
                            className="text-gray-700 font-bold">
                            {data.listing.street}{' '}{data.listing.house_number}
                        </a>
                        {' '}
                        op {moment(data.messages[0].createdAt).format('D MMMM YYYY')}
                        {' '}
                        om
                        {' '}
                        {moment(data.messages[0].createdAt).format('hh:mm')}
                    </p>
                </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch
      space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end
      sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <button
                    onClick={() => handleCandidateStatus((data.status === CandidateViewingStatus.Suitable ? 'reset' : 'promote'))}
                    type="button"
                    className={classNames('inline-flex items-center justify-center',
                        'px-4 py-2 text-base font-medium rounded-md',
                        'focus:outline-none',
                        'focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100',
                        data.status === CandidateViewingStatus.Suitable ? 'text-primary bg-secondary shadow-sm hover:bg-emerald-200'
                            : 'text-primary bg-gray-100 hover:bg-secondary-light'
                    )}
                >
                    <i className="ri-thumb-up-line ri-lg mr-2"></i> Goedkeuren
                </button>
                <button
                    onClick={() => handleCandidateStatus((data.status === CandidateViewingStatus.NotSuitable ? 'reset' : 'devote'))}
                    type="button"
                    className={classNames('inline-flex items-center justify-center px-4 py-2',
                        'text-base font-medium rounded-md',
                        ' focus:outline-none focus:ring-2',
                        'focus:ring-offset-2 focus:ring-offset-gray-100',
                        data.status === CandidateViewingStatus.NotSuitable ? 'shadow-sm text-primary bg-yellow-200 hover:bg-yellow-300 border'
                            : 'text-primary bg-gray-100 hover:bg-yellow-100'
                    )}
                >
                    <i className="ri-pause-circle-line ri-lg mr-2"></i> In Wachtrij
                </button>
                <button
                    onClick={() => handleCandidateStatus((data.status === CandidateViewingStatus.Rejected ? 'reset' : 'reject'))}
                    type="button"
                    className={classNames('inline-flex items-center justify-center px-4 py-2',
                        'text-base font-medium rounded-md ',
                        ' focus:outline-none focus:ring-2 focus:ring-offset-2',
                        'focus:ring-offset-gray-100',
                        data.status === CandidateViewingStatus.Rejected ? 'shadow-sm text-primary bg-red-300 hover:bg-red-400'
                            : 'text-primary bg-gray-100 hover:bg-red-200'
                    )}
                >
                    <i className="ri-thumb-down-line ri-lg mr-2"></i> Afwijzen
                </button>
            </div>
        </div >;
    }
}

OverviewHeader.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object,
    handleCandidateStatus: PropTypes.func,
    toggleAutoEnrichingModal: PropTypes.func
};

export default OverviewHeader;
