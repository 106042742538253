import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CandidateProfilePage from './CandidateProfilePage';
import { getCandidateDetails } from '../../../redux/actions/candidates/getCandidateDetails';
import { requestExtraInformation } from '../../../redux/actions/candidates/getExtraInformation';
import { createMessage } from '../../../redux/actions/messages/createMessages';
import { changeCandidateStatus } from '../../../redux/actions/candidates/setCandidateStatus';
import { setHomeseekerApplicationNote } from '../../../redux/actions/candidates/setHomeseekerApplicationNote';
import useAuth from '../../../hooks/useAuth'
import {useFeature} from '@growthbook/growthbook-react';

export default function CandidateProfileContainer() {
    const { id, pid } = useParams();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.users);
    const { isLoading } = useSelector((state) => state.ui);
    const { selectedCandidate } = useSelector((state) => state.candidates.data);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [isMessageModalOpen, toggleMessageModal] = useState(false);
    const [isNoteModalOpen, toggleNoteModal] = useState(false);
    const [isExtraInfoModalOpen, toggleExtraInfoModal] = useState(false);
    const { user } = useAuth();
    const FEATURE_SETTINGS_PAGE = useFeature('settings-page').value

    const sendMessageToCandidate = () => {
        dispatch(createMessage(token, [pid], subject, body));
        toggleMessageModal(false);
    };

    const handleCandidateStatus = (status, isNotified = false) => {
        dispatch(changeCandidateStatus(token, [pid], status, id, undefined, isNotified));
    };

    const handleCreateNote = () => {
        dispatch(setHomeseekerApplicationNote(pid, body));
        toggleNoteModal(false);
    };

    const handleRequestExtraInfo = () => {
        dispatch(requestExtraInformation(token, [pid], user.email, id, FEATURE_SETTINGS_PAGE));
    };

    useEffect(() => {
        if (token && id) { dispatch(getCandidateDetails(token, pid)); }
    }, [dispatch, token, id, pid]);

    return (
        <CandidateProfilePage
            isLoading={isLoading}
            data={selectedCandidate}
            sendMessageToCandidate={sendMessageToCandidate}
            handleCandidateStatus={handleCandidateStatus}
            subject={subject}
            body={body}
            setBody={setBody}
            setSubject={setSubject}
            isMessageModalOpen={isMessageModalOpen}
            toggleMessageModal={toggleMessageModal}
            isNoteModalOpen={isNoteModalOpen}
            toggleNoteModal={toggleNoteModal}
            handleCreateNote={handleCreateNote}
            toggleExtraInfoModal={toggleExtraInfoModal}
            isExtraInfoModalOpen={isExtraInfoModalOpen}
            handleRequestExtraInfo={handleRequestExtraInfo}
        />
    );
}
