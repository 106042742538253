import React from 'react';
import styles from './HorizontalTabs.module.css';
import {classNames} from '../../utils';
import { Tab, TabData } from '../../atoms/tab';

interface Props {
    fullWidth?: boolean;
    data: {
        activeLabel: string;
        tabs: TabData[];
    };
    onChange: (label: string) => void;
}

const HorizontalTabs = ({fullWidth = false, data, onChange}: Props) => {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.tabsContainer, fullWidth && styles.fullWidth)}>
                {data.tabs.map((tab, index) => {
                    return (
                        <Tab
                            key={index}
                            icon={tab.icon ? tab.icon : null}
                            label={tab.label}
                            required={tab.required ? tab.required : null}
                            active={data.activeLabel === tab.label}
                            onChange={onChange}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default HorizontalTabs;
