import styles from './Modal.module.css';
import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum';
import FlowyFigure from '../../generics/modalBackgroundIcon/FlowyFigure';
import {useEffect, useState} from 'react';
import CloseButton from '../../atoms/closeButton/CloseButton';
import ModalEventManager, { ModalCustomEventType, ModalEventData } from '../../../componentsNew/organisms/modal/ModalEventManager';

export interface ModalProps {
    icon?: string
    iconColor?: ColorEnum
    iconBackgroundColor?: ColorEnum
    flowyColor: ColorEnum
    children: React.ReactNode
    isOpen: boolean
    isNonScrollable?: boolean
    onCloseButtonClick?: () => void
    canOverflow?: boolean
    manager?: ModalEventManager<ModalEventData>;
}

const Modal = ({
    icon,
    iconColor,
    iconBackgroundColor,
    flowyColor,
    children,
    isOpen,
    isNonScrollable,
    onCloseButtonClick,
    canOverflow,
    manager,
}: ModalProps) => {
    const [isVisible, setIsVisible] = useState(isOpen);
    const [isClosing, setIsClosing] = useState(false);
    const [isOpenState, setIsOpenState] = useState(isOpen);

    const setSidePanel = (e: ModalCustomEventType) => {
        setIsOpenState(e.detail.open);
    }

    useEffect(() => {
        manager?.addListener(setSidePanel);

        return () => {
            manager?.removeListener(setSidePanel);
        };
    }, []);

    useEffect(() => {
        if (isOpen || isOpenState) {
            setIsVisible(true);
            setIsClosing(false);
        } else if (isVisible) {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                setIsVisible(false);
            }, 200);
        }
    }, [isOpen, isOpenState]);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    function handleClose() {
        if (!isClosing) {
            manager?.close();
            onCloseButtonClick?.();
        }
    }

    if (!isVisible) {
        return null;
    }

    return (
        <div
            className={classNames(
                styles.backdrop,
                isClosing ? styles.backdropHidden : styles.backdropVisible,
                isNonScrollable === true ? styles.nonScrollable : ''
            )}
        >
            <div className="fixed inset-0 w-full z-50">
                <div className={`${styles.mainContainer} ${canOverflow ? styles.canOverflow : ''}`}>
                    <div
                        className={classNames(
                            styles.innerContainer,
                            isClosing ? styles.hidden : styles.visible
                        )}
                    >
                        <div className={styles.closeButton}>
                            <CloseButton onClick={handleClose}/>
                        </div>
                        <div className={styles.flowyFigureContainer}>
                            <FlowyFigure color={flowyColor}/>
                        </div>
                        <div className={styles.contentContainer}>
                            {icon ? (
                                <div className={styles.iconContainer}>
                                    <i
                                        className={`${styles.icon} ${icon} text-xl`}
                                        style={{
                                            color: colorEnumAsCssVar(iconColor),
                                            backgroundColor: colorEnumAsCssVar(iconBackgroundColor),
                                        }}
                                    ></i>
                                </div>
                            ) : null}
                            <div
                                className={classNames(
                                    styles.childrenContainer,
                                    isNonScrollable === true ? styles.nonScrollable : ''
                                )}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
