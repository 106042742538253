import api, { BodyData } from '../../../utils/api';

export interface saveMailingSettingsPayload extends BodyData {
    readonly agencyId: string;
    readonly rejectionEmailTitle: string;
    readonly dutchRejectionEmailContent: string;
    readonly englishRejectionEmailContent: string;
    readonly enrichmentEmailTitle: string;
    readonly dutchEnrichmentEmailContent: string;
    readonly englishEnrichmentEmailContent: string;
    readonly emailSignature: string;
}

const saveMailingSettings = async (data: saveMailingSettingsPayload): Promise<void> => {
    await api.post('api/agency/settings/mailing/save', data);
};

export default saveMailingSettings;
