import React, { ReactNode } from 'react';
import styles from './Badge.module.css';

interface BadgeProps {
    label: string | number;
    before?: ReactNode;
    after?: ReactNode;
}

const Badge = ({
    label,
    before,
    after
}: BadgeProps) => {
    return (
        <span className={styles.Badge}>
            {before}
            <span className={styles.label}>
                {label}
            </span>
            {after}
        </span>
    );
}


export default Badge;
