import React, { ReactNode } from 'react';
import styles from './Main.module.css';
import { classNames } from '../../utils';

interface DropdownProps {
    readonly children: ReactNode;
    readonly className?: string;
}

const Dropdown = ({
    children,
    className,
}: DropdownProps) => {
    return (
        <main className={classNames(styles.Main, className ?? className)}>
            {children}
        </main>
    );
};

export default Dropdown;
