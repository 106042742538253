import moment from 'moment'
import {stringToEnum} from '../../../../utils/enumType'
import DiscardedLeadReasonEnum from '../model/discardedLeadReasonEnum'
import DiscardedLeadModel from '../model/discardedLeadModel'

export interface DiscardedLeadJson {
    readonly id: string
    readonly homeseekerName: string
    readonly homeseekerEmail: string
    readonly reason: DiscardedLeadReasonEnum
    readonly discardedAt: string
    readonly messageHtmlBody: string
    readonly message: string
}

export default class DiscardedLeadJsonToModelConverter {
    public static convert(json: DiscardedLeadJson): DiscardedLeadModel {
        return new DiscardedLeadModel({
            id: json.id,
            homeseekerName: json.homeseekerName,
            homeseekerEmail: json.homeseekerEmail,
            reason: stringToEnum(json.reason, DiscardedLeadReasonEnum),
            discardedAt: moment(json.discardedAt),
            template: json.messageHtmlBody,
            message: json.message
        })
    }
}
