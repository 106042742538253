import { ReactNode, useState, useEffect } from 'react';
import styles from './SidePanel.module.css';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import CloseIcon from '../../atoms/icons/CloseIcon';
import ButtonNew from '../../molecules/button/Button';
import { classNames } from '../../utils';
import ArrowLeftSLineIcon from '../../atoms/icons/ArrowLeftSLineIcon';
import { useTranslation } from 'react-i18next';
import SidePanelEventManager, { SidePanelCustomEventType, SidePanelEventData } from './SidePanelEventManager';

const sidePanel = SidePanelEventManager.create<SidePanelEventData>();

type SidePanelProps = {
    open?: boolean;
    title: string;
    children: ReactNode;
};

type SidePanelComponent = (props: SidePanelProps) => JSX.Element;

const SidePanel: SidePanelComponent = ({
    open = false,
    title,
    children,
}: SidePanelProps) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(open);

    const setSidePanel = (e: SidePanelCustomEventType) => {
        setIsOpen(e.detail.open);
    }

    useEffect(() => {
        sidePanel.addListener(setSidePanel);

        return () => {
            sidePanel.removeListener(setSidePanel);
        };
    }, []);

    return (
        <>
            <div className={classNames(styles.sidePanel, isOpen && styles.open)}>
                <div className={styles.sidePanelPadding}>
                    <div className={styles.backButtonContainer}>
                        <ButtonNew
                            variant="tetriary-gray"
                            className={styles.backButton}
                            onClick={sidePanel.close}
                            iconBefore={<ArrowLeftSLineIcon />}
                        >
                            {t('sidepanel.back.button')}
                        </ButtonNew>
                    </div>
                    <div className={`${styles.titleContainer} flex items-center justify-between`}>
                        <div>
                            <Text
                                size={FontSizeEnum.DisplayXs}
                                color={ColorEnum.Gray700}
                                weight={FontWeightEnum.Bold}
                            >
                                {title}
                            </Text>
                        </div>
                        <ButtonNew
                            variant="tetriary-gray"
                            className={styles.closeButton}
                            onClick={sidePanel.close}
                            iconBefore={<CloseIcon />}
                        ></ButtonNew>
                    </div>
                    <div className={styles.wrapper}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

Object.assign(SidePanel, sidePanel);

export default (SidePanel as SidePanelComponent & SidePanelEventManager<SidePanelEventData>);
