import React, { useEffect, useState } from 'react';
import { Select } from '../select';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSelect.module.css';
import DutchFlagIcon from '../../atoms/icons/flags/DutchFlagIcon';
import GreatBritainFlagIcon from '../../atoms/icons/flags/GreatBritain';
import { Item } from '../select/Select';
import currentLanguage from '../../utils/currentLanguage';

const LanguageSelect = () => {
    const { i18n } = useTranslation();

    const lang = currentLanguage();
    const [selected, ] = useState(lang);

    const listItems = [
        {
            value: 'nl',
            label: (
                <div className={styles.label}>
                    <DutchFlagIcon/>
                    <span>Nederlands</span>
                </div>
            )
        },
        {
            value: 'en',
            label: (
                <div className={styles.label}>
                    <GreatBritainFlagIcon/>
                    <span>English</span>
                </div>
            )
        }
    ];

    const [selectedItem, setSelectedItem] = useState(selected);

    useEffect(() => {
        i18n.changeLanguage(selectedItem)
    }, [selectedItem])

    const handleLanguageChange = (item: Item) => {
        setSelectedItem(item.value)
    }

    return (
        <>
            <Select
                selected={selectedItem}
                items={listItems}
                onChange={handleLanguageChange}
            />
        </>
    );
};

export default LanguageSelect;
