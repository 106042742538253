import style from './TimeInput.module.css';
import React, { useEffect, useState } from 'react';
import TimeInputModel from './model/timeInputModel';
import { Select } from '../../../componentsNew/organisms/select';
import TimeLineIcon from '../../../componentsNew/atoms/icons/TimeLineIcon';
import dropdownStyles from '../../../componentsNew/organisms/dropdown/Dropdown.module.css';
import { Item } from '../../../componentsNew/organisms/select/Select';

interface TimeInputProps {
    readonly value: TimeInputModel;
    readonly disabledUntil?: string;
    readonly disabledFrom?: string;
    readonly onChange?: (value: TimeInputModel) => void;
    readonly onBlur?: (value: TimeInputModel) => void;
    readonly onEnterKeyPress?: (value: TimeInputModel) => void;
}

export default function TimeInput(props: TimeInputProps) {
    const [model, setModel] = useState(props.value);

    useEffect(() => {
        setModel(props.value);
    }, [props.value])

    const timeOptions = Array.from({ length: 24 * 12 }, (_, index) => {
        const hours = String(Math.floor(index / 12)).padStart(2, '0');
        const minutes = String((index % 12) * 5).padStart(2, '0');
        const time = `${hours}:${minutes}`;

        const scrollTo = (props.disabledFrom && time == props.disabledFrom) || (props.disabledUntil && time == props.disabledUntil);

        return {
            label: time,
            value: time,
            disabled: (props.disabledFrom && time >= props.disabledFrom) || (props.disabledUntil && time <= props.disabledUntil),
            scrollTo: scrollTo ? scrollTo : undefined,
        };
    });

    function handleDropdownChange(value: string | null): void {
        setModel((prev) => {
            if (!prev) {
                return prev;
            }

            const newModel = prev.with({ time: value });
            props.onChange?.(newModel);
            return newModel;
        });
    }

    return (
        <div className={style.timeInput}>
            <Select
                className={dropdownStyles.TimeInput}
                selected={model.value.time}
                items={timeOptions}
                onChange={(e: Item | null) => handleDropdownChange(e ? e.value : null)}
                showArrowIcon={false}
                iconBefore={<TimeLineIcon />}
                hasInputField
                multiple={false}
                placeholder='--:--'
            />
        </div>
    )
}
