import React from 'react';
import styles from './Tab.module.css';
import {classNames} from '../../utils';
import {Props} from './TabTypes';

const Tab = ({icon = null, label, required = false, active, onChange}: Props) => {
    return (
        <div className={classNames(styles.container, active && styles.active)} onClick={() => onChange(label)}>
            {icon}
            <p>
                {label}
                {required && <span className={styles.required}>*</span>}
            </p>
        </div>
    );
};

export default Tab;
