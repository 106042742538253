import { ReactNode, useEffect, useState } from 'react';
import { ViewingDetails } from '../../viewing-details';
import Select from '../Select';
import { Viewing as ListingViewing } from '../../../../api/viewing/findListingViewingsOverview';
import { Viewing } from '../../../../api/viewing/findViewingAttendance';
import moment from 'moment';
import currentLanguage from '../../../utils/currentLanguage';
import { AdditionalViewingDetails } from '../../additional-viewing-details';
import styles from './ViewingSelect.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import 'moment/locale/nl';

interface ViewingSelectProps {
    items: Array<string | ListingViewing>;
    viewing: Viewing;
}

const ViewingSelect = ({
    items,
    viewing
}: ViewingSelectProps) => {
    const locale = currentLanguage();
    const navigate = useNavigate();

    const { listingId, viewingId, candidateId } = useParams();

    const [ selectedViewingId, setSelectedViewingId ] = useState<string>(viewingId);

    useEffect(() => {
        if (candidateId === undefined) {
            navigate(`/listing/${listingId}/viewings/${selectedViewingId}/overview`);
        } else {
            navigate(`/listing/${listingId}/viewings/${selectedViewingId}/overview/${candidateId}`);
        }
    }, [selectedViewingId])

    const renderSelectedViewingDetails = (): ReactNode => {
        return (
            <ViewingDetails
                viewing={viewing}
            />
        )
    }

    const itemLabel = (item: string | ListingViewing): ReactNode => {
        if (typeof item === 'string' || item instanceof String) {
            const date = moment(item as string).locale(locale).format('dddd, DD MMMM YYYY');
            return (
                <div className={styles.dateItem}>{date}</div>
            );
        }

        return (
            <AdditionalViewingDetails
                startTime={item.startTime}
                endTime={item.endTime}
                actualNumberOfAttendees={item.actualNumberOfAttendees}
                maximumNumberOfAttendees={item.maximumNumberOfAttendees}
            />
        )
    }
    const itemValue = (item: string | ListingViewing): string => {
        if (typeof item === 'string' || item instanceof String) {
            return (item as string);
        }

        return item.viewingId;
    }

    const isItemViewing = (item: string | ListingViewing) => {
        if (typeof item === 'string' || item instanceof String) {
            return false;
        }
    }

    return (
        <Select
            fullWidth
            selected={selectedViewingId}
            items={items.map((i) => {
                return {
                    label: itemLabel(i),
                    value: itemValue(i),
                    selectable: isItemViewing(i)
                }
            })}
            onChange={(i) => setSelectedViewingId(i.value)}
            renderCustomSelected={renderSelectedViewingDetails}
        />
    )
};

export default ViewingSelect;
