import api from '../../utils/api';

interface Viewing {
    startDateTime: string;
    endDateTime: string;
    status: string;
    timeslotStartTime: string;
    timeslotEndTime: string;
}

interface Message {
    id: string;
    details: string;
    createdAt: string;
}

interface Note {
    id: string;
    note: string;
    created_at: string;
}

export interface HomeseekerApplication {
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    phone?: string;
    income?: number;
    availableSince?: string;
    term?: number;
    household?: string;
    createdAt?: string;
    updatedAt?: string;
    pets?: boolean;
    householdType?: string;
    status?: string;
    birthday?: string;
    guarantor?: string;
    employment?: string;
    gender?: string;
    extraInfoFormLastSentOn?: string;
    transactionalListingId?: string;
    hasAcceptedAgentPrivacyPolicy?: boolean;
    homeseekerId?: string;
    isListingTenant?: boolean;
    screeningRequestId?: string;
    screeningRequestStatus?: string;
    screeningRequestOutcome?: string;
    screeningRequestRating?: number;
    hasCoTenants?: boolean;
    hasCoTenantsWithInvitedScreeningRequests?: boolean;
    platformUserAccountId?: string;
    viewings?: Viewing[];
    upcomingOrMostRecentViewing?: Viewing;
    messages?: Message[];
    notes?: Note[];
}

interface FindHomeseekerApplicationByIdResponse {
    result: HomeseekerApplication;
}

const findHomeSeekerApplicationById = async (selectedCandidateId: string): Promise<FindHomeseekerApplicationByIdResponse> => {
    return await api.get(`api/get-homeseeker-application/${selectedCandidateId}`);
};

export default findHomeSeekerApplicationById;
