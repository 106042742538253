import api from '../../utils/api';

export interface Attendee {
    readonly homeseekerApplicationId: string;
    readonly fullName: string;
    readonly salutation?: string;
    readonly phoneNumber?: string;
}

export interface Timeslot {
    readonly startTime: string;
    readonly endTime: string;
    readonly timeslotHasPassed: boolean;
    readonly attendees: Attendee[] | [];
}

export interface Viewing {
    readonly startDateTime: string;
    readonly endDateTime: string;
    readonly maximumInviteesPerTimeslot: number;
    readonly actualNumberOfAttendees: number;
    readonly maximumNumberOfAttendees: number;
    readonly timeslots: Timeslot[];
}

const findViewingAttendance = async (viewingId: string): Promise<Viewing> => {
    return await api.get(`api/viewing/${viewingId}/attendance`);
};

export default findViewingAttendance;
