import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import Text from '../../typography/text/Text';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import ColorEnum from '../../../enums/colorEnum';
import styles from './Checkbox.module.css';
import {TransProps} from 'react-i18next';
import CheckIcon from '../../generics/checkIcon/CheckIcon'
import FontWeightEnum from '../../typography/enums/fontWeightEnum';

interface CheckBoxProps {
    readonly id?: string
    readonly name?: string
    readonly label?: string | ReactElement<TransProps<string>>
    readonly isChecked?: boolean
    readonly isDisabled?: boolean
    readonly fontSize?: FontSizeEnum
    readonly fontWeight?: FontWeightEnum
    readonly value?: string | number
    readonly onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    readonly onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
}

export default function Checkbox(props: CheckBoxProps) {

    const [checked, setChecked] = useState(props.isChecked)

    useEffect(() => {
        setChecked(props.isChecked)
    }, [props.isChecked])

    function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
        setChecked(e.target.checked)

        if (props.onChange) {
            props.onChange(e)
        }
    }

    function renderLabel() {
        if (props.label === undefined || props.label === '') {
            return null
        }

        return (
            <>
                <Text
                    size={props.fontSize ? props.fontSize : FontSizeEnum.Sm}
                    color={props.isDisabled ? ColorEnum.Gray400 : ColorEnum.Gray900}
                    weight={(props.fontWeight) ? props.fontWeight : FontWeightEnum.Regular}
                >
                    {props.label}
                </Text>
            </>
        )
    }

    return (
        <label className={styles.root}>
            <div className={styles.checkboxContainer}>
                {checked && <CheckIcon checkColor={ColorEnum.Gray800} />}
                <input
                    id={props.id ? props.id : ''}
                    name={props.name ? props.name : ''}
                    type="checkbox"
                    disabled={props.isDisabled}
                    checked={checked}
                    value={props.value}
                    onChange={handleOnChange}
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
            {renderLabel()}
        </label>
    )
}
