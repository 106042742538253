import React, { ReactNode } from 'react';
import styles from './IconWithText.module.css';
import Text, { TextElements } from './Text';
import { FontSizeEnum } from '../../enums';
import { fontSizeEnumAsCssVar } from '../../enums/FontSizeEnum';
import { classNames } from '../../utils';

interface IconWithTextProps {
    readonly icon: ReactNode;
    readonly fontSize?: FontSizeEnum;
    readonly children: TextElements | TextElements[];
    readonly iconDisplay?: 'inline';
}

const IconWithText = ({
    icon,
    fontSize = FontSizeEnum.Md,
    children,
    iconDisplay,
}: IconWithTextProps) => {
    return (
        <div
            className={classNames(iconDisplay && styles[iconDisplay], styles.IconWithText)}
            style={{ fontSize: fontSizeEnumAsCssVar(fontSize) }}
        >
            <div className={styles.iconWrapper}>
                {icon}
            </div>
            <div className={styles.textWrapper}>
                <Text size={fontSize}>{children}</Text>
            </div>
        </div>
    );
}

export default IconWithText;
