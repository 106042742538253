import api, { BodyData } from '../../utils/api';

export interface AcceptViewingPayload extends BodyData {
    viewingId: string;
    homeseekerApplicationId: string;
    startTime: string;
    endTime: string;
}

const acceptViewing = async (data: AcceptViewingPayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/homeseeker/viewing/accept`, data);
};

export default acceptViewing;
