class DomEventManager<Data> {
    protected readonly event_name: string;

    protected constructor(event_name: string) {
        this.event_name = event_name;
    }

    public static create = <Data>(event_name: string): DomEventManager<Data> => {
        return new DomEventManager(event_name);
    }

    public addListener = (callback: (e: CustomEvent<Data>) => void): void => {
        document.addEventListener(this.event_name, callback as EventListener);
    }

    public removeListener = (callback: (e: CustomEvent<Data>) => void): void => {
        document.removeEventListener(this.event_name, callback as EventListener);
    }

    public dispatch = (data: Data): void => {
        document.dispatchEvent(new CustomEvent<Data>(this.event_name, { detail: data }));
    }
}

export default DomEventManager;
