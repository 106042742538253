import {createContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux'
import {useSearchParams} from 'react-router-dom';

interface TestAuth0ProviderProps {
    readonly children: React.ReactNode
}

export const TestAuth0Context = createContext(null)

/**
 * Save authentication in the session in testing mode, to enable acceptance tests to refresh the page.
 */
const previouslyLoggedIn = localStorage.getItem('test-authenticated');
const storeSession = (loggedIn, role) => {
    if (loggedIn) {
        return localStorage.setItem('test-authenticated', role);
    }

    localStorage.removeItem('test-authenticated');
}

export default function TestAuth0Provider(props: TestAuth0ProviderProps) {
    const dispatch = useDispatch();
    const [params,] = useSearchParams('role')
    const role = params.get('role') || 'agent';
    const [value, setValue] = useState({
        isAuthenticated: !!previouslyLoggedIn,
        isLoading: false,
        error: null,
        user: {
            email: 'johnny.test@e2e.com',
            picture: 'https://cdn.auth0.com/avatars/t.png',
            imageUrl: 'https://cdn.auth0.com/avatars/t.png'
        },

        loginWithPopup: () => {
            dispatch({
                type: 'SET_TOKEN',
                payload: role === 'admin' ? 'test_admin_token' : 'test_agent_token',
            });
            setValue(prev => ({...prev, isAuthenticated: true}))
            setValue(prev => ({...prev, email: role === 'admin' ? 'test-admin@leadflow.rent' : 'test-agent@leadflow.rent'}))
            storeSession(true, role);
        },

        logout: () => {
            dispatch({
                type: 'SET_TOKEN',
                payload: null,
            });
            setValue(prev => ({...prev, isAuthenticated: false}))
            storeSession(false, role);
            window.location.href = '/login';
        }
    })

    useEffect(() => {
        if (!previouslyLoggedIn) {
            return;
        }

        setValue(prev => ({...prev, isAuthenticated: true}))
        setValue(prev => ({...prev, email: previouslyLoggedIn === 'admin' ? 'test-admin@leadflow.rent' : 'test-agent@leadflow.rent'}))
        dispatch({
            type: 'SET_TOKEN',
            payload: previouslyLoggedIn === 'admin' ? 'test_admin_token' : 'test_agent_token',
        });
    }, []);

    return (
        <TestAuth0Context.Provider value={value}>
            {props.children}
        </TestAuth0Context.Provider>
    )
}
