import AddressModel from '../model/addressModel'
import api from '../../../../utils/api'
import TransactionalListingToAddressConverter, {Listing} from './transactionalListingToAddressConverter'

export default class MergeDuplicateTransactionalListingsService {

    public async getAddress(listingId: string): Promise<AddressModel> {
        const result: Listing = await api.get(`api/transactional_listings/${listingId}`);

        return TransactionalListingToAddressConverter.convert(result);
    }

    public async mergeListings(primaryListingId: string, redundantListingId: string): Promise<void> {
        await api.post('api/merge-duplicate-transactional-listing', {
            redundantListingId: redundantListingId,
            primaryListingId: primaryListingId
        })
    }
}
