import api from '../../utils/api';

export interface HomeseekerApplicationForSelector {
    readonly id: string;
    readonly fullName: string;
}

const findHomeseekerApplicationsForSelector = async (transactionalListingId: string, fullName: string): Promise<HomeseekerApplicationForSelector[]> => {
    return await api.get('api/get-homeseeker-applications-for-selector', { transactionalListingId, fullName });
};

export default findHomeseekerApplicationsForSelector;
