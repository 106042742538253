import {t} from 'i18next';

export default class ListingPropertiesModel {

    private static CURRENCY_FORMAT = new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: 0,
    })

    constructor(
        public readonly price: number,
        public readonly surface?: number,
        public readonly rooms?: number,
        public readonly listingId?: string,
    ) { }

    public getFormattedPrice(): string {
        return ListingPropertiesModel.CURRENCY_FORMAT.format(this.price);
    }

    public getFormattedSurface(): string {
        return this.surface ? `${this.surface} m²` : '-'
    }

    public getFormattedRooms(): string {
        if (this.rooms) {
            const label = this.rooms > 1 ? 'listings.room.plural' : 'listings.room.singular'
            return `${this.rooms} ${t(label)}`
        }
        return '-'
    }

    public isPriceVisible(): boolean {
        return this.price > 0
    }

    public isSurfaceVisible(): boolean {
        return this.surface !== undefined
    }

    public isRoomsVisible(): boolean {
        return this.rooms !== undefined
    }
}
