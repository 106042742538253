import kbdStyles from '../../kbd/KBD.module.css';

const replaceVariables = (html: string, variables: string[]) => {
    return html.replace(/{{(.*?)}}/g, (match, content, offset, string) => {
        const trimmedContent = content.trim();

        if (variables.includes(trimmedContent)) {
            const before = string.slice(0, offset);
            const after = string.slice(offset + match.length);

            const spanStartRegex = /<span[^>]*>$/;
            const spanEndRegex = /^<\/span>/;

            if (spanStartRegex.test(before) && spanEndRegex.test(after)) {
                return match;
            }

            return `<span class="${kbdStyles.kbd}" data-value="${trimmedContent}" contentEditable="false">{{${trimmedContent}}}</span>`
        }

        return match;
    });
}

export {replaceVariables};
