import Title from '../../../typography/title/Title';
import ColorEnum from '../../../../enums/colorEnum';
import FontWeightEnum from '../../../typography/enums/fontWeightEnum';
import FontSizeEnum from '../../../typography/enums/fontSizeEnum';
import Text from '../../../typography/text/Text';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../../buttons/button/Button';
import ButtonType from '../../../buttons/button/logic/models/buttonType';
import { useNavigate } from 'react-router-dom';
import styles from './ErrorFragment.module.css';
import { SidePanel } from '../../../../componentsNew/organisms/side-panel';

export default function ErrorFragment() {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div>
            <div className={styles.errorHeader}>
                <div className={styles.errorIcon}>
                    <i className="ri-emotion-sad-line"></i>
                </div>
            </div>
            <div className={styles.mainContainer}>
                <Title color={ColorEnum.Gray700} weight={FontWeightEnum.Bold} size={FontSizeEnum.Xsm}>
                    {t('candidate.popover.error.title')}
                </Title>

                <Text color={ColorEnum.Gray500} size={FontSizeEnum.Md}>
                    <Trans
                        i18nKey="candidate.popover.error.possibleProblems"
                        components={{ Ul: <ul className={styles.listItem}/>, Li: <li/> }}
                    />
                </Text>

                <div>
                    <Text color={ColorEnum.Gray800} weight={FontWeightEnum.Bold} size={FontSizeEnum.Lg}>
                        {t('candidate.popover.error.whatCanYouDo.title')}
                    </Text>

                    <Text color={ColorEnum.Gray500} size={FontSizeEnum.Md}>
                        <Trans
                            i18nKey="candidate.popover.error.whatCanYouDo.text"
                            components={{ Ul: <ul className={styles.listItem}/>, Li: <li/> }}
                        />
                    </Text>
                </div>

                <Button
                    title={t('candidate.popover.error.toListingOverview')}
                    onClick={() => navigate('/dashboard/advertenties')}
                />
                <Button
                    title={t('candidate.popover.error.toDashboard')}
                    buttonType={ButtonType.Primary}
                    onClick={SidePanel.close}
                />

                <Text color={ColorEnum.Gray500} size={FontSizeEnum.Md}>
                    <Trans
                        i18nKey="candidate.popover.error.callSupport"
                        components={{ Link: <a/> }}
                    />
                </Text>
            </div>
        </div>
    )
}
