import api from '../../../../utils/api';
import CandidateModel from '../../../../models/candidate/candidateModel';

export default class CandidateScreeningDocumentsDownloadLinkModel {
    public constructor(
        private readonly hasDoneScreeningRequest: boolean,
        private readonly isListingTenant: boolean
    ) {}

    static createFromCandidate(candidate: CandidateModel): CandidateScreeningDocumentsDownloadLinkModel {
        return new CandidateScreeningDocumentsDownloadLinkModel(
            candidate.hasDoneScreening(),
            candidate.isListingTenant
        )
    }

    public showDownloadDocumentsText(): string {
        return 'candidate.status.screening.completed.documents.download.link.text'
    }

    public static async downloadDocuments(candidate: CandidateModel) {
        if (candidate.hasDoneScreening()) {
            const documentsUrl = await api.get(`api/get-screening-dossier-documents/${candidate.screeningRequestId}`).then((response: {result: string}) => {
                return response.result
            })

            if (documentsUrl != null) {
                this.downloadDocumentsFromUrl(documentsUrl)
            }
        }
    }

    private static downloadDocumentsFromUrl(documentsUrl: string) {
        const alink = document.createElement('a');
        alink.href = documentsUrl;
        alink.click();
    }
}

