import styles from './ContactRequestPage.module.css';
import Text from '../../components/typography/text/Text';
import FontWeightEnum from '../../components/typography/enums/fontWeightEnum';
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum';
import ColorEnum from '../../enums/colorEnum';
import {GUARANTOR, HOUSEHOLD_TYPE, SALUTATION} from '../../utils/constants';
import TextInput from '../../components/forms/textInput/TextInput';
import DateInput from '../../components/atoms/dateInput/DateInput';
import moment from 'moment/moment';
import React from 'react';
import ContactRequestFormData from './ContactRequestFormData';
import {Trans, useTranslation} from 'react-i18next';
import Checkbox from '../../components/forms/checkbox/Checkbox';
import Button from '../../components/buttons/button/Button';
import ButtonType from '../../components/buttons/button/logic/models/buttonType';
import ButtonSize from '../../components/buttons/button/logic/models/buttonSize';
import {LogoLeadflowPoweredBy} from '../../components/logo/LogoLeadflowPoweredBy';
import GuarantorType from '../../components/candidateData/candidateProfileInfo/logic/models/guarantorType';
import HouseholdType from '../../components/candidateData/candidateProfileInfo/logic/models/householdType';
import FormError from '../../components/banners/FormError';
import { TextArea } from '../../componentsNew/atoms/text-area';

interface ContactRequestFormProps {
    readonly contactRequestFormData: ContactRequestFormData
    readonly setContactRequestFormData:  React.Dispatch<React.SetStateAction<ContactRequestFormData>>,
    readonly handleSubmit: () => void
    readonly householdClass: string
    readonly agent: undefined | { name: string, privacyPolicy: string }
    readonly isSubmitButtonDisabled: boolean
}

export default function ContactRequestForm(props: ContactRequestFormProps) {

    const { t } = useTranslation();

    function getHouseholdClass(): string {
        if (props.householdClass === '') {
            return styles.householdDefault + ' ' + props.householdClass;
        }
        return `${styles.householdDefault} ${props.householdClass} ${styles.formRow} ${styles.fullWidth}`;
    }

    function getIncomeOptions() {
        const options = [];
        for (let i = 0; i <= 19; i++) {
            const rangeStart = i * 500;
            const range = `€ ${rangeStart + (i === 0 ? 0 : 1)} - ${rangeStart + 500}`
            options.push(
                <option key={i + 1} value={rangeStart + 500}>
                    {range} {t('contactRequestPage.form.group.additional.income.perMonth')}
                </option>
            );
        }
        options.push(
            <option key={options.length + 1} value={options.length * 500 + 1}>
                € 10.000+ {t('contactRequestPage.form.group.additional.income.perMonth')}
            </option>
        );
        return options;
    }

    function getHouseholdOptions() {
        const options = [];
        for (let i = 1; i <= 10; i++) {
            options.push(
                <option key={i} value={i.toString()}>
                    {i}
                </option>
            );
        }
        return options;
    }

    return (
        <>
            <form data-testid={'contactRequestForm'} onSubmit={props.handleSubmit}>
                <div className={styles.formContainer}>
                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}>{t('contactRequestPage.form.group.personal.title')}</Text>
                    <div className={`${styles.formRow} ${styles.oneThirdTwoThirds}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.salutation.label')}</Text>
                            <select
                                id="gender"
                                name="gender"
                                value={props.contactRequestFormData.value.salutation}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({salutation: e.target.value}))}
                                className="mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                            >
                                <option
                                    value={SALUTATION.UNSET}>{t('contactRequestPage.form.group.personal.salutation.gender.UNSET')}</option>
                                <option
                                    value={SALUTATION.MALE}>{t('contactRequestPage.form.group.personal.salutation.gender.MALE')}</option>
                                <option
                                    value={SALUTATION.FEMALE}>{t('contactRequestPage.form.group.personal.salutation.gender.FEMALE')}</option>
                            </select>
                            <FormError message={t(props.contactRequestFormData.value.errors?.salutation)}/>
                        </div>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.fullName.label')}*</Text>
                            <TextInput
                                value={props.contactRequestFormData.value.fullName}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({fullName: (e.target as HTMLInputElement).value}))}
                                name={'fullName'}
                                placeholder={t('contactRequestPage.form.group.personal.fullName.placeholder')}
                            />
                            <FormError message={t(props.contactRequestFormData.value.errors?.fullName)}/>
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.twoThirdsOneThird}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.birthday.label')}</Text>
                            <DateInput
                                value={moment(props.contactRequestFormData.value.birthDate)}
                                onChange={(date) => props.setContactRequestFormData(prev => prev.updateBirthDate(date?.format('YYYY-MM-DD')))}
                                id={'birthDate'}
                            />
                        </div>
                        <div>
                            <Text size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray800}>{t('contactRequestPage.form.group.personal.age.label')}</Text>
                            <TextInput
                                value={props.contactRequestFormData.value.age?.toString()}
                                name={'age'}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.email.label')}*</Text>
                            <TextInput
                                value={props.contactRequestFormData.value.email}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({email: (e.target as HTMLInputElement).value}))}
                                name={'email'}
                                placeholder={t('contactRequestPage.form.group.personal.email.placeholder')}
                            />
                            <FormError message={t(props.contactRequestFormData.value.errors?.email)}/>
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.phone.label')}</Text>
                            <TextInput
                                value={props.contactRequestFormData.value.phoneNumber}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({phoneNumber: (e.target as HTMLInputElement).value}))}
                                name={'phoneNumber'}
                            />
                            <FormError message={t(props.contactRequestFormData.value.errors?.phoneNumber)}/>
                        </div>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}>{t('contactRequestPage.form.group.additional.title')}</Text>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.employment.label')}</Text>
                            <select
                                required
                                value={props.contactRequestFormData.value.employment ?? ''}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({employment: e.target.value}))}
                                id="employment"
                                name="employment"
                                className="mt-1 py-3 block w-full pl-3 pr-10 text-base border-gray-300
                                                        focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md
                                                        required:invalid:text-gray-500"
                            >
                                <option value="" disabled
                                    hidden>{t('contactRequestPage.form.group.additional.employment.NONE')}</option>
                                <option
                                    value="EMPLOYER">{t('contactRequestPage.form.group.additional.employment.EMPLOYED_BY_EMPLOYER')}</option>
                                <option
                                    value="ENTREPRENEUR">{t('contactRequestPage.form.group.additional.employment.ENTREPRENEUR')}</option>
                                <option
                                    value="STUDENT">{t('contactRequestPage.form.group.additional.employment.STUDENT')}</option>
                                <option
                                    value="RETIRED">{t('contactRequestPage.form.group.additional.employment.RETIRED')}</option>
                                <option
                                    value="NONE">{t('contactRequestPage.form.group.additional.employment.UNEMPLOYED')}</option>
                            </select>
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.income.label')}</Text>
                            <select
                                required
                                name="income"
                                id="income"
                                value={props.contactRequestFormData.value.income?.toString()}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({income: parseInt(e.target.value)}))}
                                className="mt-1 py-3 focus:ring-primary focus:border-primary
                                                    block w-full shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500"
                            >
                                <option value="" disabled
                                    hidden> {t('enriching.form.income.SELECT_INCOME')}</option>
                                <option value={0}>€ 0 {t('contactRequestPage.form.group.additional.income.perMonth')}</option>
                                {getIncomeOptions()}
                            </select>
                            <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}><Trans
                                i18nKey="contactRequestPage.form.group.additional.income.info"/></Text>
                            <FormError message={t(props.contactRequestFormData.value.errors?.income)}/>
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.guarantor.label')}</Text>
                            <select
                                required
                                name="guarantor"
                                id="guarantor"
                                value={props.contactRequestFormData.value.guarantor}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({guarantor: (e.target.value as GuarantorType) }))}
                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full
                                                    shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500 truncate "
                            >
                                <option value={GUARANTOR.NO_GUARANTOR}>
                                    {t('enriching.form.guarantor.NO_GUARANTOR')}
                                </option>
                                <option value={GUARANTOR.IN_NETHERLANDS}>
                                    {t('enriching.form.guarantor.IN_NETHERLANDS')}
                                </option>
                                <option value={GUARANTOR.ABROAD}>
                                    {t('enriching.form.guarantor.ABROAD')}
                                </option>
                            </select>
                            <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}><Trans
                                i18nKey="contactRequestPage.form.group.additional.guarantor.info"/></Text>
                            <FormError message={t(props.contactRequestFormData.value.errors?.guarantor)}/>
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.householdType.label')}</Text>
                            <select
                                required
                                name="householdType"
                                id="householdType"
                                value={props.contactRequestFormData.value.householdType ?? ''}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({householdType: (e.target.value as HouseholdType)}))}
                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500"
                            >
                                <option value="" disabled
                                    hidden> {t('contactRequestPage.form.group.additional.householdType.SELECT_HOUSEHOLD')}</option>
                                <option
                                    value={HOUSEHOLD_TYPE.SINGLE}>{t('contactRequestPage.form.group.additional.householdType.SINGLE')}</option>
                                <option
                                    value={HOUSEHOLD_TYPE.WITH_PARTNER}>{t('contactRequestPage.form.group.additional.householdType.WITH_PARTNER')}</option>
                                <option
                                    value={HOUSEHOLD_TYPE.WITH_FAMILY}>{t('contactRequestPage.form.group.additional.householdType.WITH_FAMILY')}</option>
                                <option
                                    value={HOUSEHOLD_TYPE.WITH_FRIENDS}>{t('contactRequestPage.form.group.additional.householdType.WITH_FRIENDS')}</option>
                            </select>
                        </div>
                    </div>
                    <div className={getHouseholdClass()}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.household.label')}</Text>
                            <select
                                required
                                name="household"
                                id="household"
                                value={props.contactRequestFormData.value.household?.toString()}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({household: parseInt(e.target.value)}))}
                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md required:invalid:text-gray-500"
                            >
                                <option value="" disabled hidden>
                                    {t('enriching.form.household.SELECT_MEMBERS')}
                                </option>
                                {getHouseholdOptions()}

                            </select>
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <div>
                                <Text
                                    weight={FontWeightEnum.Medium}
                                    size={FontSizeEnum.Sm}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('contactRequestPage.form.group.additional.pets.label')}
                                </Text>
                                <div className={`flex items-center ${styles.radioButtonWrapper}`}>
                                    <input
                                        id="pets-yes"
                                        name="pets"
                                        value="true"
                                        checked={props.contactRequestFormData.value.hasPets === true}
                                        onChange={(e) => props.setContactRequestFormData(prev => prev.with({hasPets: e.target.value === 'true'}))}
                                        type="radio"
                                        className={`${styles.radioButton} focus:ring-primary text-primary border-gray-300`}
                                    />
                                    <label
                                        htmlFor="pets-yes"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                        {t('contactRequestPage.form.group.additional.pets.yes')}
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        id="pets-no"
                                        name="pets"
                                        value="false"
                                        checked={props.contactRequestFormData.value.hasPets === false}
                                        onChange={(e) => props.setContactRequestFormData(prev => prev.with({hasPets: e.target.value === 'true'}))}
                                        type="radio"
                                        className={`${styles.radioButton} focus:ring-primary text-primary border-gray-300`}
                                    />
                                    <label
                                        htmlFor="pets-no"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                        {t('contactRequestPage.form.group.additional.pets.no')}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}>{t('contactRequestPage.form.group.rentalInfo.title')}</Text>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.rentalInfo.rentalStartDate.label')}</Text>
                            <DateInput
                                value={moment(props.contactRequestFormData.value.rentalStartDate)}
                                onChange={(date) => props.setContactRequestFormData(prev => prev.updateRentalStartDate(date?.format('YYYY-MM-DD')))}
                                id={'rentalStartDate'}
                            />
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.label')}</Text>
                            <select
                                required
                                id="term"
                                name="term"
                                value={props.contactRequestFormData.value.rentalPeriod?.toString()}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({rentalPeriod: parseInt(e.target.value)}))}
                                className="mt-1 py-3 block w-full pl-3 pr-10 text-base border-gray-300
                                                        focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md
                                                        required:invalid:text-gray-500"
                            >
                                <option
                                    value="0">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.INDEFINITE')}</option>
                                <option
                                    value="3">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.ZERO_THREE')}</option>
                                <option
                                    value="6">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.THREE_SIX')}</option>
                                <option
                                    value="12">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.SIX_TWELVE')}</option>
                                <option
                                    value="24">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.YEAR')}</option>
                            </select>
                            <FormError message={t(props.contactRequestFormData.value.errors?.rentalPeriod)}/>
                        </div>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}>{t('contactRequestPage.form.group.motivation.title')}</Text>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.motivation.message.label')}</Text>
                            <div className={styles.motivationTextArea}>
                                <TextArea
                                    value={props.contactRequestFormData.value.motivation}
                                    onChange={(name, val) => props.setContactRequestFormData(prev => prev.with({motivation: val}))}
                                    name={'motivation'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <Text
                        weight={FontWeightEnum.Medium}
                        size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}
                    >{t('contactRequestPage.form.group.terms.title')}</Text>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Checkbox
                                label={`${t('contactRequestPage.form.group.terms.sharing.label')} ${props.agent.name ?? ''}*`}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({dataSharingAccepted: e.target.checked}))}
                                isChecked={props.contactRequestFormData.value.dataSharingAccepted}
                                id={'dataSharingAccepted'}
                            />
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Checkbox
                                label={
                                    <span>
                                        {t('contactRequestPage.form.group.terms.privacy.label')} {props.agent.privacyPolicy &&
                                        <a
                                            target={'_blank'}
                                            href={props.agent.privacyPolicy}
                                            className="font-semibold text-primary underline"
                                            rel="noreferrer"
                                        >
                                            {t('enriching.form.privacy')}
                                        </a>}
                                    </span>
                                }
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({privacyPolicyAccepted: e.target.checked}))}
                                isChecked={props.contactRequestFormData.value.privacyPolicyAccepted}
                                id={'privacyPolicyAccepted'}
                            />
                            <FormError message={t(props.contactRequestFormData.value.errors?.terms)}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={props.isSubmitButtonDisabled ? styles.submitButton: ''}>
                        <Button buttonType={props.contactRequestFormData.hasErrors() ? ButtonType.SecondaryGray : ButtonType.Primary} title={t('contactRequestPage.form.group.submit.button')} size={ButtonSize.Md} onClick={props.handleSubmit} />
                        <FormError message={t(props.contactRequestFormData.value.errors?.general)}/>
                    </div>
                </div>
                <div>
                    <hr className={styles.divider}/>
                    <LogoLeadflowPoweredBy/>
                </div>
            </form>
        </>
    );

}
