import axios from 'axios'
import store from '../redux/store'
import ApiService from '../enums/apiService';
import { getServiceUrl } from './serviceDiscovery';

/**
 * @deprecated We are moving away from axios. Use {@link Api.get()} instead.
 * If you need an Api.post(), please write it ;)
 */
export const request = (token: string | null = null, service: ApiService = ApiService.Candidates) => {
    const headers = { Accept: 'application/ld+json' }
    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    return axios.create({ baseURL: getServiceUrl(service), timeout: 35000, headers: headers })
}

/**
 * @deprecated If you are using this function, you are doing something wrong!
 */
export const wait = (ms) => {
    return new Promise(
        resolve => setTimeout(resolve, ms)
    )
}

export const getFeaturesFromGrowthBook = async () => {
    const response = await axios.get('https://cdn.growthbook.io/api/features/prod_iG0ucOVVxS0u5Q0GQ2jr7plkiUUE7R3eLGOW2UC0oQ?project=prj_19g61mlbkqzg7h')
    return response
}

type ParamValueType = string | number | boolean;

export type BodyData = { [key: string]: ParamValueType | ParamValueType[] | null }

class Api {
    public async get<T>(path: string, params: BodyData = {}, service: ApiService = ApiService.Candidates): Promise<T> {
        if (path.includes('?')) {
            throw new Error('"path" with query parameters are not supported! Use the "params" arg instead!')
        }

        const urlQuery = this.urlQueryFromParams(params);

        const response = await fetch(
            `${getServiceUrl(service)}/${path}${decodeURIComponent(urlQuery)}`,
            {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    Authorization: `Bearer ${await this.getToken()}`,
                    Accept: 'application/ld+json'
                }
            }
        )

        const json = await response.json();
        if (json['@type'] === 'hydra:Error') {
            throw new Error(json['hydra:title'] + ': ' + json['hydra:description']);
        }

        return json['hydra:member'] ? json['hydra:member'] : json;
    }

    public async getWithoutAuth<T>(path: string, params: BodyData = {}, service: ApiService = ApiService.Candidates): Promise<T> {
        const urlQuery = this.urlQueryFromParams(params);

        const response =  await fetch(
            `${getServiceUrl(service)}/${path}${decodeURIComponent(urlQuery)}`,
            {
                method: 'GET',
            }
        )

        return await response.json()
    }

    public async getFile(path: string, service: ApiService = ApiService.Candidates): Promise<string> {
        const res = await fetch(
            `${getServiceUrl(service)}/${path}`,
            {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    Authorization: `Bearer ${await this.getToken()}`,
                    Accept: 'application/csv'
                }
            }
        )
        return await res.text()
    }

    public async getJsonLd<T>(path: string, params: BodyData = {}, service: ApiService = ApiService.Candidates): Promise<T> {
        if (path.includes('?')) {
            throw new Error('"path" with query parameters are not supported! Use the "params" arg instead!')
        }

        const urlQuery = this.urlQueryFromParams(params);

        const response = await fetch(
            `${getServiceUrl(service)}/${path}${decodeURIComponent(urlQuery)}`,
            {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    Authorization: `Bearer ${await this.getToken()}`,
                    Accept: 'application/ld+json'
                }
            }
        )
        const json = await response.json()
        if(json['@type'] === 'hydra:Error') {
            throw new Error(json['hydra:title'] + ': ' + json['hydra:description'])
        }
        return json
    }

    private urlQueryFromParams(params: BodyData): string {
        if (Object.keys(params).length === 0) {
            return ''
        }
        const urlSearchParams = this.convertParamValuesToURLSearchParams(params)
        return `?${urlSearchParams}`
    }

    public async postWithoutAuth<T extends BodyData>(path: string, body?: T, service: ApiService = ApiService.Candidates): Promise<Response> {
        if(path.includes('?')) {
            throw new Error('POST requests with query parameters are not supported! Use the body instead!')
        }

        const response = await fetch(
            `${getServiceUrl(service)}/${path}`,
            {
                method: 'POST',
                body: body ? JSON.stringify(body) : undefined,
                cache: 'no-cache' as RequestCache,
                headers: {
                    Accept: 'application/ld+json'
                }
            }
        )

        if (!response.ok) {
            throw new Error('Request is not accepted!');
        }

        return response;
    }

    public async post(path: string, body?: BodyData, service: ApiService = ApiService.Candidates): Promise<Response> {
        const response = await fetch(
            `${getServiceUrl(service)}/${path}`,
            {
                method: 'POST',
                body: body ? JSON.stringify(body) : undefined,
                cache: 'no-cache' as RequestCache,
                headers: {
                    Authorization: `Bearer ${await this.getToken()}`,
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                }
            }
        )
        if (response.status < 200 || response.status > 299) {
            throw new Error('Request is not accepted!')
        }

        return response;
    }

    private convertParamValuesToURLSearchParams(params: BodyData): URLSearchParams {
        const urlSearchParams = new URLSearchParams()
        Object.keys(params).forEach(key => {
            if(params[key] !== null) {
                this.appendUrlSearchParams(urlSearchParams, key, params[key])
            }
        })
        return urlSearchParams
    }

    private appendUrlSearchParams(urlSearchParams: URLSearchParams, paramName: string, paramValue: ParamValueType | ParamValueType[]): void {
        if(Array.isArray(paramValue)) {
            (paramValue as Array<ParamValueType>).forEach(i => urlSearchParams.append(`${paramName}[]`, i.toString()))
        } else {
            urlSearchParams.set(paramName, paramValue.toString())
        }
    }

    public async getToken(): Promise<string> {
        const token = store.getState().users.token
        if (token) {
            return token
        }
        return await new Promise((resolve) => {
            const unsubscribe = store.subscribe(() => {
                unsubscribe()
                resolve(store.getState().users.token)
            })
        })
    }
}

const api = new Api()

export default api
