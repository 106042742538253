import React from 'react';
import styles from './Icon.module.css';
import { classNames } from '../../utils';

export interface IconProps {
    className?: string;
}

const DraftIcon = ({ className }: IconProps) => {
    return (
        <div className={classNames(styles.icon, className ?? className)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                    d="M16.6667 1.66666C17.1267 1.66666 17.5 2.04 17.5 2.5V5.63083L15.8333 7.2975V3.33333H4.16667V16.6667H15.8333V14.3683L17.5 12.7017V17.5C17.5 17.96 17.1267 18.3333 16.6667 18.3333H3.33333C2.87333 18.3333 2.5 17.96 2.5 17.5V2.5C2.5 2.04 2.87333 1.66666 3.33333 1.66666H16.6667ZM18.1483 7.34L19.3267 8.51833L12.845 15L11.665 14.9983L11.6667 13.8217L18.1483 7.34ZM10.8333 10V11.6667H6.66667V10H10.8333ZM13.3333 6.66666V8.33333H6.66667V6.66666H13.3333Z"
                    fill="#697082"/>
            </svg>
        </div>
    );
};

export default DraftIcon;
