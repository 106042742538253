import React, { ReactNode, MouseEvent, ChangeEvent } from 'react';
import styles from './InputField.module.css';
import { classNames } from '../../utils';

interface BaseInputFieldProps {
    readonly name: string;
    readonly placeholder?: string;
    readonly value: string;
    readonly iconBefore?: ReactNode;
    readonly iconAfter?: ReactNode;
    readonly onClick?: (e: MouseEvent) => void;
    disabled?: boolean;
}

interface DefaultInputFieldProps extends BaseInputFieldProps {
    readonly onChange: (name: string, value: string) => void;
    readonly isSearch?: false;
}

interface SearchInputFieldProps extends BaseInputFieldProps {
    readonly onChange: (name: string, value: string, matchSearch?: (str1: string, str2: string) => void) => void;
    readonly isSearch: true;
}

type InputFieldProps = DefaultInputFieldProps | SearchInputFieldProps;

const InputField = ({
    name,
    placeholder,
    value = '',
    iconBefore,
    iconAfter,
    onClick,
    disabled = false,
    onChange,
    isSearch,
}: InputFieldProps) => {
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(e);
    }

    const handleInputClick = (e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(e);
    }

    const matchSearch = (str1: string , str2: string) => {
        const normalizedString1 = str1
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        const normalizedString2 = str2
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        return normalizedString1.includes(normalizedString2);
    }

    const handleChange = (e: ChangeEvent) => {
        if (isSearch === true) {
            onChange(name, (e.target as HTMLInputElement).value, matchSearch)
        } else {
            onChange(name, (e.target as HTMLInputElement).value)
        }
    }

    return (
        <>
            <div
                onClick={handleClick}
                className={classNames(styles.InputField, iconBefore && styles.hasIconBefore, iconAfter && styles.hasIconAfter)}
            >
                {iconBefore &&
                    <span className={classNames(styles.iconBefore)}>
                        {iconBefore}
                    </span>
                }
                <input
                    name={name}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    onClick={handleInputClick}
                    disabled={disabled}
                />
                {iconAfter &&
                    <span className={classNames(styles.iconAfter)}>
                        {iconAfter}
                    </span>
                }
            </div>
        </>
    );
};

export default InputField;
