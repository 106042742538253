import TopNavigation from '../../components/navigation/TopNavigation'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { PencilAltIcon } from '@heroicons/react/outline'
import Text from '../../components/typography/text/Text'
import { useTranslation } from 'react-i18next'
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum'
import ColorEnum from '../../enums/colorEnum'
import moment from 'moment'
import styles from './AgentsOverview.module.css'
import api from '../../utils/api'

export default function AgentsProfileContainer() {
    const { t } = useTranslation()
    const lastMonthName = useRef(getLastMonthName())

    const [agencies, setAgencies] = useState([]);

    useEffect(() => {
        api.get('api/agency-overview').then(res => {
            setAgencies(res.result)
        })
    }, []);

    function limitNumberToThreeDigits(number) {
        return number > 999 ? '999+' : number
    }

    function getLastMonthName() {
        const lastMonthName = moment().subtract(1, 'month').format('MMMM')
        return lastMonthName.charAt(0).toUpperCase() + lastMonthName.slice(1)
    }

    async function handleBillingReportLinkClick() {
        const csvData = await api.getFile('api/get-screening-billing-report-from-last-month-for-all-agencies')
        const url = window.URL.createObjectURL(new Blob([csvData]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'screening_billing_report.csv')
        link.click()
    }

    return (
        <>
            <div className="min-h-full">
                <TopNavigation />
                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <div className="sm:flex items-center justify-between mb-6 py-6">
                                <h1 className="text-4xl font-bold ">Makelaars</h1>
                                <div className={styles.buttonsContainer}>
                                    <Text size={FontSizeEnum.Md} color={ColorEnum.Primary500}>
                                        <a className={styles.link}
                                            onClick={() => handleBillingReportLinkClick()}>
                                            {t('admin.downloadBillingReport', { month: lastMonthName.current })}
                                        </a>
                                    </Text>
                                    <Text size={FontSizeEnum.Md} color={ColorEnum.Primary500}>
                                        <Link to={'/admin/dubbele-advertenties'} className={styles.link}>
                                            {t('admin.mergeListings')}
                                        </Link>
                                    </Text>
                                    <Link
                                        to="/admin/agents/new"
                                        className="inline-flex items-center px-5 py-2.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium
                                        rounded-md focus:outline-nonefocus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary bg-primary text-white"
                                    >
                                        Makelaar Toevoegen
                                    </Link>
                                </div>
                            </div>
                            <ul className="list-none">
                                {agencies.map((agency) => (
                                    <li
                                        key={agency.id}
                                        className="w-full  p-6 my-6 border-gray-300 bg-gray-100 rounded-md flex justify-between">
                                        <div>
                                            <h2 className="text-2xl pb-2">{agency.name}</h2>
                                            <p className="text-gray-500 ">{agency.id}</p>
                                        </div>

                                        <div className={styles.agentRowButtonContainer}>
                                            <Link
                                                to={`/admin/agents/${agency.id}`}
                                                key={agency.id}
                                                title="Agent bewerken"
                                            >
                                                <PencilAltIcon className={styles.pencilIcon} />
                                            </Link>
                                            <Text size={FontSizeEnum.Md} color={agency.monthlyDiscardedLeadsCount === 0 ? ColorEnum.Gray500 : ColorEnum.Primary600}>
                                                <Link
                                                    to={`/admin/onverwerkbare-leads/${agency.id}`}
                                                    className={`${styles.activelink} ${agency.monthlyDiscardedLeadsCount === 0 ? styles.disabledlink : ''}`}
                                                >
                                                    {
                                                        agency.monthlyDiscardedLeadsCount > 0 ?
                                                            t('agentsOverviewPage.agencyList.discardedLeads', { amount: limitNumberToThreeDigits(agency.monthlyDiscardedLeadsCount) }) :
                                                            t('agentsOverviewPage.agencyList.noDiscardedLeads', { amount: limitNumberToThreeDigits(agency.monthlyDiscardedLeadsCount) })
                                                    }
                                                </Link>
                                            </Text>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
