import Text from '../../typography/text/Text'
import FontSizeEnum from '../../typography/enums/fontSizeEnum'
import ColorEnum from '../../../enums/colorEnum'
import styles from './TextInput.module.css'

export interface TextInputProps {
    readonly name: string
    readonly placeholder?: string
    readonly testId?: string
    readonly isDisabled?: boolean
    readonly isMandatory?: boolean
    readonly label?: string
    readonly value?: string
    readonly width?: number
    readonly onChange?: (ev: React.ChangeEvent<unknown>) => void
    readonly keepColorOnDisabled?: boolean
}

const TextInput = (props: TextInputProps) => {

    return (
        <div style={{ width: props.width ? props.width : 'auto' }}>
            {props.label &&
                <label htmlFor={props.name}>
                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Primary700}>
                        { props.label }
                        {props.isMandatory && ' *'}
                    </Text>
                </label>
            }

            <input
                type="text"
                placeholder={props.placeholder}
                data-testid={props?.testId}
                disabled={props.isDisabled}
                name={props.name}
                id={props.name}
                onChange={props.onChange}
                value={props.value ? props.value : ''}
                className={`${styles.inputContainer} ${props.keepColorOnDisabled ? styles.inputDisabled : ''}`}
            />
        </div>
    )
}

export default TextInput
