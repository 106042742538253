/* eslint-disable-next-line */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import kbdStyles from '../../kbd/KBD.module.css';
import Quill from 'quill';

/**
 * Quill Embed Blot
 * @see https://github.com/slab/quill/blob/main/packages/quill/src/blots/embed.ts
 */
const Embed: any = Quill.import('blots/embed');

class VariablesDropdownBlot extends Embed {
    static blotName = 'variables-dropdown';
    static tagName = 'span';
    static className = kbdStyles.kbd;

    static create(value: string) {
        const node = super.create();
        node.setAttribute('data-value', value);

        node.textContent = `{{${value}}}`;
        node.setAttribute('contenteditable', 'false');

        node.setAttribute('contenteditable', 'false');

        return node;
    }

    static value(node) {
        return node.getAttribute('data-value');
    }

    static formats(node) {
        return this.value(node);
    }
}

export default VariablesDropdownBlot;
