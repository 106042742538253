import React, { ReactNode } from 'react';
import styles from './KBD.module.css';

type KBDProps = {
    children?: ReactNode;
    className?: string;
};

const KBD: React.FC<KBDProps> = ({ children, className = '' }) => {
    return (
        <kbd className={`${styles.kbd} ${className}`}>
            {children}
        </kbd>
    );
};

export default KBD;
